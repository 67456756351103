import React from 'react';
import './style.css';

const Cell = (props) => {
  const {row, col} = props;

  //use formatter
  if(col.formatter){
    const value = row[col.key] ?? null;
    const data = {row, value: value};

    return (
      <div 
        className="p-2 data-cell border-bottom"
        style={{cursor: 'pointer'}}
        onClick={() => props.onRowClick(row)}
      >
        {React.createElement(col.formatter, data)}
      </div>
    )
  }

  //use value
  if(col.key){
    return(
      <div 
        className="p-2 data-cell border-bottom"
        style={{cursor: 'pointer'}}
        onClick={() => props.onRowClick(row)}
      >
        <p>{row[col.key]}</p>
      </div>
    )
  }

  return (
    <div 
      className="p-2 data-cell border-bottom"
      style={{cursor: 'pointer'}}
      onClick={() => props.onRowClick(row)}
    >
      <p>ERROR</p>
    </div>
  )
}

function Table(props) {
  const {columns, data} = props;

  //prepare grid template css
  const gridTemplate = () => {
    return `repeat(${columns.length}, auto`;
  }

  //style grid
  const styleGrid = {
    display: 'grid',
    gridTemplateColumns: `${gridTemplate()}`,
    maxHeight: '100%',
    overflow: 'auto'
  };

  //style header
  const styleHeader = {
    backgroundColor: '#F9F9FA',
    position: 'sticky',
    top: 0,
  }

  //handle header click
  const onColumnClick = (column) => {
    if(column.sortable !== true || !column.key){
      return null;
    }

    if(props.sortBy === column.key){
      props.invertSortDir();
    }else{
      props.setSortBy(column.key);
    }
  }

  return (
    <>
      <div 
        role="grid" 
        style={styleGrid}
      >
        {columns.map((column, i) => 
          <a 
            key={i}
            href={() => false}
            className="p-2 border-bottom bg-light d-flex justify-content-between align-items-center text-dark text-decoration-none"
            style={{...styleHeader, cursor: `${column.sortable ? 'pointer' : 'default' }`}}
            onClick={() => onColumnClick(column)}
          >
            <span>{column.name}</span>
          
            {props.sortBy && props.sortBy === column.key && props.sortAsc ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          : props.sortBy && props.sortBy === column.key && !props.sortAsc ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
              <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
            </svg>
          : null }
          
          </a>
        )}

        {data.map(row => 
          columns.map((col, i) =>
            <Cell
              key={i}
              onRowClick={props.onRowClick}
              row={row}
              col={col}
            />
          )
        )}

        { props.children }

        { props.loading ?
          <div className="my-3">
            <span className="text-muted">Loading...</span>
          </div> 
        : !data || data.length <= 0 ?
          <div className="my-3">
            <span className="text-muted">Geen items gevonden.</span>
          </div> 
        : null}
      </div>
    </>
  )
}

export default Table
