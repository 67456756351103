import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Dashboard from '../../components/dashboard/Dashboard';
import List from './List';
import MailDetail from './MailDetail';
import Loader from '../../components/loader';
import Api from "../../utils/Api";
import { authContext } from '../../context/AuthContext';
import { InboxContext } from '../../context/InboxContext';

function Inbox() {
  const [mails, setMails] = useState([]);
  const [selectedMail, setSelectedMail] = useState(null);
  const [selectedMailDetails, setSelectedMailDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const { auth } = useContext(authContext);

  useEffect(()=>{
    fetchInbox();

    const interval=setInterval(()=>{
      fetchInbox();
    }, 60000);

    return() => clearInterval(interval);
  },[]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchInbox = () => {
    setError('');
    setLoading(true);
    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get("/mails", { headers })
      .then(res => {
        setMails(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response);
        setLoading(false);
      });
  }

  return (
    <Dashboard padding="0">
      <div className="h-100 d-flex flex-column"> 
        <InboxContext.Provider value={{ fetchInbox }}>
          <div className="row">
            <div className="col">
              <h1>Inbox</h1>
            </div>
            <div className="col-auto my-auto">
              <button 
                className="btn btn-primary" 
                onClick={() => fetchInbox()}
              >Refresh</button>
            </div>
          </div>
          <div className="row flex-fill" style={{ minHeight: 0, overflowY: 'auto' }}>
            <div className="col-3 pr-0 mh-100" style={{ overflowY: 'auto' }}>
              { loading ?
                <Loader />
              :
                <List 
                  mails={mails} 
                  selectMail={(id) => setSelectedMail(id)}
                  selectedMailDetails={(obj) => setSelectedMailDetails(obj)}
                  selectedMail={selectedMail}
                />
              }
            </div>
            <div className="col-9 h-100">
              <MailDetail 
                selectedMail={selectedMail}
                selectedMailDetails={selectedMailDetails} 
              />
            </div>
          </div>
          { error.status && 
            <div className="p-3 mb-2 bg-danger text-white">
              <p>There was an error loading the resource</p>
              { error.status === 502 && <p>Please check the provider config <Link to="/profile">here</Link></p> }
            </div>
          }
        </InboxContext.Provider>
      </div>
    </Dashboard>
  )
}

export default Inbox
