import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom'; 
import Dashboard from '../../components/dashboard/Dashboard';
import Form from './Form';
import Api from '../../utils/Api';
import { authContext } from '../../context/AuthContext';
import { useToasts } from 'react-toast-notifications';


function Create() {
  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const { addToast } = useToasts();
  const history = useHistory();

  //submit form
  const onSubmit = (data) => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.post(`/sites/`, data, { headers })
      .then(res => {
        console.log(res);
        addToast('Site aangemaakt.', { appearance: 'success' });
        history.push('/sites');
      })
      .catch(err => {
        console.log(err);
        addToast('Aanmaken werf mislukt.', { appearance: 'error' });
      });
  }


  return (
    <Dashboard>
      <h1>New site</h1>

      <Form 
        onSubmit={onSubmit}
      />

    </Dashboard>
  )
}

export default Create
