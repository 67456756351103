import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { authContext } from '../../context/AuthContext';
import WorkorderRef from '../../components/formatting/WorkorderRef';
import Api from '../../utils/Api';
import moment from 'moment';
import Badge from '../../components/badge';
import Table from '../../components/table';

//badge proforma
const badgeProforma = (workorder) => {
  if(workorder.status_proforma === 'ok'){
    return <Badge variant="success" text="OK"/>;
  }

  return <Badge text="&nbsp;&nbsp;&nbsp;"/>;
}

//badge approval
const badgeApproval = (workorder) => {
  if(workorder.status_approval === 'approved'){
    return <Badge variant="success" text="goedgekeurd"/>;
  }

  if(workorder.status_approval === 'requested'){
    return <Badge variant="info" text="aangevraagd"/>;
  }

  if(workorder.status_approval === 'required'){
    return <Badge variant="warning" text="vereist"/>;
  }

  if(workorder.status_approval === 'not required'){
    return <Badge variant="success" text="n.v.t."/>;
  }

  return <Badge text="&nbsp;&nbsp;&nbsp;"/>;
}

//badge invoice
const badgeInvoice = (workorder) => {
  if(workorder.status_invoice === 'paid'){
    return <Badge variant="success" text="betaald"/>;
  }

  if(workorder.status_invoice === 'sent'){
    const sent_invoice_at = workorder.invoice?.sent_invoice_at 
      ? moment(workorder.invoice?.sent_invoice_at).format('YYYY-MM-DD')
      : null;

    const daysDue = workorder.invoice?.days_due ?? null;
    let dueState = 'warning';

    if(daysDue && daysDue > 3){
      dueState = 'success';
    }else if (daysDue && daysDue <= 3 && dueState >= 0){
      dueState = 'warning';
    }else if (daysDue && daysDue > 0){
      dueState = 'danger';
    }

    return (
      <div>
      <div className="d-flex">
        <Badge variant="info" text={`verzonden${sent_invoice_at ? ' ('+sent_invoice_at+')' : null}`}/>
        { workorder.invoice?.tl_due_on ? 
          <Badge variant={dueState} text={`${workorder.invoice?.days_due ? workorder.invoice?.days_due : '?'}d` } customClass="ml-2"/>
        : null }
      </div>
      </div>
    );
  }

  if(workorder.status_invoice === 'booked'){
    return <Badge variant="warning" text="ingeboekt"/>;
  }

  return <Badge text="&nbsp;&nbsp;&nbsp;"/>;
}

//table formatters
const RefFormatter = ({value, row}) => {
  return (<WorkorderRef internalRef={value} />)
}

const ProformaFormatter = ({row}) => {
  return badgeProforma(row);
}

const ApprovalFormatter = ({row}) => {
  return badgeApproval(row);
}

const InvoiceFormatter = ({row}) => {
  return badgeInvoice(row);
}

const InvoiceNumberFormatter = ({row}) => {
  return (<span>{ row?.invoice?.tl_data?.invoice_number }</span>);
}

const DateFormatter = ({value}) => {
  return (<span>{ moment(value).format('dd DD MMM') }</span>);
}

const CompanyNameFormatter = ({row}) => {
  return (<span>{row?.company?.name}</span>);
}

const SiteFormatter = ({row}) => {
  return (
    <span>{(row.site_line_1 && row.site_city) ? `${row.site_line_1}, ${row.site_city}` : '-'}</span>
  )
}

function List() {
  const [workorders, setWorkorders] = useState([]);
  const [fetchMore, setFetchMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState(
    Number(sessionStorage.getItem('workorderFiltersRemember')) ? (sessionStorage.getItem('workorderFilterStatus') ?? 'new') : 'new');
  const [filterTerm, setFilterTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [rememberFilters, setRememberFilters] = useState( Number(sessionStorage.getItem('workorderFiltersRemember') ?? 0) );

  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const history = useHistory();

  const bottomBoundaryRef = useRef(null);

  //table columns
  const columns = [
    { 
      key: 'internal_ref', 
      name: 'ref', 
      sortable: true, 
      formatter: RefFormatter 
    },
    { 
      key: 'status_proforma', 
      name: 'proforma',
      sortable: true, 
      formatter: ProformaFormatter
    }, 
    { 
      key: 'status_approval', 
      name: 'goedkeuring',
      sortable: true, 
      formatter: ApprovalFormatter
    },
    { 
      key: 'status_invoice', 
      name: 'factuur',
      sortable: true, 
      formatter: InvoiceFormatter
    },
    { 
      //key: 'invoice', 
      name: '',
      sortable: false, 
      formatter: InvoiceNumberFormatter
    },
    { 
      key: 'date', 
      name: 'datum',
      sortable: true, 
      formatter: DateFormatter
    },
    { 
      key: 'tl_client_id', 
      name: 'klant',
      sortable: true, 
      formatter: CompanyNameFormatter
    },
    { 
      //key: 'site', 
      name: 'werf',
      sortable: false, 
      formatter: SiteFormatter
    },
  ];

  //write filter status changes to localstorage
  useEffect(() => {
    sessionStorage.setItem('workorderFilterStatus', filterStatus);
  }, [filterStatus])

  //write filter remember status changes to localstorage
  useEffect(() => {
    sessionStorage.setItem('workorderFiltersRemember', rememberFilters ? 1 : 0);
  }, [rememberFilters])

  //check conditions for page incr.
  useEffect(() => {
    if(page >= totalPages){
      setFetchMore(false);
    }else if(fetchMore && !loading && workorders.length > 0){
      setPage(page +1);
    }
  }, [fetchMore, workorders, loading, totalPages]); //eslint-disable-line react-hooks/exhaustive-deps

  //fetch on page change
  useEffect(() => {
    if(fetchMore && !loading && workorders.length > 0 && page > 1){
      setFetchMore(false);
      fetchWorkorders();
    }
  }, [page]) //eslint-disable-line react-hooks/exhaustive-deps

  //observe bottomBoundaryRef
  useEffect(() => {
    //callback
    const handleObserver = (entities) => {
      const target = entities[0];
      if (target.isIntersecting) { 
        setFetchMore(true);
      }
    }

    var options = {
       root: null,
       rootMargin: "20px",
       threshold: 1.0
    };

   // initialize IntersectionObserver
    const observer = new IntersectionObserver(handleObserver, options);
    if (bottomBoundaryRef.current) {
       observer.observe(bottomBoundaryRef.current)
    }

  }, [bottomBoundaryRef]);

  //check if bottomBoundaryRef is in view after workorders or totalpages change
  useEffect(() => {
    if(page < totalPages){
      const visible = isScrolledIntoView(bottomBoundaryRef.current);
      if(visible){
        setFetchMore(true);
      }
    }
  }, [workorders, totalPages]) //eslint-disable-line react-hooks/exhaustive-deps

  //detect if bottomBoundaryRef is in view
  const isScrolledIntoView = (el) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  //refetch workorders on filter/sort change
  useEffect(() => {
    fetchWorkorders(true);
  }, [filterStatus, sortBy, sortAsc]); //eslint-disable-line react-hooks/exhaustive-deps

  //refetch workorders on term change
  useEffect(() => {
    //debounce
    const timer = setTimeout(() => {
      fetchWorkorders(true);
    }, 350);

    return () => clearTimeout(timer);
  }, [filterTerm]) //eslint-disable-line react-hooks/exhaustive-deps


  //fetch workorders
  const fetchWorkorders = (clearData = false) => {
    setLoading(true);

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    //prepare params
    let params = {};
    let status = null;
    let term = null;

    if(filterStatus){
      status = filterStatus;
    }

    if(filterTerm && filterTerm !== ''){
      term = filterTerm;
    }

    params['per_page'] = 50;

    //clear
    if(clearData){
      params['page'] = 1;
    }else{
      params['page'] = page;
    }

    //construct params
    if(status || term){
      params['filter'] = {};

      if(status){
        params['filter']['status'] = status;
      } 

      if(term){
        params['filter']['term'] = term;
      }
    }

    if(sortBy){
      const sort = {
        field: sortBy,
        order: sortAsc ? 'asc' : 'desc'
      }

      params['sort'] = sort;
    }
    
    //get resource
    Api.get("/workorders", { headers, params })
      .then(res => {
        console.log(res.data)
        // setWorkorders(res.data.data);
        const newWorkorders = res.data.data;

        if(clearData){
          setPage(1);
          setWorkorders(newWorkorders);
        }else{
          setWorkorders([
            ...workorders,
            ...newWorkorders
          ]);
        }

        setTotalPages(res.data.last_page);

        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });  
  }

  return (
    <div className="h-100 d-flex flex-column">
      {/* toolbar */}
      <div className="row mb-3 mt-1">
        <div className="col">
          <div className="d-flex justify-items-start align-items-center">

          <div className="btn-toolbar" role="toolbar">
            <div>
              <div className="btn-group btn-group-sm mr-2" role="group">
                <button 
                  type="button" 
                  className={`btn btn-light ${filterStatus === 'new' && 'active'}`}
                  onClick={() => setFilterStatus('new')}
                >nieuw</button>
                <button 
                  type="button" 
                  className={`btn btn-light ${filterStatus === 'for_approval' && 'active'}`}
                  onClick={() => setFilterStatus('for_approval')}
                >ter goedkeuring</button>
                <button 
                  type="button" 
                  className={`btn btn-light ${filterStatus === 'for_invoice' && 'active'}`}
                  onClick={() => setFilterStatus('for_invoice')}
                >ter facturatie</button>
                <button 
                  type="button" 
                  className={`btn btn-light ${filterStatus === 'for_payment' && 'active'}`}
                  onClick={() => setFilterStatus('for_payment')}
                >ter betaling</button>
              </div>
              <div className="btn-group btn-group-sm mr-2" role="group">
                <button 
                  type="button" 
                  className={`btn btn-light ${filterStatus === '' && 'active'}`}
                  onClick={() => setFilterStatus('')}
                >alles</button>
              </div>
            </div>
          </div>
          <div className="ml-2">
          <form className="form-inline">
            <div className="input-group">
              <input 
                type="text" 
                value={filterTerm}
                className="form-control form-control-sm" 
                placeholder="Zoeken.." 
                onChange={(e) => setFilterTerm(e.target.value)}
              />
            </div>
          </form>
          </div>
          </div>
        </div>
        <div className="col-auto pt-2">
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="checkbox" 
              checked={rememberFilters} 
              onClick={() => setRememberFilters(!rememberFilters)}
              id="rememberFiltersCheck" 
            />
            <label 
              className="form-check-label" 
              htmlFor="rememberFiltersCheck"
            >Filters onthouden</label>
          </div>
        </div>
      </div>

      {/* table */}
      <div className="row flex-fill" style={{overflowY: 'auto'}}>
        <div className="col mh-100">
          <Table 
            columns={columns} 
            data={workorders}
            sortBy={sortBy}
            sortAsc={sortAsc}
            setSortBy={setSortBy}
            loading={loading}
            invertSortDir={() => setSortAsc(!sortAsc)}
            onRowClick={(row) => history.push(`/workorders/${row.id}`)}
          >
            <div
              ref={bottomBoundaryRef}
              style={{gridColumn: '1 / 3'}}
            >
              { !loading && totalPages === page ? <span className="text-muted">Einde van resultaten</span> : null }
            </div>
          </Table>
        </div>
      </div>

    </div>
  )
}

export default List
