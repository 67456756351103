import React, { useState, useEffect, useContext } from 'react';
import { useHistory, Link } from "react-router-dom";
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';

function Oauth(props) {
  const [oauthErrMsg, setOauthErrMsg] = useState('');
  let history = useHistory();
  
  const getUrlParameter = (name) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const code = getUrlParameter('code');

  const { auth } = useContext(authContext);

  const token = auth.data.access_token;

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  useEffect(() => {
    if(code){
      authenticate();
    } else {
      getAuthUrl();
    }
  }, [code]); //eslint-disable-line react-hooks/exhaustive-deps

  const authenticate = () => {
    setOauthErrMsg('');

    const data = {
      code: code
    };

    Api.post(props.authUrl, data, { headers })
      .then(() => {
        //redirect to auth url
        history.push('/')
      })
      .catch(err => {
        console.log(err);
        setOauthErrMsg(err.response?.data?.message);
      });
  }

  const getAuthUrl = () => {
    setOauthErrMsg('');

    Api.get(props.redirUrl, { headers })
      .then(res => {
        //redirect to auth url
        window.location = res.data.url;
      })
      .catch(err => {
        console.log(err);
        setOauthErrMsg(err.response?.data?.message);
      });
  }

  if (oauthErrMsg){
    return (
      <div>
        <div className="alert alert-danger" role="alert">
          <h3>Failed to connect to {props.name}</h3>
          <span>{ oauthErrMsg }</span>
          <div>
            <Link 
              className="btn btn-primary mt-2"
              to="/profile"
            >Return to profile</Link>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      <h5>{props.name} Oauth</h5>
      <span className="text-muted">connecting...</span>
    </div>
  )
}

export default Oauth
