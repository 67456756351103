import React, { useState, useEffect } from 'react';
import EmptyAlert from '../../../components/alert/EmptyAlert';

function Photos(props) {
  const [documentUrl, setDocumentUrl] = useState(null); 
  const [loading, setLoading] = useState(true);
  const [random, setRandom] = useState(0);

  useEffect(() => {
    getDocumentUrl();
  }, [props.photos]); //eslint-disable-line react-hooks/exhaustive-deps

  const getDocumentUrl = () => {
    setDocumentUrl(null);
    const url = `${process.env.REACT_APP_API_SERVER}/storage/photos/${props.internalRef}.pdf`;
    setDocumentUrl(url);
    setRandom(random +1);
    setLoading(false);

  }
  

  if(!props.photos || props.photos.length < 1){
    return(
      <EmptyAlert
        text="Nog geen fotos toegewezen"
      />
    )
  }

  if(loading){
    return(
      <p>Loading</p>
    )
  }
  
  return (
    <div className="d-flex flex-column h-100">
      { documentUrl ?
        <embed
          key={random}
          id="photosPdf"
          src={documentUrl}  
          className="flex-grow-1"
          style={{width: '100%', minHeight: '300px'}}
        ></embed >
      :
        <div>No document</div>
      } 
    </div>
  )
}

export default Photos
