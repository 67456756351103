import axios from 'axios';

/*const BASE_URL =  process.env.NODE_ENV === 'development' 
  ? 'http://127.0.0.1:8000/api' 
  : 'https://wbappserver.zanibytes.be/api';*/

const BASE_URL = process.env.REACT_APP_API_SERVER + 'api/';

const api = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
  headers: {
    'Accept': 'application/json',
  }
});

api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 && window.location?.pathname !== '/login') {
    window.location.href = '/login';
  }
  return Promise.reject(error);
});


export default api;