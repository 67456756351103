import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';
import DynamicSelect from '../../components/form/DynamicSelect';

const ModalImportSite = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [sites, setSites] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [site, setSite] = useState({});

  const { auth } = useContext(authContext);
  
  useEffect(() => {
    fetchSites();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchSites = () => {
    setSite({});
    setLoaded(false);

    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    Api.get('/sites', { headers })
      .then(res => setSites(res.data))
      .catch(err => console.log(err))
      .finally(() => setLoaded(true));
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onImportSite = () => {
    props.onImportSite(site ?? null);
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Werf importeren</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="frmCliendId">Kies een werf in onderstaande lijst:</label>
          <DynamicSelect 
            id="frmSiteId"
            data={sites}
            dataLoaded={loaded}
            value={site}
            valueField="id"
            displayField="name"
            additionalField="address.city"
            additionalFieldBrackets={true}
            updateValue={(value) => setSite(value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="primary" onClick={onImportSite}>
          Importeren
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

export default ModalImportSite
