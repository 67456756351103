import React from 'react'

function WorkorderRef(props) {
  const number = props.internalRef?.substring(2);
  let prefixZone = true;
  let prefix = '';
  let index = '';

  [...number].forEach(char => {
    if(char === '0' && prefixZone){
      prefix = prefix + char;
    }else{
      prefixZone = false;
      index = index + char;
    }
  });

  return (
    <>
      <span className="small text-muted">WB{prefix}</span>
      <span className="">{index}</span>
    </>
  )
}

export default WorkorderRef
