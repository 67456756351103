import React from 'react'

function Pagination(props) {
  const lastPage = () => {
    if(props.data.length < props.pageCount){
      return true;
    }else{
      return false;
    }
  }


  if(!props.data || !props.data.length || (props.page <= 1 && lastPage()) ){
    return null;
  }

  return (
    <nav>
      <ul className="pagination mt-3">
        <li className={`page-item ${props.page === 1 ? 'disabled' : null}`}>
          <button 
            className="page-link" 
            onClick={() => props.setPage(props.page-1)}
            disabled={props.loading || props.page === 1 ? true : false}
          >Vorige</button>
        </li>
        <li className="page-item disabled">
          <div className="page-link">{props.page}</div>
        </li>
        <li className={`page-item ${lastPage() ? 'disabled' : null}`}>
          <button 
            className="page-link"
            onClick={() => props.setPage(props.page+1)}
            disabled={props.loading || lastPage() ? true : false}
          >Volgende</button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
