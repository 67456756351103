import React from 'react';
import AppHeader from './AppHeader';
import Sidebar from './Sidebar';

function Dashboard(props) {
  return (
    <div className="container-fluid h-100 d-flex flex-column">
      <div className="row flex-fill" style={{ minHeight: 0 }}>
          
          <Sidebar />
          
          <div className="col mh-100" style={{ overflowY: 'auto' }}>
              <div className="h-100 d-flex flex-column">

                  <div className="row flex-shrink-0">
                    <AppHeader />
                  </div>

                  <div className="row flex-fill h-100" style={{ minHeight: 0, overflowY: 'auto' }}>
                      <div className={`col-12 mh-100 h-100 ${props.padding ? 'py-'+props.padding : 'py-3' }`} style={{ overflowY: 'auto' }} >
                          
                          { props.children }

                          { false ? 
                          <div className="h-100 d-flex flex-column">
                              <div className="row flex-shrink-0">
                                  <div className="col-12 border">Top bar</div>
                              </div>
                              <div className="row flex-fill" style={{ minHeight: 0, overflowY: 'auto' }}>
                                  <div className="col-6 border mh-100" style={{ overflowY: 'auto' }}>
                                    <div>content</div>
                                  </div>
                                  <div className="col-6 border mh-100" style={{ overflowY: 'auto' }}>
                                    <div>content</div>
                                  </div>
                              </div>
                          </div> 
                          : null}
                          
                          
                      </div>
                  </div>
              </div>    
              

          </div>
      </div>
      { false ?
        <div className="row flex-shrink-0">
            <div className="col-12 border">Footer </div>
        </div>
      : null }
    </div>
  )
}

export default Dashboard
