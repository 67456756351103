import React, { useState, useEffect, useContext, useRef } from 'react';
import { Dropdown, DropdownButton, Tab, Nav, Accordion, Card } from 'react-bootstrap';
import { authContext } from '../../context/AuthContext';
import { WorkorderContext } from '../../context/WorkorderContext';
import { useToasts } from 'react-toast-notifications';
import Api from '../../utils/Api';
import Loader from '../../components/loader';
import MailBody from './MailBody';
import MailAttachments from './MailAttachments';
import ModalArchiveMail from '../../components/mail/ModalArchiveMail';

function MailCard(props) {
  const [mail, setMail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mailErr, setMailErr] = useState(null);
  const { addToast } = useToasts();

  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const { refetchWorkorder } = useContext(WorkorderContext)

  const modalArchiveMailRef = useRef();

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  useEffect(() => {    
    fetchMail();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchMail = () => {
    setLoading(true);

    Api.get(`/mails/${props.mailId}`, { headers })
    .then(res => {
      setMail(res.data);
      setLoading(false);
    })
    .catch(err => {
      console.log(err);
      setMailErr(err.response?.status);
      setLoading(false);
    }); 
  }

  // const onUpdateMail = (workorderId = null) => {
  //   if(mail.approval){
  //     addToast('Deze mail wordt gebruikt als goedkeuring, kan niet verwijderd worden.', { appearance: 'error' });
  //   }else if (mail.photos.some(photo => mail.attachments.some(attachment => attachment.id === photo.graph_attachment_id))){
  //     addToast('Deze mail wordt gebruikt als foto, kan niet verwijderd worden.', { appearance: 'error' });
  //   }else{
  //     Api.patch(`mails/${mail.id}`, { workorder_id: workorderId }, { headers })
  //       .then(res => {
  //         setMail(null);
  //         fetchMail();
  //         refetchWorkorder();
  //         addToast('Mail losgekoppeld van werkbon.', { appearance: 'success' });
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         addToast('Er was een probleem bij het losgekoppelen van de mail.', { appearance: 'error' });
  //       });
  //   }
  // }

  const onArchiveMail = () => {
    if(mail && mail.approval){
      addToast('Deze mail wordt gebruikt als goedkeuring, kan niet verwijderd worden.', { appearance: 'error' });
    }else if (mail && mail.photos.some(photo => mail.attachments.some(attachment => attachment.id === photo.graph_attachment_id))){
      addToast('Deze mail wordt gebruikt als foto, kan niet verwijderd worden.', { appearance: 'error' });
    }else{
      const mailId = mail?.id ?? props.mailId;

      if(mailId){
        Api.delete(`mails/${mailId}`, { headers })
          .then(res => {
            setMail(null);
            fetchMail();
            refetchWorkorder();
            addToast('Mail losgekoppeld van werkbon.', { appearance: 'success' });
          })
          .catch(err => {
            console.log(err);
            addToast('Er was een probleem bij het losgekoppelen van de mail.', { appearance: 'error' });
          });
      }else{
        addToast('Er was een probleem bij het losgekoppelen van de mail.', { appearance: 'error' });
      }
    }
  }

  const onImportApproval = (attachmentId) => {
    const data = {
      'workorder_id': mail.workorder_id,
      'mailbox': mail.mailbox,
      'graph_mail_id': mail.graph_id,
      'graph_attachment_id' : attachmentId ?? null,
    }

    Api.post(`/approvals/`, data, {headers})
      .then(res => {
        addToast('Goedkeuring toegevoegd aan werkbon.', { appearance: 'success' });
        fetchMail();
        refetchWorkorder();
      })
      .catch(err => {
        console.log(err);
        addToast('Goedkeuring toegevoegd aan werkbon mislukt.', { appearance: 'error' });
      });
  }

  const onImportPhoto = (attachmentId) => {
    const data = {
      'workorder_id': mail.workorder_id,
      'mailbox': mail.mailbox,
      'graph_mail_id': mail.graph_id,
      'graph_attachment_id' : attachmentId ?? null,
    }

    Api.post(`/photos`, data, { headers })
      .then(res => {
        addToast('Foto geimporteerd.', { appearance: 'success' });
        fetchMail();
        refetchWorkorder();
      })
      .catch(err => addToast('Er was een error bij het importeren van de foto.', { appearance: 'error' }));
  }

  const onRemovePhoto = (attachmentId) => {
    const photo = mail.photos.find(photo => photo.graph_attachment_id === attachmentId);

    Api.delete(`/photos/${photo.id}`, { headers })
      .then(res => {
        addToast('Foto verwijderd.', { appearance: 'success' });
        fetchMail();
        refetchWorkorder();
      })
      .catch(err => addToast('Er was een error bij het verwijderen van de foto.', { appearance: 'error' }));
  }

  const onImportCompanyLogo = (attachmentId, companyId) => {
    const data = {
      'company_id': companyId,
      'mailbox': mail.mailbox,
      'message_id': mail.graph_id,
      'attachment_id' : attachmentId ?? null,
    }

    Api.post(`/companies/storeLogoFromAttachment`, data, { headers })
      .then(res => {
        addToast('Logo geimporteerd.', { appearance: 'success' });
        fetchMail();
        refetchWorkorder();
      })
      .catch(err => addToast('Er was een error bij het importeren van het logo.', { appearance: 'error' }));
  }

  const onImportSiteorder = (attachmentId) => {
    const data = {
      'attachment_id': attachmentId,
      'mailbox': mail.mailbox,
      'message_id': mail.graph_id,
      'workorder_id': mail.workorder_id
    }

    Api.post(`/siteorders`, data, { headers })
      .then(res => {
        addToast('Werfbon toegevoegd aan werkbon.', { appearance: 'success' });
        fetchMail();
        refetchWorkorder();
      })
      .catch(err => addToast('Werfbon toegevoegen aan werkbon mislukt.', { appearance: 'error' }));

  }

  if(mailErr === 404){
    return (
      <Accordion className="pb-2">
        <Card>
          <Accordion.Toggle 
            as={Card.Header}
            className="alert-danger"
            eventKey="0"
          >
            <div className="row">
              <div className="col">
                <b>Verwijderd bericht</b>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span className="text-muted">Klik voor opties</span>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Tab.Container id="mail-tabs" defaultActiveKey="body" transition={false}>
              <Card.Body className="bg-light border-bottom pb-0">
                <div className="d-flex justify-content-between">

                  <Nav variant="tabs" className="border-0 mb-0">
                    <Nav.Item>
                      <Nav.Link eventKey="body">
                        <span>Bericht</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <DropdownButton id="dropdown-item-button" title="opties">
                    <Dropdown.Item 
                      eventKey="body"
                      as="button"
                      onClick={() => modalArchiveMailRef.current.showModal()}
                    >Mail loskoppelen</Dropdown.Item>
                  </DropdownButton>

                </div>

                <ModalArchiveMail 
                  ref={modalArchiveMailRef} 
                  onArchiveMail={onArchiveMail}
                />

              </Card.Body>

              <Card.Body>
                <Tab.Content className="h-100">
                  <Tab.Pane eventKey="body" className="h-100">
                    <div className="alert alert-danger" role="alert">
                      <span>Deze mail kan niet meer worden gevonden. Mogelijk werdt deze verwijderd in Outlook?</span>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
  }

  if(loading){
    return (
      <Card className="mb-2">
        <Loader />
      </Card>
    )
  }


  if(!mail){
    return (
      null
    )
  }

  return (
    <Accordion className="pb-2">
      <Card>
        <Accordion.Toggle 
          as={Card.Header}
          className={`${mail.approval ? 'alert-info' : ''}`}
          eventKey="0"
        >
          <div className="row">
            <div className="col">
              <b>{mail.from}</b>
            </div>
            <div className="col-auto">
              <span>{mail.received_at}</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span>{mail.subject}</span>
            </div>
          </div>
          {mail.categories && mail.categories[0] && 
            <div className="d-flex justify-content-start">
              <small className="mb-1 mr-1">
                <span className={`badge badge-${mail.categories[0].includes("Goedkeuring") ? 'success' : mail.categories[0].includes("Offerte") ? 'danger' : mail.categories[0].includes("Werkbon") ? 'warning' : 'light'} `}>{mail.categories[0]}</span>
              </small>
              <small className="mb-1 mr-1">
                <span className={`badge badge-light`}>{mail.mailbox}</span>
              </small>
            </div>
            }
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Tab.Container id="mail-tabs" defaultActiveKey="body" transition={false}>
            <Card.Body className="bg-light border-bottom pb-0">
              <div className="d-flex justify-content-between">

                <Nav variant="tabs" className="border-0 mb-0">
                  <Nav.Item>
                    <Nav.Link eventKey="body">
                      <span>Bericht</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="attachments">
                      <span>Bijlagen <span className="badge badge-primary">{mail.attachments.length}</span></span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <DropdownButton id="dropdown-item-button" title="opties">
                  <Dropdown.Item 
                    eventKey="body"
                    as="button"
                    onClick={() => modalArchiveMailRef.current.showModal()}
                  >Mail loskoppelen</Dropdown.Item>
                </DropdownButton>

              </div>

              <ModalArchiveMail 
                ref={modalArchiveMailRef} 
                onArchiveMail={onArchiveMail}
              />

            </Card.Body>

            <Card.Body>
              <Tab.Content className="h-100">
                <Tab.Pane eventKey="body" className="h-100">
                  <MailBody 
                    body={mail.body} 
                    attachments={mail.attachments}
                    onImportApproval={onImportApproval}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="attachments" className="h-100">
                  <MailAttachments 
                    attachments={mail.attachments} 
                    importedPhotos={mail.photos}
                    onImportApproval={onImportApproval}
                    onImportSiteorder={onImportSiteorder}
                    onImportPhoto={onImportPhoto}
                    onImportCompanyLogo={onImportCompanyLogo}
                    onRemovePhoto={onRemovePhoto}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default MailCard
