import React, { useState, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalPrintDay = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Modal 
      size="lg"
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Print dagoverzicht</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Klik op de printknop bij het pdf-document om het af te printen.</p>
        {props.base64pdf ?
          <embed
            src={`data:application/pdf;base64,${props.base64pdf}`}  
            className="flex-grow-1"
            style={{width: '100%', minHeight: '300px'}}
          ></embed >
        :
          <p className="text-muted small">Loading</p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Sluiten
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

export default ModalPrintDay
