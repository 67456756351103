const postalCodesBe = [

  // Brussel
  { province: 'Brussel', code: 1000, city: 'Brussel' },
  { province: 'Brussel', code: 1020, city: 'Laken' },
  { province: 'Brussel', code: 1030, city: 'Brussel' },
  { province: 'Brussel', code: 1040, city: 'Etterbeek' },
  { province: 'Brussel', code: 1050, city: 'Elsene' },
  { province: 'Brussel', code: 1060, city: 'Sint Gillis' },
  { province: 'Brussel', code: 1070, city: 'Anderlecht' },
  { province: 'Brussel', code: 1080, city: 'Sint Jans Molenbeek' },
  { province: 'Brussel', code: 1081, city: 'Koekelberg' },
  { province: 'Brussel', code: 1082, city: 'Sint Agatha Berchem' },
  { province: 'Brussel', code: 1083, city: 'Ganshoren' },
  { province: 'Brussel', code: 1090, city: 'Jette' },
  { province: 'Brussel', code: 1120, city: 'Neder Over Heembeek' },
  { province: 'Brussel', code: 1130, city: 'Haren' },
  { province: 'Brussel', code: 1140, city: 'Evere' },
  { province: 'Brussel', code: 1150, city: 'Sint Pieters Woluwe' },
  { province: 'Brussel', code: 1160, city: 'Oudergem' },
  { province: 'Brussel', code: 1170, city: 'Watermaal Bosvoorde' },
  { province: 'Brussel', code: 1180, city: 'Ukkel' },
  { province: 'Brussel', code: 1190, city: 'Vorst' },
  { province: 'Brussel', code: 1200, city: 'Sint Lambrechts Woluwe' },
  { province: 'Brussel', code: 1210, city: 'Sint Joost Ten Noode' },


  // Waals-Brabant
  { province: 'Waals-Brabant', code: 1300, city: 'Wavre' },
  { province: 'Waals-Brabant', code: 1300, city: 'Limal' },
  { province: 'Waals-Brabant', code: 1301, city: 'Bierges' },
  { province: 'Waals-Brabant', code: 1310, city: 'La hulpe' },
  { province: 'Waals-Brabant', code: 1315, city: 'Opprebais' },
  { province: 'Waals-Brabant', code: 1315, city: 'Incourt' },
  { province: 'Waals-Brabant', code: 1315, city: 'Roux-miroir' },
  { province: 'Waals-Brabant', code: 1315, city: 'Glimes' },
  { province: 'Waals-Brabant', code: 1315, city: 'Piètrebais' },
  { province: 'Waals-Brabant', code: 1320, city: 'L\'ecluse' },
  { province: 'Waals-Brabant', code: 1320, city: 'Nodebais' },
  { province: 'Waals-Brabant', code: 1320, city: 'Tourinnes-la-grosse' },
  { province: 'Waals-Brabant', code: 1320, city: 'Beauvechain' },
  { province: 'Waals-Brabant', code: 1320, city: 'Hamme-mille' },
  { province: 'Waals-Brabant', code: 1325, city: 'Chaumont-gistoux' },
  { province: 'Waals-Brabant', code: 1325, city: 'Bonlez' },
  { province: 'Waals-Brabant', code: 1325, city: 'Corroy-le-grand' },
  { province: 'Waals-Brabant', code: 1325, city: 'Dion-valmont' },
  { province: 'Waals-Brabant', code: 1325, city: 'Longueville' },
  { province: 'Waals-Brabant', code: 1330, city: 'Rixensart' },
  { province: 'Waals-Brabant', code: 1331, city: 'Rosières' },
  { province: 'Waals-Brabant', code: 1332, city: 'Genval' },
  { province: 'Waals-Brabant', code: 1340, city: 'Ottignies' },
  { province: 'Waals-Brabant', code: 1340, city: 'Ottignies-louvain-la-neuve' },
  { province: 'Waals-Brabant', code: 1341, city: 'Céroux-mousty' },
  { province: 'Waals-Brabant', code: 1342, city: 'Limelette' },
  { province: 'Waals-Brabant', code: 1348, city: 'Louvain-la-neuve' },
  { province: 'Waals-Brabant', code: 1350, city: 'Orp-jauche' },
  { province: 'Waals-Brabant', code: 1350, city: 'Orp-le-grand' },
  { province: 'Waals-Brabant', code: 1350, city: 'Marilles' },
  { province: 'Waals-Brabant', code: 1350, city: 'Noduwez' },
  { province: 'Waals-Brabant', code: 1350, city: 'Jandrain-jandrenouille' },
  { province: 'Waals-Brabant', code: 1350, city: 'Jauche' },
  { province: 'Waals-Brabant', code: 1350, city: 'Enines' },
  { province: 'Waals-Brabant', code: 1350, city: 'Folx-les-caves' },
  { province: 'Waals-Brabant', code: 1357, city: 'Hélécine' },
  { province: 'Waals-Brabant', code: 1357, city: 'Neerheylissem' },
  { province: 'Waals-Brabant', code: 1357, city: 'Linsmeau' },
  { province: 'Waals-Brabant', code: 1357, city: 'Opheylissem' },
  { province: 'Waals-Brabant', code: 1360, city: 'Orbais' },
  { province: 'Waals-Brabant', code: 1360, city: 'Thorembais-les-béguines' },
  { province: 'Waals-Brabant', code: 1360, city: 'Malèves-sainte-marie-wastines' },
  { province: 'Waals-Brabant', code: 1360, city: 'Thorembais-saint-trond' },
  { province: 'Waals-Brabant', code: 1360, city: 'Perwez' },
  { province: 'Waals-Brabant', code: 1367, city: 'Geest-gérompont-petit-rosière' },
  { province: 'Waals-Brabant', code: 1367, city: 'Ramillies' },
  { province: 'Waals-Brabant', code: 1367, city: 'Bomal' },
  { province: 'Waals-Brabant', code: 1367, city: 'Huppaye' },
  { province: 'Waals-Brabant', code: 1367, city: 'Autre-eglise' },
  { province: 'Waals-Brabant', code: 1367, city: 'Gérompont' },
  { province: 'Waals-Brabant', code: 1367, city: 'Grand-rosière-hottomont' },
  { province: 'Waals-Brabant', code: 1367, city: 'Mont-saint-andré' },
  { province: 'Waals-Brabant', code: 1370, city: 'Jodoigne-souveraine' },
  { province: 'Waals-Brabant', code: 1370, city: 'Lathuy' },
  { province: 'Waals-Brabant', code: 1370, city: 'Zétrud-lumay' },
  { province: 'Waals-Brabant', code: 1370, city: 'Mélin' },
  { province: 'Waals-Brabant', code: 1370, city: 'Piétrain' },
  { province: 'Waals-Brabant', code: 1370, city: 'Saint-jean-geest' },
  { province: 'Waals-Brabant', code: 1370, city: 'Dongelberg' },
  { province: 'Waals-Brabant', code: 1370, city: 'Saint-remy-geest' },
  { province: 'Waals-Brabant', code: 1370, city: 'Jauchelette' },
  { province: 'Waals-Brabant', code: 1370, city: 'Jodoigne' },
  { province: 'Waals-Brabant', code: 1380, city: 'Lasne' },
  { province: 'Waals-Brabant', code: 1380, city: 'Maransart' },
  { province: 'Waals-Brabant', code: 1380, city: 'Lasne-chapelle-saint-lambert' },
  { province: 'Waals-Brabant', code: 1380, city: 'Couture-saint-germain' },
  { province: 'Waals-Brabant', code: 1380, city: 'Ohain' },
  { province: 'Waals-Brabant', code: 1380, city: 'Plancenoit' },
  { province: 'Waals-Brabant', code: 1390, city: 'Biez' },
  { province: 'Waals-Brabant', code: 1390, city: 'Nethen' },
  { province: 'Waals-Brabant', code: 1390, city: 'Archennes' },
  { province: 'Waals-Brabant', code: 1390, city: 'Bossut-gottechain' },
  { province: 'Waals-Brabant', code: 1390, city: 'Grez-doiceau' },
  { province: 'Waals-Brabant', code: 1400, city: 'Nivelles' },
  { province: 'Waals-Brabant', code: 1400, city: 'Monstreux' },
  { province: 'Waals-Brabant', code: 1401, city: 'Baulers' },
  { province: 'Waals-Brabant', code: 1402, city: 'Thines' },
  { province: 'Waals-Brabant', code: 1404, city: 'Bornival' },
  { province: 'Waals-Brabant', code: 1410, city: 'Waterloo' },
  { province: 'Waals-Brabant', code: 1420, city: 'Braine-l\'alleud' },
  { province: 'Waals-Brabant', code: 1421, city: 'Ophain-bois-seigneur-isaac' },
  { province: 'Waals-Brabant', code: 1428, city: 'Lillois-witterzée' },
  { province: 'Waals-Brabant', code: 1430, city: 'Rebecq' },
  { province: 'Waals-Brabant', code: 1430, city: 'Rebecq-rognon' },
  { province: 'Waals-Brabant', code: 1430, city: 'Bierghes' },
  { province: 'Waals-Brabant', code: 1430, city: 'Quenast' },
  { province: 'Waals-Brabant', code: 1435, city: 'Mont-saint-guibert' },
  { province: 'Waals-Brabant', code: 1435, city: 'Hévillers' },
  { province: 'Waals-Brabant', code: 1435, city: 'Corbais' },
  { province: 'Waals-Brabant', code: 1440, city: 'Wauthier-braine' },
  { province: 'Waals-Brabant', code: 1440, city: 'Braine-le-château' },
  { province: 'Waals-Brabant', code: 1450, city: 'Gentinnes' },
  { province: 'Waals-Brabant', code: 1450, city: 'Cortil-noirmont' },
  { province: 'Waals-Brabant', code: 1450, city: 'Saint-géry' },
  { province: 'Waals-Brabant', code: 1450, city: 'Chastre' },
  { province: 'Waals-Brabant', code: 1450, city: 'Chastre-villeroux-blanmont' },
  { province: 'Waals-Brabant', code: 1457, city: 'Walhain' },
  { province: 'Waals-Brabant', code: 1457, city: 'Walhain-saint-paul' },
  { province: 'Waals-Brabant', code: 1457, city: 'Nil-saint-vincent-saint-martin' },
  { province: 'Waals-Brabant', code: 1457, city: 'Tourinnes-saint-lambert' },
  { province: 'Waals-Brabant', code: 1460, city: 'Ittre' },
  { province: 'Waals-Brabant', code: 1460, city: 'Virginal-samme' },
  { province: 'Waals-Brabant', code: 1461, city: 'Haut-ittre' },
  { province: 'Waals-Brabant', code: 1470, city: 'Genappe' },
  { province: 'Waals-Brabant', code: 1470, city: 'Bousval' },
  { province: 'Waals-Brabant', code: 1470, city: 'Baisy-thy' },
  { province: 'Waals-Brabant', code: 1471, city: 'Loupoigne' },
  { province: 'Waals-Brabant', code: 1472, city: 'Vieux-genappe' },
  { province: 'Waals-Brabant', code: 1473, city: 'Glabais' },
  { province: 'Waals-Brabant', code: 1474, city: 'Ways' },
  { province: 'Waals-Brabant', code: 1476, city: 'Houtain-le-val' },
  { province: 'Waals-Brabant', code: 1480, city: 'Clabecq' },
  { province: 'Waals-Brabant', code: 1480, city: 'Tubize' },
  { province: 'Waals-Brabant', code: 1480, city: 'Oisquercq' },
  { province: 'Waals-Brabant', code: 1480, city: 'Saintes' },
  { province: 'Waals-Brabant', code: 1490, city: 'Court-saint-etienne' },
  { province: 'Waals-Brabant', code: 1495, city: 'Sart-dames-avelines' },
  { province: 'Waals-Brabant', code: 1495, city: 'Tilly' },
  { province: 'Waals-Brabant', code: 1495, city: 'Marbais' },
  { province: 'Waals-Brabant', code: 1495, city: 'Mellery' },
  { province: 'Waals-Brabant', code: 1495, city: 'Villers-la-ville' },


  // Vlaams Brabant'},
  { province: 'Vlaams-Brabant', code: 1500, city: 'Halle' },
  { province: 'Vlaams-Brabant', code: 1501, city: 'Buizingen' },
  { province: 'Vlaams-Brabant', code: 1502, city: 'Lembeek' },
  { province: 'Vlaams-Brabant', code: 1540, city: 'Herfelingen' },
  { province: 'Vlaams-Brabant', code: 1540, city: 'Herne' },
  { province: 'Vlaams-Brabant', code: 1541, city: 'Sint-pieters-kapelle' },
  { province: 'Vlaams-Brabant', code: 1547, city: 'Bever' },
  { province: 'Vlaams-Brabant', code: 1560, city: 'Hoeilaart' },
  { province: 'Vlaams-Brabant', code: 1570, city: 'Galmaarden' },
  { province: 'Vlaams-Brabant', code: 1570, city: 'Tollembeek' },
  { province: 'Vlaams-Brabant', code: 1570, city: 'Vollezele' },
  { province: 'Vlaams-Brabant', code: 1600, city: 'Sint-Laureins-Berchem' },
  { province: 'Vlaams-Brabant', code: 1600, city: 'Oudenaken' },
  { province: 'Vlaams-Brabant', code: 1600, city: 'Sint-Pieters-Leeuw' },
  { province: 'Vlaams-Brabant', code: 1601, city: 'Ruisbroek' },
  { province: 'Vlaams-Brabant', code: 1602, city: 'Vlezenbeek' },
  { province: 'Vlaams-Brabant', code: 1620, city: 'Drogenbos' },
  { province: 'Vlaams-Brabant', code: 1630, city: 'Linkebeek' },
  { province: 'Vlaams-Brabant', code: 1640, city: 'Sint-Genesius-Rode' },
  { province: 'Vlaams-Brabant', code: 1650, city: 'Beersel' },
  { province: 'Vlaams-Brabant', code: 1651, city: 'Lot' },
  { province: 'Vlaams-Brabant', code: 1652, city: 'Alsemberg' },
  { province: 'Vlaams-Brabant', code: 1653, city: 'Dworp' },
  { province: 'Vlaams-Brabant', code: 1654, city: 'Huizingen' },
  { province: 'Vlaams-Brabant', code: 1670, city: 'Pepingen' },
  { province: 'Vlaams-Brabant', code: 1670, city: 'Bogaarden' },
  { province: 'Vlaams-Brabant', code: 1670, city: 'Heikruis' },
  { province: 'Vlaams-Brabant', code: 1671, city: 'Elingen' },
  { province: 'Vlaams-Brabant', code: 1673, city: 'Beert' },
  { province: 'Vlaams-Brabant', code: 1674, city: 'Bellingen' },
  { province: 'Vlaams-Brabant', code: 1700, city: 'Sint-martens-bodegem' },
  { province: 'Vlaams-Brabant', code: 1700, city: 'Dilbeek' },
  { province: 'Vlaams-Brabant', code: 1700, city: 'Sint-ulriks-kapelle' },
  { province: 'Vlaams-Brabant', code: 1701, city: 'Itterbeek' },
  { province: 'Vlaams-Brabant', code: 1702, city: 'Groot-bijgaarden' },
  { province: 'Vlaams-Brabant', code: 1703, city: 'Schepdaal' },
  { province: 'Vlaams-Brabant', code: 1730, city: 'Bekkerzeel' },
  { province: 'Vlaams-Brabant', code: 1730, city: 'Mollem' },
  { province: 'Vlaams-Brabant', code: 1730, city: 'Asse' },
  { province: 'Vlaams-Brabant', code: 1730, city: 'Kobbegem' },
  { province: 'Vlaams-Brabant', code: 1731, city: 'Zellik' },
  { province: 'Vlaams-Brabant', code: 1731, city: 'Relegem' },
  { province: 'Vlaams-Brabant', code: 1740, city: 'Ternat' },
  { province: 'Vlaams-Brabant', code: 1741, city: 'Wambeek' },
  { province: 'Vlaams-Brabant', code: 1742, city: 'Sint-Katherina-Lombeek' },
  { province: 'Vlaams-Brabant', code: 1745, city: 'Mazenzele' },
  { province: 'Vlaams-Brabant', code: 1745, city: 'Opwijk' },
  { province: 'Vlaams-Brabant', code: 1750, city: 'Sint-kwintens-lennik' },
  { province: 'Vlaams-Brabant', code: 1750, city: 'Lennik' },
  { province: 'Vlaams-Brabant', code: 1750, city: 'Sint-martens-lennik' },
  { province: 'Vlaams-Brabant', code: 1750, city: 'Gaasbeek' },
  { province: 'Vlaams-Brabant', code: 1755, city: 'Gooik' },
  { province: 'Vlaams-Brabant', code: 1755, city: 'Oetingen' },
  { province: 'Vlaams-Brabant', code: 1755, city: 'Kester' },
  { province: 'Vlaams-Brabant', code: 1755, city: 'Leerbeek' },
  { province: 'Vlaams-Brabant', code: 1760, city: 'Strijtem' },
  { province: 'Vlaams-Brabant', code: 1760, city: 'Onze-Lieve-Vrouw-Lombeek' },
  { province: 'Vlaams-Brabant', code: 1760, city: 'Roosdaal' },
  { province: 'Vlaams-Brabant', code: 1760, city: 'Pamel' },
  { province: 'Vlaams-Brabant', code: 1761, city: 'Borchtlombeek' },
  { province: 'Vlaams-Brabant', code: 1770, city: 'Liedekerke' },
  { province: 'Vlaams-Brabant', code: 1780, city: 'Wemmel' },
  { province: 'Vlaams-Brabant', code: 1785, city: 'Hamme' },
  { province: 'Vlaams-Brabant', code: 1785, city: 'Merchtem' },
  { province: 'Vlaams-Brabant', code: 1785, city: 'Peizegem' },
  { province: 'Vlaams-Brabant', code: 1785, city: 'Brussegem' },
  { province: 'Vlaams-Brabant', code: 1790, city: 'Affligem' },
  { province: 'Vlaams-Brabant', code: 1790, city: 'Hekelgem' },
  { province: 'Vlaams-Brabant', code: 1790, city: 'Essene' },
  { province: 'Vlaams-Brabant', code: 1790, city: 'Teralfene' },
  { province: 'Vlaams-Brabant', code: 1800, city: 'Vilvoorde' },
  { province: 'Vlaams-Brabant', code: 1800, city: 'Peutie' },
  { province: 'Vlaams-Brabant', code: 1820, city: 'Melsbroek' },
  { province: 'Vlaams-Brabant', code: 1820, city: 'Perk' },
  { province: 'Vlaams-Brabant', code: 1820, city: 'Steenokkerzeel' },
  { province: 'Vlaams-Brabant', code: 1830, city: 'Machelen' },
  { province: 'Vlaams-Brabant', code: 1831, city: 'Diegem' },
  { province: 'Vlaams-Brabant', code: 1840, city: 'Steenhuffel' },
  { province: 'Vlaams-Brabant', code: 1840, city: 'Malderen' },
  { province: 'Vlaams-Brabant', code: 1840, city: 'Londerzeel' },
  { province: 'Vlaams-Brabant', code: 1850, city: 'Grimbergen' },
  { province: 'Vlaams-Brabant', code: 1851, city: 'Humbeek' },
  { province: 'Vlaams-Brabant', code: 1852, city: 'Beigem' },
  { province: 'Vlaams-Brabant', code: 1853, city: 'Strombeek-bever' },
  { province: 'Vlaams-Brabant', code: 1860, city: 'Meise' },
  { province: 'Vlaams-Brabant', code: 1861, city: 'Wolvertem' },
  { province: 'Vlaams-Brabant', code: 1880, city: 'Nieuwenrode' },
  { province: 'Vlaams-Brabant', code: 1880, city: 'Ramsdonk' },
  { province: 'Vlaams-Brabant', code: 1880, city: 'Kapelle-op-Den-Bos' },
  { province: 'Vlaams-Brabant', code: 1910, city: 'Buken' },
  { province: 'Vlaams-Brabant', code: 1910, city: 'Kampenhout' },
  { province: 'Vlaams-Brabant', code: 1910, city: 'Nederokkerzeel' },
  { province: 'Vlaams-Brabant', code: 1910, city: 'Berg' },
  { province: 'Vlaams-Brabant', code: 1930, city: 'Zaventem' },
  { province: 'Vlaams-Brabant', code: 1930, city: 'Nossegem' },
  { province: 'Vlaams-Brabant', code: 1932, city: 'Sint-Stevens-Woluwe' },
  { province: 'Vlaams-Brabant', code: 1933, city: 'Sterrebeek' },
  { province: 'Vlaams-Brabant', code: 1950, city: 'Kraainem' },
  { province: 'Vlaams-Brabant', code: 1970, city: 'Wezembeek-Oppem' },
  { province: 'Vlaams-Brabant', code: 1980, city: 'Eppegem' },
  { province: 'Vlaams-Brabant', code: 1980, city: 'Zemst' },
  { province: 'Vlaams-Brabant', code: 1981, city: 'Hofstade' },
  { province: 'Vlaams-Brabant', code: 1982, city: 'Weerde' },
  { province: 'Vlaams-Brabant', code: 1982, city: 'Elewijt' },
  { province: 'Vlaams-Brabant', code: 3000, city: 'Leuven' },
  { province: 'Vlaams-Brabant', code: 3001, city: 'Heverlee' },
  { province: 'Vlaams-Brabant', code: 3010, city: 'Kessel-Lo' },
  { province: 'Vlaams-Brabant', code: 3012, city: 'Wilsele' },
  { province: 'Vlaams-Brabant', code: 3018, city: 'Wijgmaal' },
  { province: 'Vlaams-Brabant', code: 3020, city: 'Veltem-beisem' },
  { province: 'Vlaams-Brabant', code: 3020, city: 'Herent' },
  { province: 'Vlaams-Brabant', code: 3020, city: 'Winksele' },
  { province: 'Vlaams-Brabant', code: 3040, city: 'Ottenburg' },
  { province: 'Vlaams-Brabant', code: 3040, city: 'Huldenberg' },
  { province: 'Vlaams-Brabant', code: 3040, city: 'Sint-agatha-rode' },
  { province: 'Vlaams-Brabant', code: 3040, city: 'Neerijse' },
  { province: 'Vlaams-Brabant', code: 3040, city: 'Loonbeek' },
  { province: 'Vlaams-Brabant', code: 3050, city: 'Oud-Heverlee' },
  { province: 'Vlaams-Brabant', code: 3051, city: 'Sint-Joris-Weert' },
  { province: 'Vlaams-Brabant', code: 3052, city: 'Blanden' },
  { province: 'Vlaams-Brabant', code: 3053, city: 'Haasrode' },
  { province: 'Vlaams-Brabant', code: 3054, city: 'Vaalbeek' },
  { province: 'Vlaams-Brabant', code: 3060, city: 'Korbeek-Dijle' },
  { province: 'Vlaams-Brabant', code: 3060, city: 'Bertem' },
  { province: 'Vlaams-Brabant', code: 3061, city: 'Leefdaal' },
  { province: 'Vlaams-Brabant', code: 3070, city: 'Kortenberg' },
  { province: 'Vlaams-Brabant', code: 3071, city: 'Erps-kwerps' },
  { province: 'Vlaams-Brabant', code: 3078, city: 'Meerbeek' },
  { province: 'Vlaams-Brabant', code: 3078, city: 'Everberg' },
  { province: 'Vlaams-Brabant', code: 3080, city: 'Vossem' },
  { province: 'Vlaams-Brabant', code: 3080, city: 'Tervuren' },
  { province: 'Vlaams-Brabant', code: 3080, city: 'Duisburg' },
  { province: 'Vlaams-Brabant', code: 3090, city: 'Overijse' },
  { province: 'Vlaams-Brabant', code: 3110, city: 'Rotselaar' },
  { province: 'Vlaams-Brabant', code: 3111, city: 'Wezemaal' },
  { province: 'Vlaams-Brabant', code: 3118, city: 'Werchter' },
  { province: 'Vlaams-Brabant', code: 3120, city: 'Tremelo' },
  { province: 'Vlaams-Brabant', code: 3128, city: 'Baal' },
  { province: 'Vlaams-Brabant', code: 3130, city: 'Begijnendijk' },
  { province: 'Vlaams-Brabant', code: 3130, city: 'Betekom' },
  { province: 'Vlaams-Brabant', code: 3140, city: 'Keerbergen' },
  { province: 'Vlaams-Brabant', code: 3150, city: 'Wespelaar' },
  { province: 'Vlaams-Brabant', code: 3150, city: 'Haacht' },
  { province: 'Vlaams-Brabant', code: 3150, city: 'Tildonk' },
  { province: 'Vlaams-Brabant', code: 3190, city: 'Boortmeerbeek' },
  { province: 'Vlaams-Brabant', code: 3191, city: 'Hever' },
  { province: 'Vlaams-Brabant', code: 3200, city: 'Gelrode' },
  { province: 'Vlaams-Brabant', code: 3200, city: 'Aarschot' },
  { province: 'Vlaams-Brabant', code: 3201, city: 'Langdorp' },
  { province: 'Vlaams-Brabant', code: 3202, city: 'Rillaar' },
  { province: 'Vlaams-Brabant', code: 3210, city: 'Lubbeek' },
  { province: 'Vlaams-Brabant', code: 3210, city: 'Linden' },
  { province: 'Vlaams-Brabant', code: 3211, city: 'Binkom' },
  { province: 'Vlaams-Brabant', code: 3212, city: 'Pellenberg' },
  { province: 'Vlaams-Brabant', code: 3220, city: 'Kortrijk-dutsel' },
  { province: 'Vlaams-Brabant', code: 3220, city: 'Holsbeek' },
  { province: 'Vlaams-Brabant', code: 3220, city: 'Sint-pieters-rode' },
  { province: 'Vlaams-Brabant', code: 3221, city: 'Nieuwrode' },
  { province: 'Vlaams-Brabant', code: 3270, city: 'Scherpenheuvel' },
  { province: 'Vlaams-Brabant', code: 3270, city: 'Scherpenheuvel-Zichem' },
  { province: 'Vlaams-Brabant', code: 3271, city: 'Zichem' },
  { province: 'Vlaams-Brabant', code: 3271, city: 'Averbode' },
  { province: 'Vlaams-Brabant', code: 3272, city: 'Messelbroek' },
  { province: 'Vlaams-Brabant', code: 3272, city: 'Testelt' },
  { province: 'Vlaams-Brabant', code: 3290, city: 'Schaffen' },
  { province: 'Vlaams-Brabant', code: 3290, city: 'Deurne' },
  { province: 'Vlaams-Brabant', code: 3290, city: 'Diest' },
  { province: 'Vlaams-Brabant', code: 3290, city: 'Webbekom' },
  { province: 'Vlaams-Brabant', code: 3293, city: 'Kaggevinne' },
  { province: 'Vlaams-Brabant', code: 3294, city: 'Molenstede' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Vissenaken' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Hakendover' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Tienen' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Goetsenhoven' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Kumtich' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Oplinter' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Oorbeek' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Bost' },
  { province: 'Vlaams-Brabant', code: 3300, city: 'Sint-Margriete-Houtem' },
  { province: 'Vlaams-Brabant', code: 3320, city: 'Hoegaarden' },
  { province: 'Vlaams-Brabant', code: 3320, city: 'Meldert' },
  { province: 'Vlaams-Brabant', code: 3321, city: 'Outgaarden' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Neerlinter' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Wommersom' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Overhespen' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Drieslinter' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Linter' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Neerhespen' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Orsmaal-gussenhoven' },
  { province: 'Vlaams-Brabant', code: 3350, city: 'Melkwezer' },
  { province: 'Vlaams-Brabant', code: 3360, city: 'Bierbeek' },
  { province: 'Vlaams-Brabant', code: 3360, city: 'Lovenjoel' },
  { province: 'Vlaams-Brabant', code: 3360, city: 'Opvelp' },
  { province: 'Vlaams-Brabant', code: 3360, city: 'Korbeek-Lo' },
  { province: 'Vlaams-Brabant', code: 3370, city: 'Kerkom' },
  { province: 'Vlaams-Brabant', code: 3370, city: 'Willebringen' },
  { province: 'Vlaams-Brabant', code: 3370, city: 'Boutersem' },
  { province: 'Vlaams-Brabant', code: 3370, city: 'Neervelp' },
  { province: 'Vlaams-Brabant', code: 3370, city: 'Roosbeek' },
  { province: 'Vlaams-Brabant', code: 3370, city: 'Vertrijk' },
  { province: 'Vlaams-Brabant', code: 3380, city: 'Bunsbeek' },
  { province: 'Vlaams-Brabant', code: 3380, city: 'Glabbeek' },
  { province: 'Vlaams-Brabant', code: 3380, city: 'Glabbeek-Zuurbemde' },
  { province: 'Vlaams-Brabant', code: 3381, city: 'Kapellen' },
  { province: 'Vlaams-Brabant', code: 3384, city: 'Attenrode' },
  { province: 'Vlaams-Brabant', code: 3390, city: 'Tielt-Winge' },
  { province: 'Vlaams-Brabant', code: 3390, city: 'Tielt' },
  { province: 'Vlaams-Brabant', code: 3390, city: 'Sint-Joris-Winge' },
  { province: 'Vlaams-Brabant', code: 3390, city: 'Houwaart' },
  { province: 'Vlaams-Brabant', code: 3391, city: 'Meensel-Kiezegem' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Eliksem' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Neerwinden' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Rumsdorp' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Laar' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Wange' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Ezemaal' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Overwinden' },
  { province: 'Vlaams-Brabant', code: 3400, city: 'Landen' },
  { province: 'Vlaams-Brabant', code: 3401, city: 'Waasmont' },
  { province: 'Vlaams-Brabant', code: 3401, city: 'Walsbets' },
  { province: 'Vlaams-Brabant', code: 3401, city: 'Walshoutem' },
  { province: 'Vlaams-Brabant', code: 3401, city: 'Wezeren' },
  { province: 'Vlaams-Brabant', code: 3404, city: 'Attenhoven' },
  { province: 'Vlaams-Brabant', code: 3404, city: 'Neerlanden' },
  { province: 'Vlaams-Brabant', code: 3440, city: 'Dormaal' },
  { province: 'Vlaams-Brabant', code: 3440, city: 'Budingen' },
  { province: 'Vlaams-Brabant', code: 3440, city: 'Halle-Booienhoven' },
  { province: 'Vlaams-Brabant', code: 3440, city: 'Zoutleeuw' },
  { province: 'Vlaams-Brabant', code: 3440, city: 'Helen-Bos' },
  { province: 'Vlaams-Brabant', code: 3450, city: 'Geetbets' },
  { province: 'Vlaams-Brabant', code: 3450, city: 'Grazen' },
  { province: 'Vlaams-Brabant', code: 3454, city: 'Rummen' },
  { province: 'Vlaams-Brabant', code: 3460, city: 'Bekkevoort' },
  { province: 'Vlaams-Brabant', code: 3460, city: 'Assent' },
  { province: 'Vlaams-Brabant', code: 3461, city: 'Molenbeek-Wersbeek' },
  { province: 'Vlaams-Brabant', code: 3470, city: 'Kortenaken' },
  { province: 'Vlaams-Brabant', code: 3470, city: 'Ransberg' },
  { province: 'Vlaams-Brabant', code: 3470, city: 'Sint-margriete-houtem' },
  { province: 'Vlaams-Brabant', code: 3471, city: 'Hoeleden' },
  { province: 'Vlaams-Brabant', code: 3472, city: 'Kersbeek-miskom' },
  { province: 'Vlaams-Brabant', code: 3473, city: 'Waanrode' },


  // Antwerpen
  { province: 'Antwerpen', code: 2000, city: 'Antwerpen' },
  { province: 'Antwerpen', code: 2018, city: 'Antwerpen' },
  { province: 'Antwerpen', code: 2020, city: 'Antwerpen' },
  { province: 'Antwerpen', code: 2030, city: 'Antwerpen' },
  { province: 'Antwerpen', code: 2040, city: 'Lillo' },
  { province: 'Antwerpen', code: 2040, city: 'Zandvliet' },
  { province: 'Antwerpen', code: 2040, city: 'Berendrecht' },
  { province: 'Antwerpen', code: 2040, city: 'Antwerpen' },
  { province: 'Antwerpen', code: 2050, city: 'Antwerpen' },
  { province: 'Antwerpen', code: 2060, city: 'Antwerpen' },
  { province: 'Antwerpen', code: 2070, city: 'Burcht' },
  { province: 'Antwerpen', code: 2070, city: 'Zwijndrecht' },
  { province: 'Antwerpen', code: 2100, city: 'Deurne' },
  { province: 'Antwerpen', code: 2110, city: 'Wijnegem' },
  { province: 'Antwerpen', code: 2140, city: 'Borgerhout' },
  { province: 'Antwerpen', code: 2150, city: 'Borsbeek' },
  { province: 'Antwerpen', code: 2160, city: 'Wommelgem' },
  { province: 'Antwerpen', code: 2170, city: 'Merksem' },
  { province: 'Antwerpen', code: 2180, city: 'Ekeren' },
  { province: 'Antwerpen', code: 2200, city: 'Noorderwijk' },
  { province: 'Antwerpen', code: 2200, city: 'Morkhoven' },
  { province: 'Antwerpen', code: 2200, city: 'Herentals' },
  { province: 'Antwerpen', code: 2220, city: 'Hallaar' },
  { province: 'Antwerpen', code: 2220, city: 'Heist-op-den-Berg' },
  { province: 'Antwerpen', code: 2221, city: 'Booischot' },
  { province: 'Antwerpen', code: 2222, city: 'Itegem' },
  { province: 'Antwerpen', code: 2222, city: 'Wiekevorst' },
  { province: 'Antwerpen', code: 2223, city: 'Schriek' },
  { province: 'Antwerpen', code: 2230, city: 'Herselt' },
  { province: 'Antwerpen', code: 2230, city: 'Ramsel' },
  { province: 'Antwerpen', code: 2235, city: 'Houtvenne' },
  { province: 'Antwerpen', code: 2235, city: 'Hulshout' },
  { province: 'Antwerpen', code: 2235, city: 'Westmeerbeek' },
  { province: 'Antwerpen', code: 2240, city: 'Massenhoven' },
  { province: 'Antwerpen', code: 2240, city: 'Viersel' },
  { province: 'Antwerpen', code: 2240, city: 'Zandhoven' },
  { province: 'Antwerpen', code: 2242, city: 'Pulderbos' },
  { province: 'Antwerpen', code: 2243, city: 'Pulle' },
  { province: 'Antwerpen', code: 2250, city: 'Olen' },
  { province: 'Antwerpen', code: 2260, city: 'Zoerle-Parwijs' },
  { province: 'Antwerpen', code: 2260, city: 'Westerlo' },
  { province: 'Antwerpen', code: 2260, city: 'Oevel' },
  { province: 'Antwerpen', code: 2260, city: 'Tongerlo' },
  { province: 'Antwerpen', code: 2270, city: 'Herenthout' },
  { province: 'Antwerpen', code: 2275, city: 'Gierle' },
  { province: 'Antwerpen', code: 2275, city: 'Lille' },
  { province: 'Antwerpen', code: 2275, city: 'Wechelderzande' },
  { province: 'Antwerpen', code: 2275, city: 'Poederlee' },
  { province: 'Antwerpen', code: 2280, city: 'Grobbendonk' },
  { province: 'Antwerpen', code: 2288, city: 'Bouwel' },
  { province: 'Antwerpen', code: 2290, city: 'Vorselaar' },
  { province: 'Antwerpen', code: 2300, city: 'Turnhout' },
  { province: 'Antwerpen', code: 2310, city: 'Rijkevorsel' },
  { province: 'Antwerpen', code: 2320, city: 'Hoogstraten' },
  { province: 'Antwerpen', code: 2321, city: 'Meer' },
  { province: 'Antwerpen', code: 2322, city: 'Minderhout' },
  { province: 'Antwerpen', code: 2323, city: 'Wortel' },
  { province: 'Antwerpen', code: 2328, city: 'Meerle' },
  { province: 'Antwerpen', code: 2330, city: 'Merksplas' },
  { province: 'Antwerpen', code: 2340, city: 'Vlimmeren' },
  { province: 'Antwerpen', code: 2340, city: 'Beerse' },
  { province: 'Antwerpen', code: 2350, city: 'Vosselaar' },
  { province: 'Antwerpen', code: 2360, city: 'Oud-Turnhout' },
  { province: 'Antwerpen', code: 2370, city: 'Arendonk' },
  { province: 'Antwerpen', code: 2380, city: 'Ravels' },
  { province: 'Antwerpen', code: 2381, city: 'Weelde' },
  { province: 'Antwerpen', code: 2382, city: 'Poppel' },
  { province: 'Antwerpen', code: 2387, city: 'Baarle-Hertog' },
  { province: 'Antwerpen', code: 2390, city: 'Oostmalle' },
  { province: 'Antwerpen', code: 2390, city: 'Malle' },
  { province: 'Antwerpen', code: 2390, city: 'Westmalle' },
  { province: 'Antwerpen', code: 2400, city: 'Mol' },
  { province: 'Antwerpen', code: 2430, city: 'Eindhout' },
  { province: 'Antwerpen', code: 2430, city: 'Laakdal' },
  { province: 'Antwerpen', code: 2430, city: 'Vorst' },
  { province: 'Antwerpen', code: 2431, city: 'Varendonk' },
  { province: 'Antwerpen', code: 2431, city: 'Veerle' },
  { province: 'Antwerpen', code: 2440, city: 'Geel' },
  { province: 'Antwerpen', code: 2450, city: 'Meerhout' },
  { province: 'Antwerpen', code: 2460, city: 'Kasterlee' },
  { province: 'Antwerpen', code: 2460, city: 'Tielen' },
  { province: 'Antwerpen', code: 2460, city: 'Lichtaart' },
  { province: 'Antwerpen', code: 2470, city: 'Retie' },
  { province: 'Antwerpen', code: 2480, city: 'Dessel' },
  { province: 'Antwerpen', code: 2490, city: 'Balen' },
  { province: 'Antwerpen', code: 2491, city: 'Olmen' },
  { province: 'Antwerpen', code: 2500, city: 'Koningshooikt' },
  { province: 'Antwerpen', code: 2500, city: 'Lier' },
  { province: 'Antwerpen', code: 2520, city: 'Oelegem' },
  { province: 'Antwerpen', code: 2520, city: 'Ranst' },
  { province: 'Antwerpen', code: 2520, city: 'Emblem' },
  { province: 'Antwerpen', code: 2520, city: 'Broechem' },
  { province: 'Antwerpen', code: 2530, city: 'Boechout' },
  { province: 'Antwerpen', code: 2531, city: 'Vremde' },
  { province: 'Antwerpen', code: 2540, city: 'Hove' },
  { province: 'Antwerpen', code: 2547, city: 'Lint' },
  { province: 'Antwerpen', code: 2550, city: 'Waarloos' },
  { province: 'Antwerpen', code: 2550, city: 'Kontich' },
  { province: 'Antwerpen', code: 2560, city: 'Nijlen' },
  { province: 'Antwerpen', code: 2560, city: 'Kessel' },
  { province: 'Antwerpen', code: 2560, city: 'Bevel' },
  { province: 'Antwerpen', code: 2570, city: 'Duffel' },
  { province: 'Antwerpen', code: 2580, city: 'Putte' },
  { province: 'Antwerpen', code: 2580, city: 'Beerzel' },
  { province: 'Antwerpen', code: 2590, city: 'Gestel' },
  { province: 'Antwerpen', code: 2590, city: 'Berlaar' },
  { province: 'Antwerpen', code: 2600, city: 'Berchem' },
  { province: 'Antwerpen', code: 2610, city: 'Wilrijk' },
  { province: 'Antwerpen', code: 2620, city: 'Hemiksem' },
  { province: 'Antwerpen', code: 2627, city: 'Schelle' },
  { province: 'Antwerpen', code: 2630, city: 'Aartselaar' },
  { province: 'Antwerpen', code: 2640, city: 'Mortsel' },
  { province: 'Antwerpen', code: 2650, city: 'Edegem' },
  { province: 'Antwerpen', code: 2660, city: 'Hoboken' },
  { province: 'Antwerpen', code: 2800, city: 'Walem' },
  { province: 'Antwerpen', code: 2800, city: 'Mechelen' },
  { province: 'Antwerpen', code: 2801, city: 'Heffen' },
  { province: 'Antwerpen', code: 2811, city: 'Hombeek' },
  { province: 'Antwerpen', code: 2811, city: 'Leest' },
  { province: 'Antwerpen', code: 2812, city: 'Muizen' },
  { province: 'Antwerpen', code: 2820, city: 'Rijmenam' },
  { province: 'Antwerpen', code: 2820, city: 'Bonheiden' },
  { province: 'Antwerpen', code: 2830, city: 'Willebroek' },
  { province: 'Antwerpen', code: 2830, city: 'Tisselt' },
  { province: 'Antwerpen', code: 2830, city: 'Heindonk' },
  { province: 'Antwerpen', code: 2830, city: 'Blaasveld' },
  { province: 'Antwerpen', code: 2840, city: 'Terhagen' },
  { province: 'Antwerpen', code: 2840, city: 'Rumst' },
  { province: 'Antwerpen', code: 2840, city: 'Reet' },
  { province: 'Antwerpen', code: 2845, city: 'Niel' },
  { province: 'Antwerpen', code: 2850, city: 'Boom' },
  { province: 'Antwerpen', code: 2860, city: 'Sint-Katelijne-Waver' },
  { province: 'Antwerpen', code: 2861, city: 'Onze-Lieve-Vrouw-Waver' },
  { province: 'Antwerpen', code: 2870, city: 'Liezele' },
  { province: 'Antwerpen', code: 2870, city: 'Ruisbroek' },
  { province: 'Antwerpen', code: 2870, city: 'Puurs' },
  { province: 'Antwerpen', code: 2870, city: 'Breendonk' },
  { province: 'Antwerpen', code: 2880, city: 'Mariekerke' },
  { province: 'Antwerpen', code: 2880, city: 'Hingene' },
  { province: 'Antwerpen', code: 2880, city: 'Bornem' },
  { province: 'Antwerpen', code: 2880, city: 'Weert' },
  { province: 'Antwerpen', code: 2890, city: 'Lippelo' },
  { province: 'Antwerpen', code: 2890, city: 'Sint-Amands' },
  { province: 'Antwerpen', code: 2890, city: 'Oppuurs' },
  { province: 'Antwerpen', code: 2900, city: 'Schoten' },
  { province: 'Antwerpen', code: 2910, city: 'Essen' },
  { province: 'Antwerpen', code: 2920, city: 'Kalmthout' },
  { province: 'Antwerpen', code: 2930, city: 'Brasschaat' },
  { province: 'Antwerpen', code: 2940, city: 'Stabroek' },
  { province: 'Antwerpen', code: 2940, city: 'Hoevenen' },
  { province: 'Antwerpen', code: 2950, city: 'Kapellen' },
  { province: 'Antwerpen', code: 2960, city: 'Sint-Lenaarts' },
  { province: 'Antwerpen', code: 2960, city: 'Brecht' },
  { province: 'Antwerpen', code: 2960, city: 'Sint-Job-in-\'t-Goor' },
  { province: 'Antwerpen', code: 2970, city: '\'s Gravenwezel' },
  { province: 'Antwerpen', code: 2970, city: 'Schilde' },
  { province: 'Antwerpen', code: 2980, city: 'Zoersel' },
  { province: 'Antwerpen', code: 2980, city: 'Halle' },
  { province: 'Antwerpen', code: 2990, city: 'Loenhout' },
  { province: 'Antwerpen', code: 2990, city: 'Wuustwezel' },





  // limburg
  { province: 'Limburg', code: 3500, city: 'Hasselt' },
  { province: 'Limburg', code: 3500, city: 'Sint-lambrechts-herk' },
  { province: 'Limburg', code: 3501, city: 'Wimmertingen' },
  { province: 'Limburg', code: 3510, city: 'Spalbeek' },
  { province: 'Limburg', code: 3510, city: 'Kermt' },
  { province: 'Limburg', code: 3511, city: 'Kuringen' },
  { province: 'Limburg', code: 3511, city: 'Stokrooie' },
  { province: 'Limburg', code: 3512, city: 'Stevoort' },
  { province: 'Limburg', code: 3520, city: 'Zonhoven' },
  { province: 'Limburg', code: 3530, city: 'Houthalen' },
  { province: 'Limburg', code: 3530, city: 'Houthalen-Helchteren' },
  { province: 'Limburg', code: 3530, city: 'Helchteren' },
  { province: 'Limburg', code: 3540, city: 'Berbroek' },
  { province: 'Limburg', code: 3540, city: 'Herk-de-Stad' },
  { province: 'Limburg', code: 3540, city: 'Donk' },
  { province: 'Limburg', code: 3540, city: 'Schulen' },
  { province: 'Limburg', code: 3545, city: 'Zelem' },
  { province: 'Limburg', code: 3545, city: 'Halen' },
  { province: 'Limburg', code: 3545, city: 'Loksbergen' },
  { province: 'Limburg', code: 3550, city: 'Heusden' },
  { province: 'Limburg', code: 3550, city: 'Heusden-Zolder' },
  { province: 'Limburg', code: 3550, city: 'Zolder' },
  { province: 'Limburg', code: 3560, city: 'Lummen' },
  { province: 'Limburg', code: 3560, city: 'Linkhout' },
  { province: 'Limburg', code: 3560, city: 'Meldert' },
  { province: 'Limburg', code: 3570, city: 'Alken' },
  { province: 'Limburg', code: 3580, city: 'Beringen' },
  { province: 'Limburg', code: 3581, city: 'Beverlo' },
  { province: 'Limburg', code: 3582, city: 'Koersel' },
  { province: 'Limburg', code: 3583, city: 'Paal' },
  { province: 'Limburg', code: 3590, city: 'Diepenbeek' },
  { province: 'Limburg', code: 3600, city: 'Genk' },
  { province: 'Limburg', code: 3620, city: 'Gellik' },
  { province: 'Limburg', code: 3620, city: 'Neerharen' },
  { province: 'Limburg', code: 3620, city: 'Veldwezelt' },
  { province: 'Limburg', code: 3620, city: 'Lanaken' },
  { province: 'Limburg', code: 3621, city: 'Rekem' },
  { province: 'Limburg', code: 3630, city: 'Maasmechelen' },
  { province: 'Limburg', code: 3630, city: 'Vucht' },
  { province: 'Limburg', code: 3630, city: 'Mechelen-aan-de-maas' },
  { province: 'Limburg', code: 3630, city: 'Eisden' },
  { province: 'Limburg', code: 3630, city: 'Meeswijk' },
  { province: 'Limburg', code: 3630, city: 'Leut' },
  { province: 'Limburg', code: 3630, city: 'Opgrimbie' },
  { province: 'Limburg', code: 3631, city: 'Boorsem' },
  { province: 'Limburg', code: 3631, city: 'Uikhoven' },
  { province: 'Limburg', code: 3640, city: 'Ophoven' },
  { province: 'Limburg', code: 3640, city: 'Molenbeersel' },
  { province: 'Limburg', code: 3640, city: 'Kessenich' },
  { province: 'Limburg', code: 3640, city: 'Kinrooi' },
  { province: 'Limburg', code: 3650, city: 'Dilsen' },
  { province: 'Limburg', code: 3650, city: 'Dilsen-Stokkem' },
  { province: 'Limburg', code: 3650, city: 'Lanklaar' },
  { province: 'Limburg', code: 3650, city: 'Stokkem' },
  { province: 'Limburg', code: 3650, city: 'Rotem' },
  { province: 'Limburg', code: 3650, city: 'Elen' },
  { province: 'Limburg', code: 3660, city: 'Oudsbergen' },
  { province: 'Limburg', code: 3665, city: 'As' },
  { province: 'Limburg', code: 3668, city: 'Niel-bij-As' },
  { province: 'Limburg', code: 3670, city: 'Gruitrode' },
  { province: 'Limburg', code: 3670, city: 'Meeuwen' },
  { province: 'Limburg', code: 3670, city: 'Ellikom' },
  { province: 'Limburg', code: 3670, city: 'Oudsbergen' },
  { province: 'Limburg', code: 3670, city: 'Wijshagen' },
  { province: 'Limburg', code: 3670, city: 'Neerglabbeek' },
  { province: 'Limburg', code: 3680, city: 'Maaseik' },
  { province: 'Limburg', code: 3680, city: 'Neeroeteren' },
  { province: 'Limburg', code: 3680, city: 'Opoeteren' },
  { province: 'Limburg', code: 3690, city: 'Zutendaal' },
  { province: 'Limburg', code: 3700, city: 'Koninksem' },
  { province: 'Limburg', code: 3700, city: 'Berg' },
  { province: 'Limburg', code: 3700, city: 'Rutten' },
  { province: 'Limburg', code: 3700, city: 'Sluizen' },
  { province: 'Limburg', code: 3700, city: 'Kolmont (Jesseren)' },
  { province: 'Limburg', code: 3700, city: 'Mal' },
  { province: 'Limburg', code: 3700, city: 'Widooie' },
  { province: 'Limburg', code: 3700, city: 'Vreren' },
  { province: 'Limburg', code: 3700, city: 'Henis' },
  { province: 'Limburg', code: 3700, city: 'Lauw' },
  { province: 'Limburg', code: 3700, city: 'Neerrepen' },
  { province: 'Limburg', code: 3700, city: '\'s Herenelderen' },
  { province: 'Limburg', code: 3700, city: 'Nerem' },
  { province: 'Limburg', code: 3700, city: 'Riksingen' },
  { province: 'Limburg', code: 3700, city: 'Overrepen' },
  { province: 'Limburg', code: 3700, city: 'Piringen' },
  { province: 'Limburg', code: 3700, city: 'Tongeren' },
  { province: 'Limburg', code: 3700, city: 'Diets-Heur' },
  { province: 'Limburg', code: 3700, city: 'Haren' },
  { province: 'Limburg', code: 3717, city: 'Herstappe' },
  { province: 'Limburg', code: 3720, city: 'Kortessem' },
  { province: 'Limburg', code: 3721, city: 'Vliermaalroot' },
  { province: 'Limburg', code: 3722, city: 'Wintershoven' },
  { province: 'Limburg', code: 3723, city: 'Guigoven' },
  { province: 'Limburg', code: 3724, city: 'Vliermaal' },
  { province: 'Limburg', code: 3730, city: 'Romershoven' },
  { province: 'Limburg', code: 3730, city: 'Sint-huibrechts-hern' },
  { province: 'Limburg', code: 3730, city: 'Werm' },
  { province: 'Limburg', code: 3730, city: 'Hoeselt' },
  { province: 'Limburg', code: 3732, city: 'Schalkhoven' },
  { province: 'Limburg', code: 3740, city: 'Grote-Spouwen' },
  { province: 'Limburg', code: 3740, city: 'Eigenbilzen' },
  { province: 'Limburg', code: 3740, city: 'Waltwilder' },
  { province: 'Limburg', code: 3740, city: 'Kleine-Spouwen' },
  { province: 'Limburg', code: 3740, city: 'Beverst' },
  { province: 'Limburg', code: 3740, city: 'Hees' },
  { province: 'Limburg', code: 3740, city: 'Mopertingen' },
  { province: 'Limburg', code: 3740, city: 'Rijkhoven' },
  { province: 'Limburg', code: 3740, city: 'Bilzen' },
  { province: 'Limburg', code: 3740, city: 'Munsterbilzen' },
  { province: 'Limburg', code: 3740, city: 'Rosmeer' },
  { province: 'Limburg', code: 3742, city: 'Martenslinde' },
  { province: 'Limburg', code: 3746, city: 'Hoelbeek' },
  { province: 'Limburg', code: 3770, city: 'Membruggen' },
  { province: 'Limburg', code: 3770, city: 'Val-meer' },
  { province: 'Limburg', code: 3770, city: 'Vroenhoven' },
  { province: 'Limburg', code: 3770, city: 'Riemst' },
  { province: 'Limburg', code: 3770, city: 'Genoelselderen' },
  { province: 'Limburg', code: 3770, city: 'Millen' },
  { province: 'Limburg', code: 3770, city: 'Kanne' },
  { province: 'Limburg', code: 3770, city: 'Zichen-Zussen-Bolder' },
  { province: 'Limburg', code: 3770, city: 'Vlijtingen' },
  { province: 'Limburg', code: 3770, city: 'Herderen' },
  { province: 'Limburg', code: 3790, city: 'Voeren' },
  { province: 'Limburg', code: 3790, city: 'Moelingen' },
  { province: 'Limburg', code: 3790, city: 'Sint-Martens-Voeren' },
  { province: 'Limburg', code: 3791, city: 'Remersdaal' },
  { province: 'Limburg', code: 3792, city: 'Sint-Pieters-Voeren' },
  { province: 'Limburg', code: 3793, city: 'Teuven' },
  { province: 'Limburg', code: 3798, city: '\'s-Gravenvoeren' },
  { province: 'Limburg', code: 3800, city: 'Aalst' },
  { province: 'Limburg', code: 3800, city: 'Ordingen' },
  { province: 'Limburg', code: 3800, city: 'Zepperen' },
  { province: 'Limburg', code: 3800, city: 'Halmaal' },
  { province: 'Limburg', code: 3800, city: 'Kerkom-bij-sint-truiden' },
  { province: 'Limburg', code: 3800, city: 'Brustem' },
  { province: 'Limburg', code: 3800, city: 'Sint-truiden' },
  { province: 'Limburg', code: 3800, city: 'Engelmanshoven' },
  { province: 'Limburg', code: 3800, city: 'Groot-gelmen' },
  { province: 'Limburg', code: 3800, city: 'Gelinden' },
  { province: 'Limburg', code: 3803, city: 'Wilderen' },
  { province: 'Limburg', code: 3803, city: 'Runkelen' },
  { province: 'Limburg', code: 3803, city: 'Duras' },
  { province: 'Limburg', code: 3803, city: 'Gorsem' },
  { province: 'Limburg', code: 3806, city: 'Velm' },
  { province: 'Limburg', code: 3830, city: 'Berlingen' },
  { province: 'Limburg', code: 3830, city: 'Wellen' },
  { province: 'Limburg', code: 3831, city: 'Herten' },
  { province: 'Limburg', code: 3832, city: 'Ulbeek' },
  { province: 'Limburg', code: 3840, city: 'Bommershoven' },
  { province: 'Limburg', code: 3840, city: 'Haren' },
  { province: 'Limburg', code: 3840, city: 'Rijkel' },
  { province: 'Limburg', code: 3840, city: 'Borgloon' },
  { province: 'Limburg', code: 3840, city: 'Jesseren' },
  { province: 'Limburg', code: 3840, city: 'Kolmont (Borgloon)' },
  { province: 'Limburg', code: 3840, city: 'Kerniel' },
  { province: 'Limburg', code: 3840, city: 'Broekom' },
  { province: 'Limburg', code: 3840, city: 'Gors-opleeuw' },
  { province: 'Limburg', code: 3840, city: 'Voort' },
  { province: 'Limburg', code: 3840, city: 'Gotem' },
  { province: 'Limburg', code: 3840, city: 'Hoepertingen' },
  { province: 'Limburg', code: 3840, city: 'Kuttekoven' },
  { province: 'Limburg', code: 3840, city: 'Groot-loon' },
  { province: 'Limburg', code: 3840, city: 'Hendrieken' },
  { province: 'Limburg', code: 3850, city: 'Nieuwerkerken' },
  { province: 'Limburg', code: 3850, city: 'Binderveld' },
  { province: 'Limburg', code: 3850, city: 'Wijer' },
  { province: 'Limburg', code: 3850, city: 'Kozen' },
  { province: 'Limburg', code: 3870, city: 'Opheers' },
  { province: 'Limburg', code: 3870, city: 'Mettekoven' },
  { province: 'Limburg', code: 3870, city: 'Vechmaal' },
  { province: 'Limburg', code: 3870, city: 'Bovelingen' },
  { province: 'Limburg', code: 3870, city: 'Gutshoven' },
  { province: 'Limburg', code: 3870, city: 'Heers' },
  { province: 'Limburg', code: 3870, city: 'Mechelen-Bovelingen' },
  { province: 'Limburg', code: 3870, city: 'Heks' },
  { province: 'Limburg', code: 3870, city: 'Veulen' },
  { province: 'Limburg', code: 3870, city: 'Horpmaal' },
  { province: 'Limburg', code: 3870, city: 'Batsheers' },
  { province: 'Limburg', code: 3870, city: 'Klein-Gelmen' },
  { province: 'Limburg', code: 3870, city: 'Rukkelingen-Loon' },
  { province: 'Limburg', code: 3890, city: 'Jeuk' },
  { province: 'Limburg', code: 3890, city: 'Niel-bij-sint-truiden' },
  { province: 'Limburg', code: 3890, city: 'Gingelom' },
  { province: 'Limburg', code: 3890, city: 'Montenaken' },
  { province: 'Limburg', code: 3890, city: 'Boekhout' },
  { province: 'Limburg', code: 3890, city: 'Kortijs' },
  { province: 'Limburg', code: 3890, city: 'Vorsen' },
  { province: 'Limburg', code: 3891, city: 'Mielen-boven-aalst' },
  { province: 'Limburg', code: 3891, city: 'Borlo' },
  { province: 'Limburg', code: 3891, city: 'Muizen' },
  { province: 'Limburg', code: 3891, city: 'Buvingen' },
  { province: 'Limburg', code: 3900, city: 'Overpelt' },
  { province: 'Limburg', code: 3910, city: 'Neerpelt' },
  { province: 'Limburg', code: 3910, city: 'Sint-Huibrechts-Lille' },
  { province: 'Limburg', code: 3920, city: 'Lommel' },
  { province: 'Limburg', code: 3930, city: 'Achel' },
  { province: 'Limburg', code: 3930, city: 'Hamont' },
  { province: 'Limburg', code: 3930, city: 'Hamont-Achel' },
  { province: 'Limburg', code: 3940, city: 'Hechtel' },
  { province: 'Limburg', code: 3940, city: 'Hechtel-Eksel' },
  { province: 'Limburg', code: 3941, city: 'Eksel' },
  { province: 'Limburg', code: 3945, city: 'Kwaadmechelen' },
  { province: 'Limburg', code: 3945, city: 'Ham' },
  { province: 'Limburg', code: 3945, city: 'Oostham' },
  { province: 'Limburg', code: 3950, city: 'Reppel' },
  { province: 'Limburg', code: 3950, city: 'Kaulille' },
  { province: 'Limburg', code: 3950, city: 'Bocholt' },
  { province: 'Limburg', code: 3960, city: 'Opitter' },
  { province: 'Limburg', code: 3960, city: 'Gerdingen' },
  { province: 'Limburg', code: 3960, city: 'Bree' },
  { province: 'Limburg', code: 3960, city: 'Tongerlo' },
  { province: 'Limburg', code: 3960, city: 'Beek' },
  { province: 'Limburg', code: 3970, city: 'Leopoldsburg' },
  { province: 'Limburg', code: 3971, city: 'Heppen' },
  { province: 'Limburg', code: 3980, city: 'Tessenderlo' },
  { province: 'Limburg', code: 3990, city: 'Grote-brogel' },
  { province: 'Limburg', code: 3990, city: 'Kleine-brogel' },
  { province: 'Limburg', code: 3990, city: 'Peer' },
  { province: 'Limburg', code: 3990, city: 'Wijchmaal' },






  // Luik
  { province: 'Luik', code: 4000, city: 'Glain' },
  { province: 'Luik', code: 4000, city: 'Rocourt' },
  { province: 'Luik', code: 4000, city: 'Luik' },
  { province: 'Luik', code: 4020, city: 'Wandre' },
  { province: 'Luik', code: 4020, city: 'Bressoux' },
  { province: 'Luik', code: 4020, city: 'Jupille-sur-meuse' },
  { province: 'Luik', code: 4020, city: 'Liège' },
  { province: 'Luik', code: 4030, city: 'Grivegnee' },
  { province: 'Luik', code: 4031, city: 'Angleur' },
  { province: 'Luik', code: 4032, city: 'Chênee' },
  { province: 'Luik', code: 4040, city: 'Herstal' },
  { province: 'Luik', code: 4041, city: 'Milmort' },
  { province: 'Luik', code: 4041, city: 'Vottem' },
  { province: 'Luik', code: 4042, city: 'Liers' },
  { province: 'Luik', code: 4050, city: 'Chaudfontaine' },
  { province: 'Luik', code: 4051, city: 'Vaux-sous-chèvremont' },
  { province: 'Luik', code: 4052, city: 'Beaufays' },
  { province: 'Luik', code: 4053, city: 'Embourg' },
  { province: 'Luik', code: 4100, city: 'Seraing' },
  { province: 'Luik', code: 4100, city: 'Boncelles' },
  { province: 'Luik', code: 4101, city: 'Jemeppe-sur-meuse' },
  { province: 'Luik', code: 4102, city: 'Ougrée' },
  { province: 'Luik', code: 4120, city: 'Ehein' },
  { province: 'Luik', code: 4120, city: 'Rotheux-rimière' },
  { province: 'Luik', code: 4120, city: 'Neupré' },
  { province: 'Luik', code: 4121, city: 'Neuville-en-condroz' },
  { province: 'Luik', code: 4122, city: 'Plainevaux' },
  { province: 'Luik', code: 4130, city: 'Tilff' },
  { province: 'Luik', code: 4130, city: 'Esneux' },
  { province: 'Luik', code: 4140, city: 'Sprimont' },
  { province: 'Luik', code: 4140, city: 'Rouvreux' },
  { province: 'Luik', code: 4140, city: 'Dolembreux' },
  { province: 'Luik', code: 4140, city: 'Gomzé-andoumont' },
  { province: 'Luik', code: 4141, city: 'Louveigné' },
  { province: 'Luik', code: 4160, city: 'Anthisnes' },
  { province: 'Luik', code: 4161, city: 'Villers-aux-tours' },
  { province: 'Luik', code: 4162, city: 'Hody' },
  { province: 'Luik', code: 4163, city: 'Tavier' },
  { province: 'Luik', code: 4170, city: 'Comblain-au-pont' },
  { province: 'Luik', code: 4171, city: 'Poulseur' },
  { province: 'Luik', code: 4180, city: 'Comblain-fairon' },
  { province: 'Luik', code: 4180, city: 'Comblain-la-tour' },
  { province: 'Luik', code: 4180, city: 'Hamoir' },
  { province: 'Luik', code: 4181, city: 'Filot' },
  { province: 'Luik', code: 4190, city: 'Vieuxville' },
  { province: 'Luik', code: 4190, city: 'My' },
  { province: 'Luik', code: 4190, city: 'Xhoris' },
  { province: 'Luik', code: 4190, city: 'Ferrières' },
  { province: 'Luik', code: 4190, city: 'Werbomont' },
  { province: 'Luik', code: 4210, city: 'Marneffe' },
  { province: 'Luik', code: 4210, city: 'Oteppe' },
  { province: 'Luik', code: 4210, city: 'Lamontzée' },
  { province: 'Luik', code: 4210, city: 'Burdinne' },
  { province: 'Luik', code: 4210, city: 'Hannêche' },
  { province: 'Luik', code: 4217, city: 'Waret-l\'evêque' },
  { province: 'Luik', code: 4217, city: 'Lavoir' },
  { province: 'Luik', code: 4217, city: 'Héron' },
  { province: 'Luik', code: 4218, city: 'Couthuin' },
  { province: 'Luik', code: 4219, city: 'Wasseiges' },
  { province: 'Luik', code: 4219, city: 'Acosse' },
  { province: 'Luik', code: 4219, city: 'Meeffe' },
  { province: 'Luik', code: 4219, city: 'Ambresin' },
  { province: 'Luik', code: 4250, city: 'Geer' },
  { province: 'Luik', code: 4250, city: 'Lens-saint-servais' },
  { province: 'Luik', code: 4250, city: 'Hollogne-sur-geer' },
  { province: 'Luik', code: 4250, city: 'Boëlhe' },
  { province: 'Luik', code: 4252, city: 'Omal' },
  { province: 'Luik', code: 4253, city: 'Darion' },
  { province: 'Luik', code: 4254, city: 'Ligney' },
  { province: 'Luik', code: 4257, city: 'Rosoux-crenwick' },
  { province: 'Luik', code: 4257, city: 'Berloz' },
  { province: 'Luik', code: 4257, city: 'Corswarem' },
  { province: 'Luik', code: 4260, city: 'Avennes' },
  { province: 'Luik', code: 4260, city: 'Ciplet' },
  { province: 'Luik', code: 4260, city: 'Ville-en-hesbaye' },
  { province: 'Luik', code: 4260, city: 'Fumal' },
  { province: 'Luik', code: 4260, city: 'Fallais' },
  { province: 'Luik', code: 4260, city: 'Braives' },
  { province: 'Luik', code: 4261, city: 'Latinne' },
  { province: 'Luik', code: 4263, city: 'Tourinne' },
  { province: 'Luik', code: 4280, city: 'Hannut' },
  { province: 'Luik', code: 4280, city: 'Trognée' },
  { province: 'Luik', code: 4280, city: 'Avernas-le-bauduin' },
  { province: 'Luik', code: 4280, city: 'Cras-avernas' },
  { province: 'Luik', code: 4280, city: 'Thisnes' },
  { province: 'Luik', code: 4280, city: 'Moxhe' },
  { province: 'Luik', code: 4280, city: 'Avin' },
  { province: 'Luik', code: 4280, city: 'Crehen' },
  { province: 'Luik', code: 4280, city: 'Grand-hallet' },
  { province: 'Luik', code: 4280, city: 'Poucet' },
  { province: 'Luik', code: 4280, city: 'Lens-saint-remy' },
  { province: 'Luik', code: 4280, city: 'Abolens' },
  { province: 'Luik', code: 4280, city: 'Blehen' },
  { province: 'Luik', code: 4280, city: 'Villers-le-peuplier' },
  { province: 'Luik', code: 4280, city: 'Petit-hallet' },
  { province: 'Luik', code: 4280, city: 'Wansin' },
  { province: 'Luik', code: 4280, city: 'Bertrée' },
  { province: 'Luik', code: 4280, city: 'Merdorp' },
  { province: 'Luik', code: 4287, city: 'Lincent' },
  { province: 'Luik', code: 4287, city: 'Pellaines' },
  { province: 'Luik', code: 4287, city: 'Racour' },
  { province: 'Luik', code: 4300, city: 'Lantremange' },
  { province: 'Luik', code: 4300, city: 'Grand-axhe' },
  { province: 'Luik', code: 4300, city: 'Oleye' },
  { province: 'Luik', code: 4300, city: 'Bovenistier' },
  { province: 'Luik', code: 4300, city: 'Bleret' },
  { province: 'Luik', code: 4300, city: 'Bettincourt' },
  { province: 'Luik', code: 4300, city: 'Waremme' },
  { province: 'Luik', code: 4317, city: 'Faimes' },
  { province: 'Luik', code: 4317, city: 'Celles' },
  { province: 'Luik', code: 4317, city: 'Borlez' },
  { province: 'Luik', code: 4317, city: 'Aineffe' },
  { province: 'Luik', code: 4317, city: 'Les waleffes' },
  { province: 'Luik', code: 4317, city: 'Viemme' },
  { province: 'Luik', code: 4340, city: 'Awans' },
  { province: 'Luik', code: 4340, city: 'Villers-l\'evêque' },
  { province: 'Luik', code: 4340, city: 'Fooz' },
  { province: 'Luik', code: 4340, city: 'Othée' },
  { province: 'Luik', code: 4342, city: 'Hognoul' },
  { province: 'Luik', code: 4347, city: 'Freloux' },
  { province: 'Luik', code: 4347, city: 'Voroux-goreux' },
  { province: 'Luik', code: 4347, city: 'Fexhe-le-haut-clocher' },
  { province: 'Luik', code: 4347, city: 'Roloux' },
  { province: 'Luik', code: 4347, city: 'Noville' },
  { province: 'Luik', code: 4350, city: 'Remicourt' },
  { province: 'Luik', code: 4350, city: 'Pousset' },
  { province: 'Luik', code: 4350, city: 'Momalle' },
  { province: 'Luik', code: 4350, city: 'Lamine' },
  { province: 'Luik', code: 4351, city: 'Hodeige' },
  { province: 'Luik', code: 4357, city: 'Limont' },
  { province: 'Luik', code: 4357, city: 'Jeneffe' },
  { province: 'Luik', code: 4357, city: 'Haneffe' },
  { province: 'Luik', code: 4357, city: 'Donceel' },
  { province: 'Luik', code: 4360, city: 'Grandville' },
  { province: 'Luik', code: 4360, city: 'Lens-sur-geer' },
  { province: 'Luik', code: 4360, city: 'Oreye' },
  { province: 'Luik', code: 4360, city: 'Bergilers' },
  { province: 'Luik', code: 4360, city: 'Otrange' },
  { province: 'Luik', code: 4367, city: 'Fize-le-marsal' },
  { province: 'Luik', code: 4367, city: 'Odeur' },
  { province: 'Luik', code: 4367, city: 'Kemexhe' },
  { province: 'Luik', code: 4367, city: 'Crisnée' },
  { province: 'Luik', code: 4367, city: 'Thys' },
  { province: 'Luik', code: 4400, city: 'Chokier' },
  { province: 'Luik', code: 4400, city: 'Flémalle' },
  { province: 'Luik', code: 4400, city: 'Flémalle-grande' },
  { province: 'Luik', code: 4400, city: 'Flémalle-haute' },
  { province: 'Luik', code: 4400, city: 'Ivoz-ramet' },
  { province: 'Luik', code: 4400, city: 'Awirs' },
  { province: 'Luik', code: 4400, city: 'Gleixhe' },
  { province: 'Luik', code: 4400, city: 'Mons-lez-liège' },
  { province: 'Luik', code: 4420, city: 'Saint-nicolas' },
  { province: 'Luik', code: 4420, city: 'Montegnée' },
  { province: 'Luik', code: 4420, city: 'Tilleur' },
  { province: 'Luik', code: 4430, city: 'Ans' },
  { province: 'Luik', code: 4431, city: 'Loncin' },
  { province: 'Luik', code: 4432, city: 'Xhendremael' },
  { province: 'Luik', code: 4432, city: 'Alleur' },
  { province: 'Luik', code: 4450, city: 'Juprelle' },
  { province: 'Luik', code: 4450, city: 'Slins' },
  { province: 'Luik', code: 4450, city: 'Lantin' },
  { province: 'Luik', code: 4451, city: 'Voroux-lez-liers' },
  { province: 'Luik', code: 4452, city: 'Paifve' },
  { province: 'Luik', code: 4452, city: 'Wihogne' },
  { province: 'Luik', code: 4453, city: 'Villers-saint-siméon' },
  { province: 'Luik', code: 4458, city: 'Fexhe-slins' },
  { province: 'Luik', code: 4460, city: 'Grâce-hollogne' },
  { province: 'Luik', code: 4460, city: 'Bierset' },
  { province: 'Luik', code: 4460, city: 'Velroux' },
  { province: 'Luik', code: 4460, city: 'Hollogne-aux-pierres' },
  { province: 'Luik', code: 4460, city: 'Horion-hozémont' },
  { province: 'Luik', code: 4460, city: 'Grâce-berleur' },
  { province: 'Luik', code: 4470, city: 'Saint-georges-sur-meuse' },
  { province: 'Luik', code: 4480, city: 'Ehein' },
  { province: 'Luik', code: 4480, city: 'Clermont-sous-huy' },
  { province: 'Luik', code: 4480, city: 'Engis' },
  { province: 'Luik', code: 4480, city: 'Hermalle-sous-huy' },
  { province: 'Luik', code: 4500, city: 'Huy' },
  { province: 'Luik', code: 4500, city: 'Ben-ahin' },
  { province: 'Luik', code: 4500, city: 'Tihange' },
  { province: 'Luik', code: 4520, city: 'Huccorgne' },
  { province: 'Luik', code: 4520, city: 'Moha' },
  { province: 'Luik', code: 4520, city: 'Antheit' },
  { province: 'Luik', code: 4520, city: 'Bas-oha' },
  { province: 'Luik', code: 4520, city: 'Vinalmont' },
  { province: 'Luik', code: 4520, city: 'Wanze' },
  { province: 'Luik', code: 4530, city: 'Vieux-waleffe' },
  { province: 'Luik', code: 4530, city: 'Warnant-dreye' },
  { province: 'Luik', code: 4530, city: 'Vaux-et-borset' },
  { province: 'Luik', code: 4530, city: 'Villers-le-bouillet' },
  { province: 'Luik', code: 4530, city: 'Fize-fontaine' },
  { province: 'Luik', code: 4537, city: 'Seraing-le-château' },
  { province: 'Luik', code: 4537, city: 'Chapon-seraing' },
  { province: 'Luik', code: 4537, city: 'Verlaine' },
  { province: 'Luik', code: 4537, city: 'Bodegnée' },
  { province: 'Luik', code: 4540, city: 'Ampsin' },
  { province: 'Luik', code: 4540, city: 'Flône' },
  { province: 'Luik', code: 4540, city: 'Ombret' },
  { province: 'Luik', code: 4540, city: 'Jehay' },
  { province: 'Luik', code: 4540, city: 'Amay' },
  { province: 'Luik', code: 4550, city: 'Nandrin' },
  { province: 'Luik', code: 4550, city: 'Yernée-fraineux' },
  { province: 'Luik', code: 4550, city: 'Villers-le-temple' },
  { province: 'Luik', code: 4550, city: 'Saint-séverin' },
  { province: 'Luik', code: 4557, city: 'Seny' },
  { province: 'Luik', code: 4557, city: 'Abée' },
  { province: 'Luik', code: 4557, city: 'Soheit-tinlot' },
  { province: 'Luik', code: 4557, city: 'Tinlot' },
  { province: 'Luik', code: 4557, city: 'Ramelot' },
  { province: 'Luik', code: 4557, city: 'Fraiture' },
  { province: 'Luik', code: 4560, city: 'Bois-et-borsu' },
  { province: 'Luik', code: 4560, city: 'Ocquier' },
  { province: 'Luik', code: 4560, city: 'Clavier' },
  { province: 'Luik', code: 4560, city: 'Pailhe' },
  { province: 'Luik', code: 4560, city: 'Les avins' },
  { province: 'Luik', code: 4560, city: 'Terwagne' },
  { province: 'Luik', code: 4570, city: 'Vyle-et-tharoul' },
  { province: 'Luik', code: 4570, city: 'Marchin' },
  { province: 'Luik', code: 4577, city: 'Outrelouxhe' },
  { province: 'Luik', code: 4577, city: 'Modave' },
  { province: 'Luik', code: 4577, city: 'Strée-lez-huy' },
  { province: 'Luik', code: 4577, city: 'Vierset-barse' },
  { province: 'Luik', code: 4590, city: 'Ellemelle' },
  { province: 'Luik', code: 4590, city: 'Warzée' },
  { province: 'Luik', code: 4590, city: 'Ouffet' },
  { province: 'Luik', code: 4600, city: 'Richelle' },
  { province: 'Luik', code: 4600, city: 'Lixhe' },
  { province: 'Luik', code: 4600, city: 'Lanaye' },
  { province: 'Luik', code: 4600, city: 'Visé' },
  { province: 'Luik', code: 4601, city: 'Argenteau' },
  { province: 'Luik', code: 4602, city: 'Cheratte' },
  { province: 'Luik', code: 4606, city: 'Saint-andré' },
  { province: 'Luik', code: 4607, city: 'Mortroux' },
  { province: 'Luik', code: 4607, city: 'Bombaye' },
  { province: 'Luik', code: 4607, city: 'Feneur' },
  { province: 'Luik', code: 4607, city: 'Dalhem' },
  { province: 'Luik', code: 4607, city: 'Berneau' },
  { province: 'Luik', code: 4608, city: 'Warsage' },
  { province: 'Luik', code: 4608, city: 'Neufchâteau' },
  { province: 'Luik', code: 4610, city: 'Beyne-heusay' },
  { province: 'Luik', code: 4610, city: 'Bellaire' },
  { province: 'Luik', code: 4610, city: 'Queue-du-bois' },
  { province: 'Luik', code: 4620, city: 'Fléron' },
  { province: 'Luik', code: 4621, city: 'Retinne' },
  { province: 'Luik', code: 4623, city: 'Magnée' },
  { province: 'Luik', code: 4624, city: 'Romsée' },
  { province: 'Luik', code: 4630, city: 'Micheroux' },
  { province: 'Luik', code: 4630, city: 'Tignée' },
  { province: 'Luik', code: 4630, city: 'Ayeneux' },
  { province: 'Luik', code: 4630, city: 'Soumagne' },
  { province: 'Luik', code: 4631, city: 'Evegnée' },
  { province: 'Luik', code: 4632, city: 'Cérexhe-heuseux' },
  { province: 'Luik', code: 4633, city: 'Melen' },
  { province: 'Luik', code: 4650, city: 'Herve' },
  { province: 'Luik', code: 4650, city: 'Grand-rechain' },
  { province: 'Luik', code: 4650, city: 'Chaineux' },
  { province: 'Luik', code: 4650, city: 'Julémont' },
  { province: 'Luik', code: 4651, city: 'Battice' },
  { province: 'Luik', code: 4652, city: 'Xhendelesse' },
  { province: 'Luik', code: 4653, city: 'Bolland' },
  { province: 'Luik', code: 4654, city: 'Charneux' },
  { province: 'Luik', code: 4670, city: 'Blégny' },
  { province: 'Luik', code: 4670, city: 'Mortier' },
  { province: 'Luik', code: 4670, city: 'Trembleur' },
  { province: 'Luik', code: 4671, city: 'Barchon' },
  { province: 'Luik', code: 4671, city: 'Saive' },
  { province: 'Luik', code: 4671, city: 'Housse' },
  { province: 'Luik', code: 4672, city: 'Saint-remy' },
  { province: 'Luik', code: 4680, city: 'Oupeye' },
  { province: 'Luik', code: 4680, city: 'Hermée' },
  { province: 'Luik', code: 4681, city: 'Hermalle-sous-argenteau' },
  { province: 'Luik', code: 4682, city: 'Houtain-saint-siméon' },
  { province: 'Luik', code: 4682, city: 'Heure-le-romain' },
  { province: 'Luik', code: 4683, city: 'Vivegnis' },
  { province: 'Luik', code: 4684, city: 'Haccourt' },
  { province: 'Luik', code: 4690, city: 'Wonck' },
  { province: 'Luik', code: 4690, city: 'Bassenge' },
  { province: 'Luik', code: 4690, city: 'Roclenge-sur-geer' },
  { province: 'Luik', code: 4690, city: 'Glons' },
  { province: 'Luik', code: 4690, city: 'Boirs' },
  { province: 'Luik', code: 4690, city: 'Eben-emael' },
  { province: 'Luik', code: 4700, city: 'Eupen' },
  { province: 'Luik', code: 4701, city: 'Kettenis' },
  { province: 'Luik', code: 4710, city: 'Lontzen' },
  { province: 'Luik', code: 4711, city: 'Walhorn' },
  { province: 'Luik', code: 4720, city: 'Kelmis' },
  { province: 'Luik', code: 4721, city: 'Neu-moresnet' },
  { province: 'Luik', code: 4728, city: 'Hergenrath' },
  { province: 'Luik', code: 4730, city: 'Hauset' },
  { province: 'Luik', code: 4730, city: 'Raeren' },
  { province: 'Luik', code: 4731, city: 'Eynatten' },
  { province: 'Luik', code: 4750, city: 'Butgenbach' },
  { province: 'Luik', code: 4750, city: 'Elsenborn' },
  { province: 'Luik', code: 4760, city: 'Büllingen' },
  { province: 'Luik', code: 4760, city: 'Manderfeld' },
  { province: 'Luik', code: 4761, city: 'Rocherath' },
  { province: 'Luik', code: 4770, city: 'Meyerode' },
  { province: 'Luik', code: 4770, city: 'Amel' },
  { province: 'Luik', code: 4771, city: 'Heppenbach' },
  { province: 'Luik', code: 4780, city: 'Recht' },
  { province: 'Luik', code: 4780, city: 'Sankt-vith' },
  { province: 'Luik', code: 4782, city: 'Schönberg' },
  { province: 'Luik', code: 4783, city: 'Lommersweiler' },
  { province: 'Luik', code: 4784, city: 'Crombach' },
  { province: 'Luik', code: 4790, city: 'Reuland' },
  { province: 'Luik', code: 4790, city: 'Burg-reuland' },
  { province: 'Luik', code: 4791, city: 'Thommen' },
  { province: 'Luik', code: 4800, city: 'Polleur' },
  { province: 'Luik', code: 4800, city: 'Lambermont' },
  { province: 'Luik', code: 4800, city: 'Ensival' },
  { province: 'Luik', code: 4800, city: 'Verviers' },
  { province: 'Luik', code: 4800, city: 'Petit-rechain' },
  { province: 'Luik', code: 4801, city: 'Stembert' },
  { province: 'Luik', code: 4802, city: 'Heusy' },
  { province: 'Luik', code: 4820, city: 'Dison' },
  { province: 'Luik', code: 4821, city: 'Andrimont' },
  { province: 'Luik', code: 4830, city: 'Limbourg' },
  { province: 'Luik', code: 4831, city: 'Bilstain' },
  { province: 'Luik', code: 4834, city: 'Goé' },
  { province: 'Luik', code: 4837, city: 'Baelen' },
  { province: 'Luik', code: 4837, city: 'Membach' },
  { province: 'Luik', code: 4840, city: 'Welkenraedt' },
  { province: 'Luik', code: 4841, city: 'Henri-chapelle' },
  { province: 'Luik', code: 4845, city: 'Jalhay' },
  { province: 'Luik', code: 4845, city: 'Sart-lez-spa' },
  { province: 'Luik', code: 4850, city: 'Montzen' },
  { province: 'Luik', code: 4850, city: 'Moresnet' },
  { province: 'Luik', code: 4850, city: 'Plombières' },
  { province: 'Luik', code: 4851, city: 'Gemmenich' },
  { province: 'Luik', code: 4851, city: 'Sippenaeken' },
  { province: 'Luik', code: 4852, city: 'Hombourg' },
  { province: 'Luik', code: 4860, city: 'Wegnez' },
  { province: 'Luik', code: 4860, city: 'Pepinster' },
  { province: 'Luik', code: 4860, city: 'Cornesse' },
  { province: 'Luik', code: 4861, city: 'Soiron' },
  { province: 'Luik', code: 4870, city: 'Trooz' },
  { province: 'Luik', code: 4870, city: 'Nessonvaux' },
  { province: 'Luik', code: 4870, city: 'Forêt' },
  { province: 'Luik', code: 4870, city: 'Fraipont' },
  { province: 'Luik', code: 4877, city: 'Olne' },
  { province: 'Luik', code: 4880, city: 'Aubel' },
  { province: 'Luik', code: 4890, city: 'Thimister-clermont' },
  { province: 'Luik', code: 4890, city: 'Clermont' },
  { province: 'Luik', code: 4890, city: 'Thimister' },
  { province: 'Luik', code: 4900, city: 'Spa' },
  { province: 'Luik', code: 4910, city: 'Polleur' },
  { province: 'Luik', code: 4910, city: 'La reid' },
  { province: 'Luik', code: 4910, city: 'Theux' },
  { province: 'Luik', code: 4920, city: 'Harzé' },
  { province: 'Luik', code: 4920, city: 'Louveigné' },
  { province: 'Luik', code: 4920, city: 'Aywaille' },
  { province: 'Luik', code: 4920, city: 'Ernonheid' },
  { province: 'Luik', code: 4920, city: 'Sougné-remouchamps' },
  { province: 'Luik', code: 4950, city: 'Sourbrodt' },
  { province: 'Luik', code: 4950, city: 'Faymonville' },
  { province: 'Luik', code: 4950, city: 'Robertville' },
  { province: 'Luik', code: 4950, city: 'Weismes' },
  { province: 'Luik', code: 4960, city: 'Bevercé' },
  { province: 'Luik', code: 4960, city: 'Malmedy' },
  { province: 'Luik', code: 4960, city: 'Bellevaux-ligneuville' },
  { province: 'Luik', code: 4970, city: 'Francorchamps' },
  { province: 'Luik', code: 4970, city: 'Stavelot' },
  { province: 'Luik', code: 4980, city: 'Trois-ponts' },
  { province: 'Luik', code: 4980, city: 'Wanne' },
  { province: 'Luik', code: 4980, city: 'Fosse' },
  { province: 'Luik', code: 4983, city: 'Basse-bodeux' },
  { province: 'Luik', code: 4987, city: 'La gleize' },
  { province: 'Luik', code: 4987, city: 'Stoumont' },
  { province: 'Luik', code: 4987, city: 'Rahier' },
  { province: 'Luik', code: 4987, city: 'Lorcé' },
  { province: 'Luik', code: 4987, city: 'Chevron' },
  { province: 'Luik', code: 4990, city: 'Lierneux' },
  { province: 'Luik', code: 4990, city: 'Bra' },
  { province: 'Luik', code: 4990, city: 'Arbrefontaine' },







  // Namen'
  { province: 'Namen', code: 5000, city: 'Beez' },
  { province: 'Namen', code: 5000, city: 'Namur' },
  { province: 'Namen', code: 5001, city: 'Belgrade' },
  { province: 'Namen', code: 5002, city: 'Saint-servais' },
  { province: 'Namen', code: 5003, city: 'Saint-marc' },
  { province: 'Namen', code: 5004, city: 'Bouge' },
  { province: 'Namen', code: 5020, city: 'Suarlée' },
  { province: 'Namen', code: 5020, city: 'Malonne' },
  { province: 'Namen', code: 5020, city: 'Flawinne' },
  { province: 'Namen', code: 5020, city: 'Daussoulx' },
  { province: 'Namen', code: 5020, city: 'Champion' },
  { province: 'Namen', code: 5020, city: 'Vedrin' },
  { province: 'Namen', code: 5020, city: 'Temploux' },
  { province: 'Namen', code: 5021, city: 'Boninne' },
  { province: 'Namen', code: 5022, city: 'Cognelée' },
  { province: 'Namen', code: 5024, city: 'Gelbressée' },
  { province: 'Namen', code: 5024, city: 'Marche-les-dames' },
  { province: 'Namen', code: 5030, city: 'Gembloux' },
  { province: 'Namen', code: 5030, city: 'Grand-manil' },
  { province: 'Namen', code: 5030, city: 'Beuzet' },
  { province: 'Namen', code: 5030, city: 'Lonzée' },
  { province: 'Namen', code: 5030, city: 'Ernage' },
  { province: 'Namen', code: 5030, city: 'Sauvenière' },
  { province: 'Namen', code: 5031, city: 'Grand-leez' },
  { province: 'Namen', code: 5032, city: 'Bothey' },
  { province: 'Namen', code: 5032, city: 'Isnes' },
  { province: 'Namen', code: 5032, city: 'Mazy' },
  { province: 'Namen', code: 5032, city: 'Corroy-le-château' },
  { province: 'Namen', code: 5032, city: 'Bossière' },
  { province: 'Namen', code: 5060, city: 'Arsimont' },
  { province: 'Namen', code: 5060, city: 'Moignelée' },
  { province: 'Namen', code: 5060, city: 'Sambreville' },
  { province: 'Namen', code: 5060, city: 'Auvelais' },
  { province: 'Namen', code: 5060, city: 'Falisolle' },
  { province: 'Namen', code: 5060, city: 'Tamines' },
  { province: 'Namen', code: 5060, city: 'Velaine-sur-sambre' },
  { province: 'Namen', code: 5060, city: 'Keumiée' },
  { province: 'Namen', code: 5070, city: 'Fosses-la-ville' },
  { province: 'Namen', code: 5070, city: 'Aisemont' },
  { province: 'Namen', code: 5070, city: 'Le roux' },
  { province: 'Namen', code: 5070, city: 'Sart-eustache' },
  { province: 'Namen', code: 5070, city: 'Sart-saint-laurent' },
  { province: 'Namen', code: 5070, city: 'Vitrival' },
  { province: 'Namen', code: 5080, city: 'La bruyère' },
  { province: 'Namen', code: 5080, city: 'Emines' },
  { province: 'Namen', code: 5080, city: 'Villers-lez-heest' },
  { province: 'Namen', code: 5080, city: 'Rhisnes' },
  { province: 'Namen', code: 5080, city: 'Warisoulx' },
  { province: 'Namen', code: 5081, city: 'Bovesse' },
  { province: 'Namen', code: 5081, city: 'Saint-denis-bovesse' },
  { province: 'Namen', code: 5081, city: 'Meux' },
  { province: 'Namen', code: 5100, city: 'Jambes' },
  { province: 'Namen', code: 5100, city: 'Naninne' },
  { province: 'Namen', code: 5100, city: 'Wépion' },
  { province: 'Namen', code: 5100, city: 'Wierde' },
  { province: 'Namen', code: 5100, city: 'Dave' },
  { province: 'Namen', code: 5101, city: 'Erpent' },
  { province: 'Namen', code: 5101, city: 'Lives-sur-meuse' },
  { province: 'Namen', code: 5101, city: 'Loyers' },
  { province: 'Namen', code: 5140, city: 'Boignée' },
  { province: 'Namen', code: 5140, city: 'Tongrinne' },
  { province: 'Namen', code: 5140, city: 'Ligny' },
  { province: 'Namen', code: 5140, city: 'Sombreffe' },
  { province: 'Namen', code: 5150, city: 'Floreffe' },
  { province: 'Namen', code: 5150, city: 'Franière' },
  { province: 'Namen', code: 5150, city: 'Floriffoux' },
  { province: 'Namen', code: 5150, city: 'Soye' },
  { province: 'Namen', code: 5170, city: 'Lesve' },
  { province: 'Namen', code: 5170, city: 'Profondeville' },
  { province: 'Namen', code: 5170, city: 'Bois-de-villers' },
  { province: 'Namen', code: 5170, city: 'Rivière' },
  { province: 'Namen', code: 5170, city: 'Lustin' },
  { province: 'Namen', code: 5170, city: 'Arbre' },
  { province: 'Namen', code: 5190, city: 'Saint-martin' },
  { province: 'Namen', code: 5190, city: 'Spy' },
  { province: 'Namen', code: 5190, city: 'Mornimont' },
  { province: 'Namen', code: 5190, city: 'Ham-sur-sambre' },
  { province: 'Namen', code: 5190, city: 'Jemeppe-sur-sambre' },
  { province: 'Namen', code: 5190, city: 'Balâtre' },
  { province: 'Namen', code: 5190, city: 'Moustier-sur-sambre' },
  { province: 'Namen', code: 5190, city: 'Onoz' },
  { province: 'Namen', code: 5300, city: 'Sclayn' },
  { province: 'Namen', code: 5300, city: 'Vezin' },
  { province: 'Namen', code: 5300, city: 'Maizeret' },
  { province: 'Namen', code: 5300, city: 'Namêche' },
  { province: 'Namen', code: 5300, city: 'Landenne' },
  { province: 'Namen', code: 5300, city: 'Seilles' },
  { province: 'Namen', code: 5300, city: 'Andenne' },
  { province: 'Namen', code: 5300, city: 'Bonneville' },
  { province: 'Namen', code: 5300, city: 'Coutisse' },
  { province: 'Namen', code: 5300, city: 'Thon' },
  { province: 'Namen', code: 5310, city: 'Hanret' },
  { province: 'Namen', code: 5310, city: 'Leuze' },
  { province: 'Namen', code: 5310, city: 'Liernu' },
  { province: 'Namen', code: 5310, city: 'Aische-en-refail' },
  { province: 'Namen', code: 5310, city: 'Noville-sur-méhaigne' },
  { province: 'Namen', code: 5310, city: 'Mehaigne' },
  { province: 'Namen', code: 5310, city: 'Eghezée' },
  { province: 'Namen', code: 5310, city: 'Longchamps' },
  { province: 'Namen', code: 5310, city: 'Bolinne' },
  { province: 'Namen', code: 5310, city: 'Boneffe' },
  { province: 'Namen', code: 5310, city: 'Upigny' },
  { province: 'Namen', code: 5310, city: 'Dhuy' },
  { province: 'Namen', code: 5310, city: 'Taviers' },
  { province: 'Namen', code: 5310, city: 'Branchon' },
  { province: 'Namen', code: 5310, city: 'Saint-germain' },
  { province: 'Namen', code: 5310, city: 'Waret-la-chaussée' },
  { province: 'Namen', code: 5330, city: 'Maillen' },
  { province: 'Namen', code: 5330, city: 'Assesse' },
  { province: 'Namen', code: 5330, city: 'Sart-bernard' },
  { province: 'Namen', code: 5332, city: 'Crupet' },
  { province: 'Namen', code: 5333, city: 'Sorinne-la-longue' },
  { province: 'Namen', code: 5334, city: 'Florée' },
  { province: 'Namen', code: 5336, city: 'Courrière' },
  { province: 'Namen', code: 5340, city: 'Mozet' },
  { province: 'Namen', code: 5340, city: 'Sorée' },
  { province: 'Namen', code: 5340, city: 'Gesves' },
  { province: 'Namen', code: 5340, city: 'Haltinne' },
  { province: 'Namen', code: 5340, city: 'Faulx-les-tombes' },
  { province: 'Namen', code: 5350, city: 'Evelette' },
  { province: 'Namen', code: 5350, city: 'Ohey' },
  { province: 'Namen', code: 5351, city: 'Haillot' },
  { province: 'Namen', code: 5352, city: 'Perwez-haillot' },
  { province: 'Namen', code: 5353, city: 'Goesnes' },
  { province: 'Namen', code: 5354, city: 'Jallet' },
  { province: 'Namen', code: 5360, city: 'Natoye' },
  { province: 'Namen', code: 5360, city: 'Hamois' },
  { province: 'Namen', code: 5361, city: 'Mohiville' },
  { province: 'Namen', code: 5361, city: 'Scy' },
  { province: 'Namen', code: 5362, city: 'Achet' },
  { province: 'Namen', code: 5363, city: 'Emptinne' },
  { province: 'Namen', code: 5364, city: 'Schaltin' },
  { province: 'Namen', code: 5370, city: 'Verlée' },
  { province: 'Namen', code: 5370, city: 'Porcheresse' },
  { province: 'Namen', code: 5370, city: 'Havelange' },
  { province: 'Namen', code: 5370, city: 'Flostoy' },
  { province: 'Namen', code: 5370, city: 'Jeneffe' },
  { province: 'Namen', code: 5370, city: 'Barvaux-condroz' },
  { province: 'Namen', code: 5372, city: 'Méan' },
  { province: 'Namen', code: 5374, city: 'Maffe' },
  { province: 'Namen', code: 5376, city: 'Miécret' },
  { province: 'Namen', code: 5377, city: 'Noiseux' },
  { province: 'Namen', code: 5377, city: 'Heure' },
  { province: 'Namen', code: 5377, city: 'Hogne' },
  { province: 'Namen', code: 5377, city: 'Waillet' },
  { province: 'Namen', code: 5377, city: 'Nettinne' },
  { province: 'Namen', code: 5377, city: 'Baillonville' },
  { province: 'Namen', code: 5377, city: 'Sinsin' },
  { province: 'Namen', code: 5377, city: 'Bonsin' },
  { province: 'Namen', code: 5377, city: 'Somme-leuze' },
  { province: 'Namen', code: 5380, city: 'Hemptinne' },
  { province: 'Namen', code: 5380, city: 'Pontillas' },
  { province: 'Namen', code: 5380, city: 'Forville' },
  { province: 'Namen', code: 5380, city: 'Noville-les-bois' },
  { province: 'Namen', code: 5380, city: 'Tillier' },
  { province: 'Namen', code: 5380, city: 'Hingeon' },
  { province: 'Namen', code: 5380, city: 'Franc-waret' },
  { province: 'Namen', code: 5380, city: 'Cortil-wodon' },
  { province: 'Namen', code: 5380, city: 'Marchovelette' },
  { province: 'Namen', code: 5380, city: 'Bierwart' },
  { province: 'Namen', code: 5380, city: 'Fernelmont' },
  { province: 'Namen', code: 5500, city: 'Dréhance' },
  { province: 'Namen', code: 5500, city: 'Bouvignes-sur-meuse' },
  { province: 'Namen', code: 5500, city: 'Falmagne' },
  { province: 'Namen', code: 5500, city: 'Falmignoul' },
  { province: 'Namen', code: 5500, city: 'Anseremme' },
  { province: 'Namen', code: 5500, city: 'Dinant' },
  { province: 'Namen', code: 5500, city: 'Furfooz' },
  { province: 'Namen', code: 5501, city: 'Lisogne' },
  { province: 'Namen', code: 5502, city: 'Thynes' },
  { province: 'Namen', code: 5503, city: 'Sorinnes' },
  { province: 'Namen', code: 5504, city: 'Foy-notre-dame' },
  { province: 'Namen', code: 5520, city: 'Onhaye' },
  { province: 'Namen', code: 5520, city: 'Anthée' },
  { province: 'Namen', code: 5521, city: 'Serville' },
  { province: 'Namen', code: 5522, city: 'Falaen' },
  { province: 'Namen', code: 5523, city: 'Sommière' },
  { province: 'Namen', code: 5523, city: 'Weillen' },
  { province: 'Namen', code: 5524, city: 'Gerin' },
  { province: 'Namen', code: 5530, city: 'Spontin' },
  { province: 'Namen', code: 5530, city: 'Dorinne' },
  { province: 'Namen', code: 5530, city: 'Evrehailles' },
  { province: 'Namen', code: 5530, city: 'Durnal' },
  { province: 'Namen', code: 5530, city: 'Mont' },
  { province: 'Namen', code: 5530, city: 'Purnode' },
  { province: 'Namen', code: 5530, city: 'Godinne' },
  { province: 'Namen', code: 5530, city: 'Houx' },
  { province: 'Namen', code: 5530, city: 'Yvoir' },
  { province: 'Namen', code: 5537, city: 'Bioul' },
  { province: 'Namen', code: 5537, city: 'Warnant' },
  { province: 'Namen', code: 5537, city: 'Sosoye' },
  { province: 'Namen', code: 5537, city: 'Anhée' },
  { province: 'Namen', code: 5537, city: 'Denée' },
  { province: 'Namen', code: 5537, city: 'Haut-le-wastia' },
  { province: 'Namen', code: 5537, city: 'Annevoie-rouillon' },
  { province: 'Namen', code: 5540, city: 'Waulsort' },
  { province: 'Namen', code: 5540, city: 'Hermeton-sur-meuse' },
  { province: 'Namen', code: 5540, city: 'Hastière' },
  { province: 'Namen', code: 5540, city: 'Hastière-lavaux' },
  { province: 'Namen', code: 5541, city: 'Hastière-par-delà' },
  { province: 'Namen', code: 5542, city: 'Blaimont' },
  { province: 'Namen', code: 5543, city: 'Heer' },
  { province: 'Namen', code: 5544, city: 'Agimont' },
  { province: 'Namen', code: 5550, city: 'Orchimont' },
  { province: 'Namen', code: 5550, city: 'Nafraiture' },
  { province: 'Namen', code: 5550, city: 'Laforet' },
  { province: 'Namen', code: 5550, city: 'Bohan' },
  { province: 'Namen', code: 5550, city: 'Sugny' },
  { province: 'Namen', code: 5550, city: 'Vresse-sur-semois' },
  { province: 'Namen', code: 5550, city: 'Alle' },
  { province: 'Namen', code: 5550, city: 'Membre' },
  { province: 'Namen', code: 5550, city: 'Chairière' },
  { province: 'Namen', code: 5550, city: 'Bagimont' },
  { province: 'Namen', code: 5550, city: 'Pussemange' },
  { province: 'Namen', code: 5550, city: 'Mouzaive' },
  { province: 'Namen', code: 5555, city: 'Graide' },
  { province: 'Namen', code: 5555, city: 'Monceau-en-ardenne' },
  { province: 'Namen', code: 5555, city: 'Bellefontaine' },
  { province: 'Namen', code: 5555, city: 'Cornimont' },
  { province: 'Namen', code: 5555, city: 'Gros-fays' },
  { province: 'Namen', code: 5555, city: 'Naomé' },
  { province: 'Namen', code: 5555, city: 'Oizy' },
  { province: 'Namen', code: 5555, city: 'Baillamont' },
  { province: 'Namen', code: 5555, city: 'Petit-fays' },
  { province: 'Namen', code: 5555, city: 'Bièvre' },
  { province: 'Namen', code: 5560, city: 'Hulsonniaux' },
  { province: 'Namen', code: 5560, city: 'Finnevaux' },
  { province: 'Namen', code: 5560, city: 'Mesnil-eglise' },
  { province: 'Namen', code: 5560, city: 'Mesnil-saint-blaise' },
  { province: 'Namen', code: 5560, city: 'Ciergnon' },
  { province: 'Namen', code: 5560, city: 'Houyet' },
  { province: 'Namen', code: 5561, city: 'Celles' },
  { province: 'Namen', code: 5562, city: 'Custinne' },
  { province: 'Namen', code: 5563, city: 'Hour' },
  { province: 'Namen', code: 5564, city: 'Wanlin' },
  { province: 'Namen', code: 5570, city: 'Feschaux' },
  { province: 'Namen', code: 5570, city: 'Beauraing' },
  { province: 'Namen', code: 5570, city: 'Vonêche' },
  { province: 'Namen', code: 5570, city: 'Honnay' },
  { province: 'Namen', code: 5570, city: 'Javingue' },
  { province: 'Namen', code: 5570, city: 'Wancennes' },
  { province: 'Namen', code: 5570, city: 'Winenne' },
  { province: 'Namen', code: 5570, city: 'Felenne' },
  { province: 'Namen', code: 5570, city: 'Baronville' },
  { province: 'Namen', code: 5570, city: 'Dion' },
  { province: 'Namen', code: 5571, city: 'Wiesme' },
  { province: 'Namen', code: 5572, city: 'Focant' },
  { province: 'Namen', code: 5573, city: 'Martouzin-neuville' },
  { province: 'Namen', code: 5574, city: 'Pondrôme' },
  { province: 'Namen', code: 5575, city: 'Gedinne' },
  { province: 'Namen', code: 5575, city: 'Patignies' },
  { province: 'Namen', code: 5575, city: 'Bourseigne-neuve' },
  { province: 'Namen', code: 5575, city: 'Bourseigne-vieille' },
  { province: 'Namen', code: 5575, city: 'Malvoisin' },
  { province: 'Namen', code: 5575, city: 'Sart-custinne' },
  { province: 'Namen', code: 5575, city: 'Houdremont' },
  { province: 'Namen', code: 5575, city: 'Louette-saint-denis' },
  { province: 'Namen', code: 5575, city: 'Willerzie' },
  { province: 'Namen', code: 5575, city: 'Louette-saint-pierre' },
  { province: 'Namen', code: 5575, city: 'Rienne' },
  { province: 'Namen', code: 5575, city: 'Vencimont' },
  { province: 'Namen', code: 5576, city: 'Froidfontaine' },
  { province: 'Namen', code: 5580, city: 'Buissonville' },
  { province: 'Namen', code: 5580, city: 'Wavreille' },
  { province: 'Namen', code: 5580, city: 'Ave-et-auffe' },
  { province: 'Namen', code: 5580, city: 'Lavaux-saint-anne' },
  { province: 'Namen', code: 5580, city: 'Mont-gauthier' },
  { province: 'Namen', code: 5580, city: 'Jemelle' },
  { province: 'Namen', code: 5580, city: 'Villers-sur-lesse' },
  { province: 'Namen', code: 5580, city: 'Eprave' },
  { province: 'Namen', code: 5580, city: 'Lessive' },
  { province: 'Namen', code: 5580, city: 'Han-sur-lesse' },
  { province: 'Namen', code: 5580, city: 'Rochefort' },
  { province: 'Namen', code: 5590, city: 'Conneux' },
  { province: 'Namen', code: 5590, city: 'Pessoux' },
  { province: 'Namen', code: 5590, city: 'Serinchamps' },
  { province: 'Namen', code: 5590, city: 'Braibant' },
  { province: 'Namen', code: 5590, city: 'Leignon' },
  { province: 'Namen', code: 5590, city: 'Chevetogne' },
  { province: 'Namen', code: 5590, city: 'Haversin' },
  { province: 'Namen', code: 5590, city: 'Sovet' },
  { province: 'Namen', code: 5590, city: 'Achêne' },
  { province: 'Namen', code: 5590, city: 'Ciney' },
  { province: 'Namen', code: 5600, city: 'Vodecée' },
  { province: 'Namen', code: 5600, city: 'Roly' },
  { province: 'Namen', code: 5600, city: 'Samart' },
  { province: 'Namen', code: 5600, city: 'Romedenne' },
  { province: 'Namen', code: 5600, city: 'Fagnolle' },
  { province: 'Namen', code: 5600, city: 'Jamagne' },
  { province: 'Namen', code: 5600, city: 'Villers-en-fagne' },
  { province: 'Namen', code: 5600, city: 'Surice' },
  { province: 'Namen', code: 5600, city: 'Jamiolle' },
  { province: 'Namen', code: 5600, city: 'Merlemont' },
  { province: 'Namen', code: 5600, city: 'Villers-le-gambon' },
  { province: 'Namen', code: 5600, city: 'Franchimont' },
  { province: 'Namen', code: 5600, city: 'Sart-en-fagne' },
  { province: 'Namen', code: 5600, city: 'Omezée' },
  { province: 'Namen', code: 5600, city: 'Neuville' },
  { province: 'Namen', code: 5600, city: 'Philippeville' },
  { province: 'Namen', code: 5600, city: 'Sautour' },
  { province: 'Namen', code: 5620, city: 'Hemptinne-lez-florennes' },
  { province: 'Namen', code: 5620, city: 'Flavion' },
  { province: 'Namen', code: 5620, city: 'Corenne' },
  { province: 'Namen', code: 5620, city: 'Rosée' },
  { province: 'Namen', code: 5620, city: 'Saint-aubin' },
  { province: 'Namen', code: 5620, city: 'Florennes' },
  { province: 'Namen', code: 5620, city: 'Morville' },
  { province: 'Namen', code: 5621, city: 'Thy-le-baudouin' },
  { province: 'Namen', code: 5621, city: 'Hanzinelle' },
  { province: 'Namen', code: 5621, city: 'Hanzinne' },
  { province: 'Namen', code: 5621, city: 'Morialmé' },
  { province: 'Namen', code: 5630, city: 'Daussois' },
  { province: 'Namen', code: 5630, city: 'Villers-deux-eglises' },
  { province: 'Namen', code: 5630, city: 'Cerfontaine' },
  { province: 'Namen', code: 5630, city: 'Senzeille' },
  { province: 'Namen', code: 5630, city: 'Silenrieux' },
  { province: 'Namen', code: 5630, city: 'Soumoy' },
  { province: 'Namen', code: 5640, city: 'Oret' },
  { province: 'Namen', code: 5640, city: 'Graux' },
  { province: 'Namen', code: 5640, city: 'Saint-gérard' },
  { province: 'Namen', code: 5640, city: 'Biesme' },
  { province: 'Namen', code: 5640, city: 'Mettet' },
  { province: 'Namen', code: 5640, city: 'Biesmerée' },
  { province: 'Namen', code: 5641, city: 'Furnaux' },
  { province: 'Namen', code: 5644, city: 'Ermeton-sur-biert' },
  { province: 'Namen', code: 5646, city: 'Stave' },
  { province: 'Namen', code: 5650, city: 'Clermont' },
  { province: 'Namen', code: 5650, city: 'Castillon' },
  { province: 'Namen', code: 5650, city: 'Vogenée' },
  { province: 'Namen', code: 5650, city: 'Fraire' },
  { province: 'Namen', code: 5650, city: 'Pry' },
  { province: 'Namen', code: 5650, city: 'Walcourt' },
  { province: 'Namen', code: 5650, city: 'Chastrès' },
  { province: 'Namen', code: 5650, city: 'Yves-gomezée' },
  { province: 'Namen', code: 5650, city: 'Fontenelle' },
  { province: 'Namen', code: 5651, city: 'Gourdinne' },
  { province: 'Namen', code: 5651, city: 'Rognée' },
  { province: 'Namen', code: 5651, city: 'Thy-le-château' },
  { province: 'Namen', code: 5651, city: 'Somzée' },
  { province: 'Namen', code: 5651, city: 'Laneffe' },
  { province: 'Namen', code: 5651, city: 'Berzée' },
  { province: 'Namen', code: 5651, city: 'Tarcienne' },
  { province: 'Namen', code: 5660, city: 'Cul-des-sarts' },
  { province: 'Namen', code: 5660, city: 'Presgaux' },
  { province: 'Namen', code: 5660, city: 'Aublain' },
  { province: 'Namen', code: 5660, city: 'Dailly' },
  { province: 'Namen', code: 5660, city: 'Pesche' },
  { province: 'Namen', code: 5660, city: 'Boussu-en-fagne' },
  { province: 'Namen', code: 5660, city: 'Petigny' },
  { province: 'Namen', code: 5660, city: 'Mariembourg' },
  { province: 'Namen', code: 5660, city: 'Frasnes' },
  { province: 'Namen', code: 5660, city: 'Petite-chapelle' },
  { province: 'Namen', code: 5660, city: 'Couvin' },
  { province: 'Namen', code: 5660, city: 'Brûly' },
  { province: 'Namen', code: 5660, city: 'Gonrieux' },
  { province: 'Namen', code: 5660, city: 'Brûly-de-pesche' },
  { province: 'Namen', code: 5670, city: 'Mazée' },
  { province: 'Namen', code: 5670, city: 'Dourbes' },
  { province: 'Namen', code: 5670, city: 'Vierves-sur-viroin' },
  { province: 'Namen', code: 5670, city: 'Oignies-en-thiérache' },
  { province: 'Namen', code: 5670, city: 'Treignes' },
  { province: 'Namen', code: 5670, city: 'Le mesnil' },
  { province: 'Namen', code: 5670, city: 'Olloy-sur-viroin' },
  { province: 'Namen', code: 5670, city: 'Viroinval' },
  { province: 'Namen', code: 5670, city: 'Nismes' },
  { province: 'Namen', code: 5680, city: 'Vodelée' },
  { province: 'Namen', code: 5680, city: 'Romerée' },
  { province: 'Namen', code: 5680, city: 'Gimnée' },
  { province: 'Namen', code: 5680, city: 'Soulme' },
  { province: 'Namen', code: 5680, city: 'Gochenée' },
  { province: 'Namen', code: 5680, city: 'Vaucelles' },
  { province: 'Namen', code: 5680, city: 'Matagne-la-grande' },
  { province: 'Namen', code: 5680, city: 'Matagne-la-petite' },
  { province: 'Namen', code: 5680, city: 'Niverlée' },
  { province: 'Namen', code: 5680, city: 'Doische' },




  // Lux'

  { province: 'Luxemburg', code: 6600, city: 'Villers-la-bonne-eau' },
  { province: 'Luxemburg', code: 6600, city: 'Noville' },
  { province: 'Luxemburg', code: 6600, city: 'Bastogne' },
  { province: 'Luxemburg', code: 6600, city: 'Longvilly' },
  { province: 'Luxemburg', code: 6600, city: 'Wardin' },
  { province: 'Luxemburg', code: 6630, city: 'Martelange' },
  { province: 'Luxemburg', code: 6637, city: 'Hollange' },
  { province: 'Luxemburg', code: 6637, city: 'Fauvillers' },
  { province: 'Luxemburg', code: 6637, city: 'Tintange' },
  { province: 'Luxemburg', code: 6640, city: 'Nives' },
  { province: 'Luxemburg', code: 6640, city: 'Hompré' },
  { province: 'Luxemburg', code: 6640, city: 'Sibret' },
  { province: 'Luxemburg', code: 6640, city: 'Morhet' },
  { province: 'Luxemburg', code: 6640, city: 'Vaux-lez-rosières' },
  { province: 'Luxemburg', code: 6640, city: 'Vaux-sur-sûre' },
  { province: 'Luxemburg', code: 6642, city: 'Juseret' },
  { province: 'Luxemburg', code: 6660, city: 'Houffalize' },
  { province: 'Luxemburg', code: 6660, city: 'Nadrin' },
  { province: 'Luxemburg', code: 6661, city: 'Tailles' },
  { province: 'Luxemburg', code: 6661, city: 'Mont' },
  { province: 'Luxemburg', code: 6662, city: 'Tavigny' },
  { province: 'Luxemburg', code: 6663, city: 'Mabompré' },
  { province: 'Luxemburg', code: 6666, city: 'Wibrin' },
  { province: 'Luxemburg', code: 6670, city: 'Limerlé' },
  { province: 'Luxemburg', code: 6670, city: 'Gouvy' },
  { province: 'Luxemburg', code: 6671, city: 'Bovigny' },
  { province: 'Luxemburg', code: 6672, city: 'Beho' },
  { province: 'Luxemburg', code: 6673, city: 'Cherain' },
  { province: 'Luxemburg', code: 6674, city: 'Montleban' },
  { province: 'Luxemburg', code: 6680, city: 'Amberloup' },
  { province: 'Luxemburg', code: 6680, city: 'Tillet' },
  { province: 'Luxemburg', code: 6680, city: 'Sainte-ode' },
  { province: 'Luxemburg', code: 6681, city: 'Lavacherie' },
  { province: 'Luxemburg', code: 6686, city: 'Flamierge' },
  { province: 'Luxemburg', code: 6687, city: 'Bertogne' },
  { province: 'Luxemburg', code: 6688, city: 'Longchamps' },
  { province: 'Luxemburg', code: 6690, city: 'Vielsalm' },
  { province: 'Luxemburg', code: 6690, city: 'Bihain' },
  { province: 'Luxemburg', code: 6692, city: 'Petit-thier' },
  { province: 'Luxemburg', code: 6698, city: 'Grand-halleux' },
  { province: 'Luxemburg', code: 6700, city: 'Bonnert' },
  { province: 'Luxemburg', code: 6700, city: 'Toernich' },
  { province: 'Luxemburg', code: 6700, city: 'Heinsch' },
  { province: 'Luxemburg', code: 6700, city: 'Arlon' },
  { province: 'Luxemburg', code: 6704, city: 'Guirsch' },
  { province: 'Luxemburg', code: 6706, city: 'Autelbas' },
  { province: 'Luxemburg', code: 6717, city: 'Nobressart' },
  { province: 'Luxemburg', code: 6717, city: 'Thiaumont' },
  { province: 'Luxemburg', code: 6717, city: 'Nothomb' },
  { province: 'Luxemburg', code: 6717, city: 'Attert' },
  { province: 'Luxemburg', code: 6717, city: 'Tontelange' },
  { province: 'Luxemburg', code: 6720, city: 'Habay' },
  { province: 'Luxemburg', code: 6720, city: 'Habay-la-neuve' },
  { province: 'Luxemburg', code: 6720, city: 'Hachy' },
  { province: 'Luxemburg', code: 6721, city: 'Anlier' },
  { province: 'Luxemburg', code: 6723, city: 'Habay-la-vieille' },
  { province: 'Luxemburg', code: 6724, city: 'Houdemont' },
  { province: 'Luxemburg', code: 6724, city: 'Rulles' },
  { province: 'Luxemburg', code: 6730, city: 'Bellefontaine' },
  { province: 'Luxemburg', code: 6730, city: 'Saint-vincent' },
  { province: 'Luxemburg', code: 6730, city: 'Rossignol' },
  { province: 'Luxemburg', code: 6730, city: 'Tintigny' },
  { province: 'Luxemburg', code: 6740, city: 'Etalle' },
  { province: 'Luxemburg', code: 6740, city: 'Villers-sur-semois' },
  { province: 'Luxemburg', code: 6740, city: 'Sainte-marie-sur-semois' },
  { province: 'Luxemburg', code: 6741, city: 'Vance' },
  { province: 'Luxemburg', code: 6742, city: 'Chantemelle' },
  { province: 'Luxemburg', code: 6743, city: 'Buzenol' },
  { province: 'Luxemburg', code: 6747, city: 'Saint-léger' },
  { province: 'Luxemburg', code: 6747, city: 'Meix-le-tige' },
  { province: 'Luxemburg', code: 6747, city: 'Châtillon' },
  { province: 'Luxemburg', code: 6750, city: 'Signeulx' },
  { province: 'Luxemburg', code: 6750, city: 'Musson' },
  { province: 'Luxemburg', code: 6750, city: 'Mussy-la-ville' },
  { province: 'Luxemburg', code: 6760, city: 'Ethe' },
  { province: 'Luxemburg', code: 6760, city: 'Bleid' },
  { province: 'Luxemburg', code: 6760, city: 'Virton' },
  { province: 'Luxemburg', code: 6760, city: 'Ruette' },
  { province: 'Luxemburg', code: 6761, city: 'Latour' },
  { province: 'Luxemburg', code: 6762, city: 'Saint-mard' },
  { province: 'Luxemburg', code: 6767, city: 'Torgny' },
  { province: 'Luxemburg', code: 6767, city: 'Harnoncourt' },
  { province: 'Luxemburg', code: 6767, city: 'Dampicourt' },
  { province: 'Luxemburg', code: 6767, city: 'Lamorteau' },
  { province: 'Luxemburg', code: 6767, city: 'Rouvroy' },
  { province: 'Luxemburg', code: 6769, city: 'Gérouville' },
  { province: 'Luxemburg', code: 6769, city: 'Robelmont' },
  { province: 'Luxemburg', code: 6769, city: 'Villers-la-loue' },
  { province: 'Luxemburg', code: 6769, city: 'Meix-devant-virton' },
  { province: 'Luxemburg', code: 6769, city: 'Sommethonne' },
  { province: 'Luxemburg', code: 6780, city: 'Hondelange' },
  { province: 'Luxemburg', code: 6780, city: 'Wolkrange' },
  { province: 'Luxemburg', code: 6780, city: 'Messancy' },
  { province: 'Luxemburg', code: 6781, city: 'Sélange' },
  { province: 'Luxemburg', code: 6782, city: 'Habergy' },
  { province: 'Luxemburg', code: 6790, city: 'Aubange' },
  { province: 'Luxemburg', code: 6791, city: 'Athus' },
  { province: 'Luxemburg', code: 6792, city: 'Halanzy' },
  { province: 'Luxemburg', code: 6792, city: 'Rachecourt' },
  { province: 'Luxemburg', code: 6800, city: 'Freux' },
  { province: 'Luxemburg', code: 6800, city: 'Remagne' },
  { province: 'Luxemburg', code: 6800, city: 'Moircy' },
  { province: 'Luxemburg', code: 6800, city: 'Bras' },
  { province: 'Luxemburg', code: 6800, city: 'Libramont-chevigny' },
  { province: 'Luxemburg', code: 6800, city: 'Saint-pierre' },
  { province: 'Luxemburg', code: 6800, city: 'Sainte-marie-chevigny' },
  { province: 'Luxemburg', code: 6800, city: 'Recogne' },
  { province: 'Luxemburg', code: 6810, city: 'Jamoigne' },
  { province: 'Luxemburg', code: 6810, city: 'Chiny' },
  { province: 'Luxemburg', code: 6810, city: 'Izel' },
  { province: 'Luxemburg', code: 6811, city: 'Les bulles' },
  { province: 'Luxemburg', code: 6812, city: 'Suxy' },
  { province: 'Luxemburg', code: 6813, city: 'Termes' },
  { province: 'Luxemburg', code: 6820, city: 'Sainte-cécile' },
  { province: 'Luxemburg', code: 6820, city: 'Muno' },
  { province: 'Luxemburg', code: 6820, city: 'Florenville' },
  { province: 'Luxemburg', code: 6820, city: 'Fontenoille' },
  { province: 'Luxemburg', code: 6821, city: 'Lacuisine' },
  { province: 'Luxemburg', code: 6823, city: 'Villers-devant-orval' },
  { province: 'Luxemburg', code: 6824, city: 'Chassepierre' },
  { province: 'Luxemburg', code: 6830, city: 'Rochehaut' },
  { province: 'Luxemburg', code: 6830, city: 'Poupehan' },
  { province: 'Luxemburg', code: 6830, city: 'Bouillon' },
  { province: 'Luxemburg', code: 6830, city: 'Les hayons' },
  { province: 'Luxemburg', code: 6831, city: 'Noirfontaine' },
  { province: 'Luxemburg', code: 6832, city: 'Sensenruth' },
  { province: 'Luxemburg', code: 6833, city: 'Ucimont' },
  { province: 'Luxemburg', code: 6833, city: 'Vivy' },
  { province: 'Luxemburg', code: 6834, city: 'Bellevaux' },
  { province: 'Luxemburg', code: 6836, city: 'Dohan' },
  { province: 'Luxemburg', code: 6838, city: 'Corbion' },
  { province: 'Luxemburg', code: 6840, city: 'Neufchâteau' },
  { province: 'Luxemburg', code: 6840, city: 'Tournay' },
  { province: 'Luxemburg', code: 6840, city: 'Longlier' },
  { province: 'Luxemburg', code: 6840, city: 'Grandvoir' },
  { province: 'Luxemburg', code: 6840, city: 'Grapfontaine' },
  { province: 'Luxemburg', code: 6840, city: 'Hamipré' },
  { province: 'Luxemburg', code: 6850, city: 'Paliseul' },
  { province: 'Luxemburg', code: 6850, city: 'Carlsbourg' },
  { province: 'Luxemburg', code: 6850, city: 'Offagne' },
  { province: 'Luxemburg', code: 6851, city: 'Nollevaux' },
  { province: 'Luxemburg', code: 6852, city: 'Opont' },
  { province: 'Luxemburg', code: 6852, city: 'Maissin' },
  { province: 'Luxemburg', code: 6853, city: 'Framont' },
  { province: 'Luxemburg', code: 6856, city: 'Fays-les-veneurs' },
  { province: 'Luxemburg', code: 6860, city: 'Witry' },
  { province: 'Luxemburg', code: 6860, city: 'Assenois' },
  { province: 'Luxemburg', code: 6860, city: 'Léglise' },
  { province: 'Luxemburg', code: 6860, city: 'Mellier' },
  { province: 'Luxemburg', code: 6860, city: 'Ebly' },
  { province: 'Luxemburg', code: 6870, city: 'Mirwart' },
  { province: 'Luxemburg', code: 6870, city: 'Awenne' },
  { province: 'Luxemburg', code: 6870, city: 'Hatrival' },
  { province: 'Luxemburg', code: 6870, city: 'Arville' },
  { province: 'Luxemburg', code: 6870, city: 'Vesqueville' },
  { province: 'Luxemburg', code: 6870, city: 'Saint-hubert' },
  { province: 'Luxemburg', code: 6880, city: 'Auby-sur-semois' },
  { province: 'Luxemburg', code: 6880, city: 'Jehonville' },
  { province: 'Luxemburg', code: 6880, city: 'Orgeo' },
  { province: 'Luxemburg', code: 6880, city: 'Cugnon' },
  { province: 'Luxemburg', code: 6880, city: 'Bertrix' },
  { province: 'Luxemburg', code: 6887, city: 'Saint-médard' },
  { province: 'Luxemburg', code: 6887, city: 'Herbeumont' },
  { province: 'Luxemburg', code: 6887, city: 'Straimont' },
  { province: 'Luxemburg', code: 6890, city: 'Villance' },
  { province: 'Luxemburg', code: 6890, city: 'Libin' },
  { province: 'Luxemburg', code: 6890, city: 'Transinne' },
  { province: 'Luxemburg', code: 6890, city: 'Anloy' },
  { province: 'Luxemburg', code: 6890, city: 'Smuid' },
  { province: 'Luxemburg', code: 6890, city: 'Ochamps' },
  { province: 'Luxemburg', code: 6890, city: 'Redu' },
  { province: 'Luxemburg', code: 6900, city: 'On' },
  { province: 'Luxemburg', code: 6900, city: 'Hargimont' },
  { province: 'Luxemburg', code: 6900, city: 'Marche-en-famenne' },
  { province: 'Luxemburg', code: 6900, city: 'Aye' },
  { province: 'Luxemburg', code: 6900, city: 'Roy' },
  { province: 'Luxemburg', code: 6900, city: 'Humain' },
  { province: 'Luxemburg', code: 6900, city: 'Waha' },
  { province: 'Luxemburg', code: 6920, city: 'Wellin' },
  { province: 'Luxemburg', code: 6920, city: 'Sohier' },
  { province: 'Luxemburg', code: 6921, city: 'Chanly' },
  { province: 'Luxemburg', code: 6922, city: 'Halma' },
  { province: 'Luxemburg', code: 6924, city: 'Lomprez' },
  { province: 'Luxemburg', code: 6927, city: 'Grupont' },
  { province: 'Luxemburg', code: 6927, city: 'Resteigne' },
  { province: 'Luxemburg', code: 6927, city: 'Tellin' },
  { province: 'Luxemburg', code: 6927, city: 'Bure' },
  { province: 'Luxemburg', code: 6929, city: 'Gembes' },
  { province: 'Luxemburg', code: 6929, city: 'Daverdisse' },
  { province: 'Luxemburg', code: 6929, city: 'Porcheresse' },
  { province: 'Luxemburg', code: 6929, city: 'Haut-fays' },
  { province: 'Luxemburg', code: 6940, city: 'Wéris' },
  { province: 'Luxemburg', code: 6940, city: 'Septon' },
  { province: 'Luxemburg', code: 6940, city: 'Grandhan' },
  { province: 'Luxemburg', code: 6940, city: 'Barvaux-sur-ourthe' },
  { province: 'Luxemburg', code: 6940, city: 'Durbuy' },
  { province: 'Luxemburg', code: 6941, city: 'Bende' },
  { province: 'Luxemburg', code: 6941, city: 'Villers-sainte-gertrude' },
  { province: 'Luxemburg', code: 6941, city: 'Bomal-sur-ourthe' },
  { province: 'Luxemburg', code: 6941, city: 'Borlon' },
  { province: 'Luxemburg', code: 6941, city: 'Tohogne' },
  { province: 'Luxemburg', code: 6941, city: 'Heyd' },
  { province: 'Luxemburg', code: 6941, city: 'Izier' },
  { province: 'Luxemburg', code: 6950, city: 'Harsin' },
  { province: 'Luxemburg', code: 6950, city: 'Nassogne' },
  { province: 'Luxemburg', code: 6951, city: 'Bande' },
  { province: 'Luxemburg', code: 6952, city: 'Grune' },
  { province: 'Luxemburg', code: 6953, city: 'Lesterny' },
  { province: 'Luxemburg', code: 6953, city: 'Ambly' },
  { province: 'Luxemburg', code: 6953, city: 'Masbourg' },
  { province: 'Luxemburg', code: 6953, city: 'Forrières' },
  { province: 'Luxemburg', code: 6960, city: 'Malempré' },
  { province: 'Luxemburg', code: 6960, city: 'Manhay' },
  { province: 'Luxemburg', code: 6960, city: 'Harre' },
  { province: 'Luxemburg', code: 6960, city: 'Dochamps' },
  { province: 'Luxemburg', code: 6960, city: 'Vaux-chavanne' },
  { province: 'Luxemburg', code: 6960, city: 'Grandmenil' },
  { province: 'Luxemburg', code: 6960, city: 'Odeigne' },
  { province: 'Luxemburg', code: 6970, city: 'Tenneville' },
  { province: 'Luxemburg', code: 6971, city: 'Champlon' },
  { province: 'Luxemburg', code: 6972, city: 'Erneuville' },
  { province: 'Luxemburg', code: 6980, city: 'La roche-en-ardenne' },
  { province: 'Luxemburg', code: 6980, city: 'Beausaint' },
  { province: 'Luxemburg', code: 6982, city: 'Samrée' },
  { province: 'Luxemburg', code: 6983, city: 'Ortho' },
  { province: 'Luxemburg', code: 6984, city: 'Hives' },
  { province: 'Luxemburg', code: 6986, city: 'Halleux' },
  { province: 'Luxemburg', code: 6987, city: 'Hodister' },
  { province: 'Luxemburg', code: 6987, city: 'Rendeux' },
  { province: 'Luxemburg', code: 6987, city: 'Marcourt' },
  { province: 'Luxemburg', code: 6987, city: 'Beffe' },
  { province: 'Luxemburg', code: 6990, city: 'Fronville' },
  { province: 'Luxemburg', code: 6990, city: 'Marenne' },
  { province: 'Luxemburg', code: 6990, city: 'Hotton' },
  { province: 'Luxemburg', code: 6990, city: 'Hampteau' },
  { province: 'Luxemburg', code: 6997, city: 'Amonines' },
  { province: 'Luxemburg', code: 6997, city: 'Mormont' },
  { province: 'Luxemburg', code: 6997, city: 'Soy' },
  { province: 'Luxemburg', code: 6997, city: 'Erezée' },











  // Henehouwen
  { province: 'Henehouwen', code: 6000, city: 'Charleroi' },
  { province: 'Henehouwen', code: 6001, city: 'Marcinelle' },
  { province: 'Henehouwen', code: 6010, city: 'Couillet' },
  { province: 'Henehouwen', code: 6020, city: 'Dampremy' },
  { province: 'Henehouwen', code: 6030, city: 'Marchienne-au-pont' },
  { province: 'Henehouwen', code: 6030, city: 'Goutroux' },
  { province: 'Henehouwen', code: 6031, city: 'Monceau-sur-sambre' },
  { province: 'Henehouwen', code: 6032, city: 'Mont-sur-marchienne' },
  { province: 'Henehouwen', code: 6040, city: 'Jumet' },
  { province: 'Henehouwen', code: 6041, city: 'Gosselies' },
  { province: 'Henehouwen', code: 6042, city: 'Lodelinsart' },
  { province: 'Henehouwen', code: 6043, city: 'Ransart' },
  { province: 'Henehouwen', code: 6044, city: 'Roux' },
  { province: 'Henehouwen', code: 6060, city: 'Gilly' },
  { province: 'Henehouwen', code: 6061, city: 'Montignies-sur-sambre' },
  { province: 'Henehouwen', code: 6110, city: 'Montigny-le-tilleul' },
  { province: 'Henehouwen', code: 6111, city: 'Landelies' },
  { province: 'Henehouwen', code: 6120, city: 'Marbaix' },
  { province: 'Henehouwen', code: 6120, city: 'Ham-sur-heure' },
  { province: 'Henehouwen', code: 6120, city: 'Nalinnes' },
  { province: 'Henehouwen', code: 6120, city: 'Jamioulx' },
  { province: 'Henehouwen', code: 6120, city: 'Cour-sur-heure' },
  { province: 'Henehouwen', code: 6140, city: 'Fontaine-l\'evêque' },
  { province: 'Henehouwen', code: 6141, city: 'Forchies-la-marche' },
  { province: 'Henehouwen', code: 6142, city: 'Leernes' },
  { province: 'Henehouwen', code: 6150, city: 'Anderlues' },
  { province: 'Henehouwen', code: 6180, city: 'Courcelles' },
  { province: 'Henehouwen', code: 6181, city: 'Gouy-lez-piéton' },
  { province: 'Henehouwen', code: 6182, city: 'Souvret' },
  { province: 'Henehouwen', code: 6183, city: 'Trazegnies' },
  { province: 'Henehouwen', code: 6200, city: 'Châtelet' },
  { province: 'Henehouwen', code: 6200, city: 'Châtelineau' },
  { province: 'Henehouwen', code: 6200, city: 'Bouffioulx' },
  { province: 'Henehouwen', code: 6210, city: 'Wayaux' },
  { province: 'Henehouwen', code: 6210, city: 'Villers-perwin' },
  { province: 'Henehouwen', code: 6210, city: 'Rèves' },
  { province: 'Henehouwen', code: 6210, city: 'Frasnes-lez-gosselies' },
  { province: 'Henehouwen', code: 6210, city: 'Les bons villers' },
  { province: 'Henehouwen', code: 6211, city: 'Mellet' },
  { province: 'Henehouwen', code: 6220, city: 'Lambusart' },
  { province: 'Henehouwen', code: 6220, city: 'Wangenies' },
  { province: 'Henehouwen', code: 6220, city: 'Heppignies' },
  { province: 'Henehouwen', code: 6220, city: 'Fleurus' },
  { province: 'Henehouwen', code: 6221, city: 'Saint-amand' },
  { province: 'Henehouwen', code: 6222, city: 'Brye' },
  { province: 'Henehouwen', code: 6223, city: 'Wagnelée' },
  { province: 'Henehouwen', code: 6224, city: 'Wanfercée-baulet' },
  { province: 'Henehouwen', code: 6230, city: 'Pont-à-celles' },
  { province: 'Henehouwen', code: 6230, city: 'Thiméon' },
  { province: 'Henehouwen', code: 6230, city: 'Viesville' },
  { province: 'Henehouwen', code: 6230, city: 'Buzet' },
  { province: 'Henehouwen', code: 6230, city: 'Obaix' },
  { province: 'Henehouwen', code: 6238, city: 'Luttre' },
  { province: 'Henehouwen', code: 6238, city: 'Liberchies' },
  { province: 'Henehouwen', code: 6240, city: 'Farciennes' },
  { province: 'Henehouwen', code: 6240, city: 'Pironchamps' },
  { province: 'Henehouwen', code: 6250, city: 'Pont-de-loup' },
  { province: 'Henehouwen', code: 6250, city: 'Roselies' },
  { province: 'Henehouwen', code: 6250, city: 'Presles' },
  { province: 'Henehouwen', code: 6250, city: 'Aiseau' },
  { province: 'Henehouwen', code: 6250, city: 'Aiseau-presles' },
  { province: 'Henehouwen', code: 6280, city: 'Loverval' },
  { province: 'Henehouwen', code: 6280, city: 'Villers-poterie' },
  { province: 'Henehouwen', code: 6280, city: 'Gerpinnes' },
  { province: 'Henehouwen', code: 6280, city: 'Joncret' },
  { province: 'Henehouwen', code: 6280, city: 'Gougnies' },
  { province: 'Henehouwen', code: 6280, city: 'Acoz' },
  { province: 'Henehouwen', code: 6440, city: 'Vergnies' },
  { province: 'Henehouwen', code: 6440, city: 'Boussu-lez-walcourt' },
  { province: 'Henehouwen', code: 6440, city: 'Fourbechies' },
  { province: 'Henehouwen', code: 6440, city: 'Froidchapelle' },
  { province: 'Henehouwen', code: 6441, city: 'Erpion' },
  { province: 'Henehouwen', code: 6460, city: 'Chimay' },
  { province: 'Henehouwen', code: 6460, city: 'Salles' },
  { province: 'Henehouwen', code: 6460, city: 'Robechies' },
  { province: 'Henehouwen', code: 6460, city: 'Saint-remy' },
  { province: 'Henehouwen', code: 6460, city: 'Bailièvre' },
  { province: 'Henehouwen', code: 6460, city: 'Villers-la-tour' },
  { province: 'Henehouwen', code: 6461, city: 'Virelles' },
  { province: 'Henehouwen', code: 6462, city: 'Vaulx-lez-chimay' },
  { province: 'Henehouwen', code: 6463, city: 'Lompret' },
  { province: 'Henehouwen', code: 6464, city: 'Bourlers' },
  { province: 'Henehouwen', code: 6464, city: 'L\'escaillère' },
  { province: 'Henehouwen', code: 6464, city: 'Forges' },
  { province: 'Henehouwen', code: 6464, city: 'Rièzes' },
  { province: 'Henehouwen', code: 6464, city: 'Baileux' },
  { province: 'Henehouwen', code: 6470, city: 'Sivry' },
  { province: 'Henehouwen', code: 6470, city: 'Sivry-rance' },
  { province: 'Henehouwen', code: 6470, city: 'Grandrieu' },
  { province: 'Henehouwen', code: 6470, city: 'Sautin' },
  { province: 'Henehouwen', code: 6470, city: 'Rance' },
  { province: 'Henehouwen', code: 6470, city: 'Montbliart' },
  { province: 'Henehouwen', code: 6500, city: 'Renlies' },
  { province: 'Henehouwen', code: 6500, city: 'Barbençon' },
  { province: 'Henehouwen', code: 6500, city: 'Leugnies' },
  { province: 'Henehouwen', code: 6500, city: 'Solre-saint-géry' },
  { province: 'Henehouwen', code: 6500, city: 'Leval-chaudeville' },
  { province: 'Henehouwen', code: 6500, city: 'Thirimont' },
  { province: 'Henehouwen', code: 6500, city: 'Beaumont' },
  { province: 'Henehouwen', code: 6511, city: 'Strée' },
  { province: 'Henehouwen', code: 6530, city: 'Thuin' },
  { province: 'Henehouwen', code: 6530, city: 'Leers-et-fosteau' },
  { province: 'Henehouwen', code: 6531, city: 'Biesme-sous-thuin' },
  { province: 'Henehouwen', code: 6532, city: 'Ragnies' },
  { province: 'Henehouwen', code: 6533, city: 'Biercée' },
  { province: 'Henehouwen', code: 6534, city: 'Gozée' },
  { province: 'Henehouwen', code: 6536, city: 'Donstiennes' },
  { province: 'Henehouwen', code: 6536, city: 'Thuillies' },
  { province: 'Henehouwen', code: 6540, city: 'Mont-sainte-geneviève' },
  { province: 'Henehouwen', code: 6540, city: 'Lobbes' },
  { province: 'Henehouwen', code: 6542, city: 'Sars-la-buissière' },
  { province: 'Henehouwen', code: 6543, city: 'Bienne-lez-happart' },
  { province: 'Henehouwen', code: 6560, city: 'Grand-reng' },
  { province: 'Henehouwen', code: 6560, city: 'Montignies-saint-christophe' },
  { province: 'Henehouwen', code: 6560, city: 'Hantes-wihéries' },
  { province: 'Henehouwen', code: 6560, city: 'Solre-sur-sambre' },
  { province: 'Henehouwen', code: 6560, city: 'Bersillies-l\'abbaye' },
  { province: 'Henehouwen', code: 6560, city: 'Erquelinnes' },
  { province: 'Henehouwen', code: 6567, city: 'Merbes-le-château' },
  { province: 'Henehouwen', code: 6567, city: 'Merbes-sainte-marie' },
  { province: 'Henehouwen', code: 6567, city: 'Labuissière' },
  { province: 'Henehouwen', code: 6567, city: 'Fontaine-valmont' },
  { province: 'Henehouwen', code: 6590, city: 'Momignies' },
  { province: 'Henehouwen', code: 6591, city: 'Macon' },
  { province: 'Henehouwen', code: 6592, city: 'Monceau-imbrechies' },
  { province: 'Henehouwen', code: 6593, city: 'Macquenoise' },
  { province: 'Henehouwen', code: 6594, city: 'Beauwelz' },
  { province: 'Henehouwen', code: 6596, city: 'Forge-philippe' },
  { province: 'Henehouwen', code: 6596, city: 'Seloignes' },
  { province: 'Henehouwen', code: 7000, city: 'Mons' },
  { province: 'Henehouwen', code: 7011, city: 'Ghlin' },
  { province: 'Henehouwen', code: 7012, city: 'Jemappes' },
  { province: 'Henehouwen', code: 7012, city: 'Flénu' },
  { province: 'Henehouwen', code: 7020, city: 'Nimy' },
  { province: 'Henehouwen', code: 7020, city: 'Maisières' },
  { province: 'Henehouwen', code: 7021, city: 'Havre' },
  { province: 'Henehouwen', code: 7022, city: 'Hyon' },
  { province: 'Henehouwen', code: 7022, city: 'Harmignies' },
  { province: 'Henehouwen', code: 7022, city: 'Harveng' },
  { province: 'Henehouwen', code: 7022, city: 'Mesvin' },
  { province: 'Henehouwen', code: 7022, city: 'Nouvelles' },
  { province: 'Henehouwen', code: 7024, city: 'Ciply' },
  { province: 'Henehouwen', code: 7030, city: 'Saint-symphorien' },
  { province: 'Henehouwen', code: 7031, city: 'Villers-saint-ghislain' },
  { province: 'Henehouwen', code: 7032, city: 'Spiennes' },
  { province: 'Henehouwen', code: 7033, city: 'Cuesmes' },
  { province: 'Henehouwen', code: 7034, city: 'Obourg' },
  { province: 'Henehouwen', code: 7034, city: 'Saint-denis' },
  { province: 'Henehouwen', code: 7040, city: 'Bougnies' },
  { province: 'Henehouwen', code: 7040, city: 'Quévy' },
  { province: 'Henehouwen', code: 7040, city: 'Asquillies' },
  { province: 'Henehouwen', code: 7040, city: 'Quévy-le-grand' },
  { province: 'Henehouwen', code: 7040, city: 'Blaregnies' },
  { province: 'Henehouwen', code: 7040, city: 'Quévy-le-petit' },
  { province: 'Henehouwen', code: 7040, city: 'Goegnies-chaussée' },
  { province: 'Henehouwen', code: 7040, city: 'Genly' },
  { province: 'Henehouwen', code: 7040, city: 'Aulnois' },
  { province: 'Henehouwen', code: 7041, city: 'Givry' },
  { province: 'Henehouwen', code: 7041, city: 'Havay' },
  { province: 'Henehouwen', code: 7050, city: 'Herchies' },
  { province: 'Henehouwen', code: 7050, city: 'Erbaut' },
  { province: 'Henehouwen', code: 7050, city: 'Erbisoeul' },
  { province: 'Henehouwen', code: 7050, city: 'Masnuy-saint-jean' },
  { province: 'Henehouwen', code: 7050, city: 'Masnuy-saint-pierre' },
  { province: 'Henehouwen', code: 7050, city: 'Jurbise' },
  { province: 'Henehouwen', code: 7060, city: 'Soignies' },
  { province: 'Henehouwen', code: 7060, city: 'Horrues' },
  { province: 'Henehouwen', code: 7061, city: 'Casteau' },
  { province: 'Henehouwen', code: 7061, city: 'Thieusies' },
  { province: 'Henehouwen', code: 7062, city: 'Naast' },
  { province: 'Henehouwen', code: 7063, city: 'Neufvilles' },
  { province: 'Henehouwen', code: 7063, city: 'Chaussée-notre-dame-louvignies' },
  { province: 'Henehouwen', code: 7070, city: 'Thieu' },
  { province: 'Henehouwen', code: 7070, city: 'Le roeulx' },
  { province: 'Henehouwen', code: 7070, city: 'Gottignies' },
  { province: 'Henehouwen', code: 7070, city: 'Ville-sur-haine' },
  { province: 'Henehouwen', code: 7070, city: 'Mignault' },
  { province: 'Henehouwen', code: 7080, city: 'La bouverie' },
  { province: 'Henehouwen', code: 7080, city: 'Sars-la-bruyère' },
  { province: 'Henehouwen', code: 7080, city: 'Frameries' },
  { province: 'Henehouwen', code: 7080, city: 'Eugies' },
  { province: 'Henehouwen', code: 7080, city: 'Noirchain' },
  { province: 'Henehouwen', code: 7090, city: 'Petit-Roeulx-lez-Braine' },
  { province: 'Henehouwen', code: 7090, city: 'Ronquières' },
  { province: 'Henehouwen', code: 7090, city: '\'s-Gravenbrakel' },
  { province: 'Henehouwen', code: 7090, city: 'Steenkerque' },
  { province: 'Henehouwen', code: 7090, city: 'Hennuyères' },
  { province: 'Henehouwen', code: 7090, city: 'Henripont' },
  { province: 'Henehouwen', code: 7100, city: 'Saint-vaast' },
  { province: 'Henehouwen', code: 7100, city: 'La louvière' },
  { province: 'Henehouwen', code: 7100, city: 'Trivières' },
  { province: 'Henehouwen', code: 7100, city: 'Haine-saint-paul' },
  { province: 'Henehouwen', code: 7100, city: 'Haine-saint-pierre' },
  { province: 'Henehouwen', code: 7110, city: 'Maurage' },
  { province: 'Henehouwen', code: 7110, city: 'Boussoit' },
  { province: 'Henehouwen', code: 7110, city: 'Houdeng-aimeries' },
  { province: 'Henehouwen', code: 7110, city: 'Houdeng-goegnies' },
  { province: 'Henehouwen', code: 7110, city: 'Strépy-bracquegnies' },
  { province: 'Henehouwen', code: 7120, city: 'Fauroeulx' },
  { province: 'Henehouwen', code: 7120, city: 'Vellereille-le-sec' },
  { province: 'Henehouwen', code: 7120, city: 'Peissant' },
  { province: 'Henehouwen', code: 7120, city: 'Vellereille-les-brayeux' },
  { province: 'Henehouwen', code: 7120, city: 'Estinnes' },
  { province: 'Henehouwen', code: 7120, city: 'Estinnes-au-mont' },
  { province: 'Henehouwen', code: 7120, city: 'Haulchin' },
  { province: 'Henehouwen', code: 7120, city: 'Estinnes-au-val' },
  { province: 'Henehouwen', code: 7120, city: 'Rouveroy' },
  { province: 'Henehouwen', code: 7120, city: 'Croix-lez-rouveroy' },
  { province: 'Henehouwen', code: 7130, city: 'Battignies' },
  { province: 'Henehouwen', code: 7130, city: 'Bray' },
  { province: 'Henehouwen', code: 7130, city: 'Binche' },
  { province: 'Henehouwen', code: 7131, city: 'Waudrez' },
  { province: 'Henehouwen', code: 7133, city: 'Buvrinnes' },
  { province: 'Henehouwen', code: 7134, city: 'Ressaix' },
  { province: 'Henehouwen', code: 7134, city: 'Epinois' },
  { province: 'Henehouwen', code: 7134, city: 'Péronnes-lez-binche' },
  { province: 'Henehouwen', code: 7134, city: 'Leval-trahegnies' },
  { province: 'Henehouwen', code: 7140, city: 'Morlanwelz' },
  { province: 'Henehouwen', code: 7140, city: 'Morlanwelz-mariemont' },
  { province: 'Henehouwen', code: 7141, city: 'Carnières' },
  { province: 'Henehouwen', code: 7141, city: 'Mont-sainte-aldegonde' },
  { province: 'Henehouwen', code: 7160, city: 'Godarville' },
  { province: 'Henehouwen', code: 7160, city: 'Chapelle-lez-herlaimont' },
  { province: 'Henehouwen', code: 7160, city: 'Piéton' },
  { province: 'Henehouwen', code: 7170, city: 'Bellecourt' },
  { province: 'Henehouwen', code: 7170, city: 'Fayt-lez-manage' },
  { province: 'Henehouwen', code: 7170, city: 'La hestre' },
  { province: 'Henehouwen', code: 7170, city: 'Bois-d\'haine' },
  { province: 'Henehouwen', code: 7170, city: 'Manage' },
  { province: 'Henehouwen', code: 7180, city: 'Seneffe' },
  { province: 'Henehouwen', code: 7181, city: 'Arquennes' },
  { province: 'Henehouwen', code: 7181, city: 'Feluy' },
  { province: 'Henehouwen', code: 7181, city: 'Petit-roeulx-lez-nivelles' },
  { province: 'Henehouwen', code: 7181, city: 'Familleureux' },
  { province: 'Henehouwen', code: 7190, city: 'Marche-lez-ecaussinnes' },
  { province: 'Henehouwen', code: 7190, city: 'Ecaussinnes' },
  { province: 'Henehouwen', code: 7190, city: 'Ecaussinnes-d\'enghien' },
  { province: 'Henehouwen', code: 7191, city: 'Ecaussinnes-lalaing' },
  { province: 'Henehouwen', code: 7300, city: 'Boussu' },
  { province: 'Henehouwen', code: 7301, city: 'Hornu' },
  { province: 'Henehouwen', code: 7320, city: 'Bernissart' },
  { province: 'Henehouwen', code: 7321, city: 'Blaton' },
  { province: 'Henehouwen', code: 7321, city: 'Harchies' },
  { province: 'Henehouwen', code: 7322, city: 'Pommeroeul' },
  { province: 'Henehouwen', code: 7322, city: 'Ville-pommeroeul' },
  { province: 'Henehouwen', code: 7330, city: 'Saint-ghislain' },
  { province: 'Henehouwen', code: 7331, city: 'Baudour' },
  { province: 'Henehouwen', code: 7332, city: 'Neufmaison' },
  { province: 'Henehouwen', code: 7332, city: 'Sirault' },
  { province: 'Henehouwen', code: 7333, city: 'Tertre' },
  { province: 'Henehouwen', code: 7334, city: 'Hautrage' },
  { province: 'Henehouwen', code: 7334, city: 'Villerot' },
  { province: 'Henehouwen', code: 7340, city: 'Paturages' },
  { province: 'Henehouwen', code: 7340, city: 'Colfontaine' },
  { province: 'Henehouwen', code: 7340, city: 'Warquignies' },
  { province: 'Henehouwen', code: 7340, city: 'Wasmes' },
  { province: 'Henehouwen', code: 7350, city: 'Hainin' },
  { province: 'Henehouwen', code: 7350, city: 'Hensies' },
  { province: 'Henehouwen', code: 7350, city: 'Thulin' },
  { province: 'Henehouwen', code: 7350, city: 'Montroeul-sur-haine' },
  { province: 'Henehouwen', code: 7370, city: 'Elouges' },
  { province: 'Henehouwen', code: 7370, city: 'Wihéries' },
  { province: 'Henehouwen', code: 7370, city: 'Blaugies' },
  { province: 'Henehouwen', code: 7370, city: 'Dour' },
  { province: 'Henehouwen', code: 7380, city: 'Baisieux' },
  { province: 'Henehouwen', code: 7380, city: 'Quiévrain' },
  { province: 'Henehouwen', code: 7382, city: 'Audregnies' },
  { province: 'Henehouwen', code: 7387, city: 'Honnelles' },
  { province: 'Henehouwen', code: 7387, city: 'Montignies-sur-roc' },
  { province: 'Henehouwen', code: 7387, city: 'Angre' },
  { province: 'Henehouwen', code: 7387, city: 'Fayt-le-franc' },
  { province: 'Henehouwen', code: 7387, city: 'Athis' },
  { province: 'Henehouwen', code: 7387, city: 'Marchipont' },
  { province: 'Henehouwen', code: 7387, city: 'Angreau' },
  { province: 'Henehouwen', code: 7387, city: 'Roisin' },
  { province: 'Henehouwen', code: 7387, city: 'Onnezies' },
  { province: 'Henehouwen', code: 7387, city: 'Autreppe' },
  { province: 'Henehouwen', code: 7387, city: 'Erquennes' },
  { province: 'Henehouwen', code: 7390, city: 'Wasmuel' },
  { province: 'Henehouwen', code: 7390, city: 'Quaregnon' },
  { province: 'Henehouwen', code: 7500, city: 'Tournai' },
  { province: 'Henehouwen', code: 7500, city: 'Ere' },
  { province: 'Henehouwen', code: 7500, city: 'Saint-maur' },
  { province: 'Henehouwen', code: 7501, city: 'Orcq' },
  { province: 'Henehouwen', code: 7502, city: 'Esplechin' },
  { province: 'Henehouwen', code: 7503, city: 'Froyennes' },
  { province: 'Henehouwen', code: 7504, city: 'Froidmont' },
  { province: 'Henehouwen', code: 7506, city: 'Willemeau' },
  { province: 'Henehouwen', code: 7520, city: 'Templeuve' },
  { province: 'Henehouwen', code: 7520, city: 'Ramegnies-chin' },
  { province: 'Henehouwen', code: 7521, city: 'Chercq' },
  { province: 'Henehouwen', code: 7522, city: 'Blandain' },
  { province: 'Henehouwen', code: 7522, city: 'Marquain' },
  { province: 'Henehouwen', code: 7522, city: 'Hertain' },
  { province: 'Henehouwen', code: 7522, city: 'Lamain' },
  { province: 'Henehouwen', code: 7530, city: 'Gaurain-ramecroix' },
  { province: 'Henehouwen', code: 7531, city: 'Havinnes' },
  { province: 'Henehouwen', code: 7532, city: 'Beclers' },
  { province: 'Henehouwen', code: 7533, city: 'Thimougies' },
  { province: 'Henehouwen', code: 7534, city: 'Barry' },
  { province: 'Henehouwen', code: 7534, city: 'Maulde' },
  { province: 'Henehouwen', code: 7536, city: 'Vaulx' },
  { province: 'Henehouwen', code: 7538, city: 'Vezon' },
  { province: 'Henehouwen', code: 7540, city: 'Melles' },
  { province: 'Henehouwen', code: 7540, city: 'Rumillies' },
  { province: 'Henehouwen', code: 7540, city: 'Kain' },
  { province: 'Henehouwen', code: 7540, city: 'Quartes' },
  { province: 'Henehouwen', code: 7542, city: 'Mont-saint-aubert' },
  { province: 'Henehouwen', code: 7543, city: 'Mourcourt' },
  { province: 'Henehouwen', code: 7548, city: 'Warchin' },
  { province: 'Henehouwen', code: 7600, city: 'Péruwelz' },
  { province: 'Henehouwen', code: 7601, city: 'Roucourt' },
  { province: 'Henehouwen', code: 7602, city: 'Bury' },
  { province: 'Henehouwen', code: 7603, city: 'Bon-secours' },
  { province: 'Henehouwen', code: 7604, city: 'Braffe' },
  { province: 'Henehouwen', code: 7604, city: 'Wasmes-audemez-briffoeil' },
  { province: 'Henehouwen', code: 7604, city: 'Brasmenil' },
  { province: 'Henehouwen', code: 7604, city: 'Callenelle' },
  { province: 'Henehouwen', code: 7604, city: 'Baugnies' },
  { province: 'Henehouwen', code: 7608, city: 'Wiers' },
  { province: 'Henehouwen', code: 7610, city: 'Rumes' },
  { province: 'Henehouwen', code: 7611, city: 'La glanerie' },
  { province: 'Henehouwen', code: 7618, city: 'Taintignies' },
  { province: 'Henehouwen', code: 7620, city: 'Hollain' },
  { province: 'Henehouwen', code: 7620, city: 'Wez-velvain' },
  { province: 'Henehouwen', code: 7620, city: 'Bléharies' },
  { province: 'Henehouwen', code: 7620, city: 'Jollain-merlin' },
  { province: 'Henehouwen', code: 7620, city: 'Guignies' },
  { province: 'Henehouwen', code: 7620, city: 'Brunehaut' },
  { province: 'Henehouwen', code: 7621, city: 'Lesdain' },
  { province: 'Henehouwen', code: 7622, city: 'Laplaigne' },
  { province: 'Henehouwen', code: 7623, city: 'Rongy' },
  { province: 'Henehouwen', code: 7624, city: 'Howardries' },
  { province: 'Henehouwen', code: 7640, city: 'Péronnes-lez-antoing' },
  { province: 'Henehouwen', code: 7640, city: 'Antoing' },
  { province: 'Henehouwen', code: 7640, city: 'Maubray' },
  { province: 'Henehouwen', code: 7641, city: 'Bruyelle' },
  { province: 'Henehouwen', code: 7642, city: 'Calonne' },
  { province: 'Henehouwen', code: 7643, city: 'Fontenoy' },
  { province: 'Henehouwen', code: 7700, city: 'Luingne' },
  { province: 'Henehouwen', code: 7700, city: 'Moeskroen' },
  { province: 'Henehouwen', code: 7711, city: 'Dottenijs' },
  { province: 'Henehouwen', code: 7712, city: 'Herseaux' },
  { province: 'Henehouwen', code: 7730, city: 'Bailleul' },
  { province: 'Henehouwen', code: 7730, city: 'Estaimbourg' },
  { province: 'Henehouwen', code: 7730, city: 'Néchin' },
  { province: 'Henehouwen', code: 7730, city: 'Estaimpuis' },
  { province: 'Henehouwen', code: 7730, city: 'Leers-nord' },
  { province: 'Henehouwen', code: 7730, city: 'Saint-léger' },
  { province: 'Henehouwen', code: 7730, city: 'Evregnies' },
  { province: 'Henehouwen', code: 7740, city: 'Pecq' },
  { province: 'Henehouwen', code: 7740, city: 'Warcoing' },
  { province: 'Henehouwen', code: 7742, city: 'Hérinnes-lez-pecq' },
  { province: 'Henehouwen', code: 7743, city: 'Obigies' },
  { province: 'Henehouwen', code: 7743, city: 'Esquelmes' },
  { province: 'Henehouwen', code: 7750, city: 'Amougies' },
  { province: 'Henehouwen', code: 7750, city: 'Russeignies' },
  { province: 'Henehouwen', code: 7750, city: 'Anseroeul' },
  { province: 'Henehouwen', code: 7750, city: 'Mont-de-l\'enclus' },
  { province: 'Henehouwen', code: 7750, city: 'Orroir' },
  { province: 'Henehouwen', code: 7760, city: 'Escanaffles' },
  { province: 'Henehouwen', code: 7760, city: 'Velaines' },
  { province: 'Henehouwen', code: 7760, city: 'Celles' },
  { province: 'Henehouwen', code: 7760, city: 'Popuelles' },
  { province: 'Henehouwen', code: 7760, city: 'Pottes' },
  { province: 'Henehouwen', code: 7760, city: 'Molenbaix' },
  { province: 'Henehouwen', code: 7780, city: 'Komen-waasten' },
  { province: 'Henehouwen', code: 7780, city: 'Komen' },
  { province: 'Henehouwen', code: 7781, city: 'Houthem' },
  { province: 'Henehouwen', code: 7782, city: 'Ploegsteert' },
  { province: 'Henehouwen', code: 7783, city: 'Bizet' },
  { province: 'Henehouwen', code: 7784, city: 'Neerwaasten' },
  { province: 'Henehouwen', code: 7784, city: 'Waasten' },
  { province: 'Henehouwen', code: 7800, city: 'Ath' },
  { province: 'Henehouwen', code: 7800, city: 'Lanquesaint' },
  { province: 'Henehouwen', code: 7801, city: 'Irchonwelz' },
  { province: 'Henehouwen', code: 7802, city: 'Ormeignies' },
  { province: 'Henehouwen', code: 7803, city: 'Bouvignies' },
  { province: 'Henehouwen', code: 7804, city: 'Ostiches' },
  { province: 'Henehouwen', code: 7804, city: 'Rebaix' },
  { province: 'Henehouwen', code: 7810, city: 'Maffle' },
  { province: 'Henehouwen', code: 7811, city: 'Arbre' },
  { province: 'Henehouwen', code: 7812, city: 'Villers-notre-dame' },
  { province: 'Henehouwen', code: 7812, city: 'Villers-saint-amand' },
  { province: 'Henehouwen', code: 7812, city: 'Houtaing' },
  { province: 'Henehouwen', code: 7812, city: 'Ligne' },
  { province: 'Henehouwen', code: 7812, city: 'Mainvault' },
  { province: 'Henehouwen', code: 7812, city: 'Moulbaix' },
  { province: 'Henehouwen', code: 7822, city: 'Isières' },
  { province: 'Henehouwen', code: 7822, city: 'Meslin-l\'evêque' },
  { province: 'Henehouwen', code: 7822, city: 'Ghislenghien' },
  { province: 'Henehouwen', code: 7823, city: 'Gibecq' },
  { province: 'Henehouwen', code: 7830, city: 'Hellebecq' },
  { province: 'Henehouwen', code: 7830, city: 'Fouleng' },
  { province: 'Henehouwen', code: 7830, city: 'Gondregnies' },
  { province: 'Henehouwen', code: 7830, city: 'Graty' },
  { province: 'Henehouwen', code: 7830, city: 'Bassilly' },
  { province: 'Henehouwen', code: 7830, city: 'Hoves' },
  { province: 'Henehouwen', code: 7830, city: 'Silly' },
  { province: 'Henehouwen', code: 7830, city: 'Thoricourt' },
  { province: 'Henehouwen', code: 7850, city: 'Lettelingen' },
  { province: 'Henehouwen', code: 7850, city: 'Mark' },
  { province: 'Henehouwen', code: 7850, city: 'Edingen' },
  { province: 'Henehouwen', code: 7860, city: 'Lessines' },
  { province: 'Henehouwen', code: 7861, city: 'Papignies' },
  { province: 'Henehouwen', code: 7861, city: 'Wannebecq' },
  { province: 'Henehouwen', code: 7862, city: 'Ogy' },
  { province: 'Henehouwen', code: 7863, city: 'Ghoy' },
  { province: 'Henehouwen', code: 7864, city: 'Deux-acren' },
  { province: 'Henehouwen', code: 7866, city: 'Ollignies' },
  { province: 'Henehouwen', code: 7866, city: 'Bois-de-lessines' },
  { province: 'Henehouwen', code: 7870, city: 'Cambron-saint-vincent' },
  { province: 'Henehouwen', code: 7870, city: 'Montignies-lez-lens' },
  { province: 'Henehouwen', code: 7870, city: 'Lens' },
  { province: 'Henehouwen', code: 7870, city: 'Bauffe' },
  { province: 'Henehouwen', code: 7870, city: 'Lombise' },
  { province: 'Henehouwen', code: 7880, city: 'Vloesberg' },
  { province: 'Henehouwen', code: 7890, city: 'Ellezelles' },
  { province: 'Henehouwen', code: 7890, city: 'Wodecq' },
  { province: 'Henehouwen', code: 7890, city: 'Lahamaide' },
  { province: 'Henehouwen', code: 7900, city: 'Grandmetz' },
  { province: 'Henehouwen', code: 7900, city: 'Leuze-en-hainaut' },
  { province: 'Henehouwen', code: 7901, city: 'Thieulain' },
  { province: 'Henehouwen', code: 7903, city: 'Chapelle-à-oie' },
  { province: 'Henehouwen', code: 7903, city: 'Chapelle-à-wattines' },
  { province: 'Henehouwen', code: 7903, city: 'Blicquy' },
  { province: 'Henehouwen', code: 7904, city: 'Willaupuis' },
  { province: 'Henehouwen', code: 7904, city: 'Tourpes' },
  { province: 'Henehouwen', code: 7904, city: 'Pipaix' },
  { province: 'Henehouwen', code: 7906, city: 'Gallaix' },
  { province: 'Henehouwen', code: 7910, city: 'Ellignies-lez-frasnes' },
  { province: 'Henehouwen', code: 7910, city: 'Forest' },
  { province: 'Henehouwen', code: 7910, city: 'Cordes' },
  { province: 'Henehouwen', code: 7910, city: 'Anvaing' },
  { province: 'Henehouwen', code: 7910, city: 'Frasnes-lez-anvaing' },
  { province: 'Henehouwen', code: 7910, city: 'Arc-ainières' },
  { province: 'Henehouwen', code: 7910, city: 'Wattripont' },
  { province: 'Henehouwen', code: 7910, city: 'Arc-wattripont' },
  { province: 'Henehouwen', code: 7911, city: 'Moustier' },
  { province: 'Henehouwen', code: 7911, city: 'Buissenal' },
  { province: 'Henehouwen', code: 7911, city: 'Oeudeghien' },
  { province: 'Henehouwen', code: 7911, city: 'Montroeul-au-bois' },
  { province: 'Henehouwen', code: 7911, city: 'Herquegies' },
  { province: 'Henehouwen', code: 7911, city: 'Frasnes-lez-buissenal' },
  { province: 'Henehouwen', code: 7911, city: 'Hacquegnies' },
  { province: 'Henehouwen', code: 7912, city: 'Dergneau' },
  { province: 'Henehouwen', code: 7912, city: 'Saint-sauveur' },
  { province: 'Henehouwen', code: 7940, city: 'Brugelette' },
  { province: 'Henehouwen', code: 7940, city: 'Cambron-casteau' },
  { province: 'Henehouwen', code: 7941, city: 'Attre' },
  { province: 'Henehouwen', code: 7942, city: 'Mévergnies-lez-lens' },
  { province: 'Henehouwen', code: 7943, city: 'Gages' },
  { province: 'Henehouwen', code: 7950, city: 'Chièvres' },
  { province: 'Henehouwen', code: 7950, city: 'Tongre-saint-martin' },
  { province: 'Henehouwen', code: 7950, city: 'Grosage' },
  { province: 'Henehouwen', code: 7950, city: 'Ladeuze' },
  { province: 'Henehouwen', code: 7950, city: 'Huissignies' },
  { province: 'Henehouwen', code: 7951, city: 'Tongre-notre-dame' },
  { province: 'Henehouwen', code: 7970, city: 'Beloeil' },
  { province: 'Henehouwen', code: 7971, city: 'Thumaide' },
  { province: 'Henehouwen', code: 7971, city: 'Basècles' },
  { province: 'Henehouwen', code: 7971, city: 'Ramegnies' },
  { province: 'Henehouwen', code: 7971, city: 'Wadelincourt' },
  { province: 'Henehouwen', code: 7972, city: 'Ellignies-saint-anne' },
  { province: 'Henehouwen', code: 7972, city: 'Aubechies' },
  { province: 'Henehouwen', code: 7972, city: 'Quevaucamps' },
  { province: 'Henehouwen', code: 7973, city: 'Grandglise' },
  { province: 'Henehouwen', code: 7973, city: 'Stambruges' },


  // West-Vlaanderen
  { province: 'West-Vlaanderen', code: 8000, city: 'Brugge' },
  { province: 'West-Vlaanderen', code: 8000, city: 'Koolkerke' },
  { province: 'West-Vlaanderen', code: 8020, city: 'Hertsberge' },
  { province: 'West-Vlaanderen', code: 8020, city: 'Ruddervoorde' },
  { province: 'West-Vlaanderen', code: 8020, city: 'Waardamme' },
  { province: 'West-Vlaanderen', code: 8020, city: 'Oostkamp' },
  { province: 'West-Vlaanderen', code: 8200, city: 'Sint-michiels' },
  { province: 'West-Vlaanderen', code: 8200, city: 'Sint-andries' },
  { province: 'West-Vlaanderen', code: 8210, city: 'Loppem' },
  { province: 'West-Vlaanderen', code: 8210, city: 'Zedelgem' },
  { province: 'West-Vlaanderen', code: 8210, city: 'Veldegem' },
  { province: 'West-Vlaanderen', code: 8211, city: 'Aartrijke' },
  { province: 'West-Vlaanderen', code: 8300, city: 'Westkapelle' },
  { province: 'West-Vlaanderen', code: 8300, city: 'Knokke' },
  { province: 'West-Vlaanderen', code: 8300, city: 'Knokke-Heist' },
  { province: 'West-Vlaanderen', code: 8301, city: 'Heist-aan-zee' },
  { province: 'West-Vlaanderen', code: 8301, city: 'Ramskapelle' },
  { province: 'West-Vlaanderen', code: 8310, city: 'Sint-kruis' },
  { province: 'West-Vlaanderen', code: 8310, city: 'Assebroek' },
  { province: 'West-Vlaanderen', code: 8340, city: 'Sijsele' },
  { province: 'West-Vlaanderen', code: 8340, city: 'Lapscheure' },
  { province: 'West-Vlaanderen', code: 8340, city: 'Damme' },
  { province: 'West-Vlaanderen', code: 8340, city: 'Moerkerke' },
  { province: 'West-Vlaanderen', code: 8340, city: 'Hoeke' },
  { province: 'West-Vlaanderen', code: 8340, city: 'Oostkerke' },
  { province: 'West-Vlaanderen', code: 8370, city: 'Blankenberge' },
  { province: 'West-Vlaanderen', code: 8370, city: 'Uitkerke' },
  { province: 'West-Vlaanderen', code: 8377, city: 'Zuienkerke' },
  { province: 'West-Vlaanderen', code: 8377, city: 'Nieuwmunster' },
  { province: 'West-Vlaanderen', code: 8377, city: 'Houtave' },
  { province: 'West-Vlaanderen', code: 8377, city: 'Meetkerke' },
  { province: 'West-Vlaanderen', code: 8380, city: 'Lissewege' },
  { province: 'West-Vlaanderen', code: 8380, city: 'Dudzele' },
  { province: 'West-Vlaanderen', code: 8380, city: 'Zeebrugge' },
  { province: 'West-Vlaanderen', code: 8400, city: 'Zandvoorde' },
  { province: 'West-Vlaanderen', code: 8400, city: 'Stene' },
  { province: 'West-Vlaanderen', code: 8400, city: 'Oostende' },
  { province: 'West-Vlaanderen', code: 8420, city: 'Wenduine' },
  { province: 'West-Vlaanderen', code: 8420, city: 'Klemskerke' },
  { province: 'West-Vlaanderen', code: 8420, city: 'De haan' },
  { province: 'West-Vlaanderen', code: 8421, city: 'Vlissegem' },
  { province: 'West-Vlaanderen', code: 8430, city: 'Middelkerke' },
  { province: 'West-Vlaanderen', code: 8431, city: 'Wilskerke' },
  { province: 'West-Vlaanderen', code: 8432, city: 'Leffinge' },
  { province: 'West-Vlaanderen', code: 8433, city: 'Sint-Pieters-Kapelle' },
  { province: 'West-Vlaanderen', code: 8433, city: 'Slijpe' },
  { province: 'West-Vlaanderen', code: 8433, city: 'Mannekensvere' },
  { province: 'West-Vlaanderen', code: 8433, city: 'Schore' },
  { province: 'West-Vlaanderen', code: 8434, city: 'Lombardsijde' },
  { province: 'West-Vlaanderen', code: 8434, city: 'Westende' },
  { province: 'West-Vlaanderen', code: 8450, city: 'Bredene' },
  { province: 'West-Vlaanderen', code: 8460, city: 'Ettelgem' },
  { province: 'West-Vlaanderen', code: 8460, city: 'Roksem' },
  { province: 'West-Vlaanderen', code: 8460, city: 'Westkerke' },
  { province: 'West-Vlaanderen', code: 8460, city: 'Oudenburg' },
  { province: 'West-Vlaanderen', code: 8470, city: 'Zevekote' },
  { province: 'West-Vlaanderen', code: 8470, city: 'Moere' },
  { province: 'West-Vlaanderen', code: 8470, city: 'Snaaskerke' },
  { province: 'West-Vlaanderen', code: 8470, city: 'Gistel' },
  { province: 'West-Vlaanderen', code: 8480, city: 'Ichtegem' },
  { province: 'West-Vlaanderen', code: 8480, city: 'Eernegem' },
  { province: 'West-Vlaanderen', code: 8480, city: 'Bekegem' },
  { province: 'West-Vlaanderen', code: 8490, city: 'Zerkegem' },
  { province: 'West-Vlaanderen', code: 8490, city: 'Snellegem' },
  { province: 'West-Vlaanderen', code: 8490, city: 'Varsenare' },
  { province: 'West-Vlaanderen', code: 8490, city: 'Stalhille' },
  { province: 'West-Vlaanderen', code: 8490, city: 'Jabbeke' },
  { province: 'West-Vlaanderen', code: 8500, city: 'Kortrijk' },
  { province: 'West-Vlaanderen', code: 8501, city: 'Bissegem' },
  { province: 'West-Vlaanderen', code: 8501, city: 'Heule' },
  { province: 'West-Vlaanderen', code: 8510, city: 'Kooigem' },
  { province: 'West-Vlaanderen', code: 8510, city: 'Rollegem' },
  { province: 'West-Vlaanderen', code: 8510, city: 'Marke' },
  { province: 'West-Vlaanderen', code: 8510, city: 'Bellegem' },
  { province: 'West-Vlaanderen', code: 8511, city: 'Aalbeke' },
  { province: 'West-Vlaanderen', code: 8520, city: 'Kuurne' },
  { province: 'West-Vlaanderen', code: 8530, city: 'Harelbeke' },
  { province: 'West-Vlaanderen', code: 8531, city: 'Hulste' },
  { province: 'West-Vlaanderen', code: 8531, city: 'Bavikhove' },
  { province: 'West-Vlaanderen', code: 8540, city: 'Deerlijk' },
  { province: 'West-Vlaanderen', code: 8550, city: 'Zwevegem' },
  { province: 'West-Vlaanderen', code: 8551, city: 'Heestert' },
  { province: 'West-Vlaanderen', code: 8552, city: 'Moen' },
  { province: 'West-Vlaanderen', code: 8553, city: 'Otegem' },
  { province: 'West-Vlaanderen', code: 8554, city: 'Sint-Denijs' },
  { province: 'West-Vlaanderen', code: 8560, city: 'Gullegem' },
  { province: 'West-Vlaanderen', code: 8560, city: 'Wevelgem' },
  { province: 'West-Vlaanderen', code: 8560, city: 'Moorsele' },
  { province: 'West-Vlaanderen', code: 8570, city: 'Vichte' },
  { province: 'West-Vlaanderen', code: 8570, city: 'Anzegem' },
  { province: 'West-Vlaanderen', code: 8570, city: 'Ingooigem' },
  { province: 'West-Vlaanderen', code: 8570, city: 'Gijzelbrechtegem' },
  { province: 'West-Vlaanderen', code: 8572, city: 'Kaster' },
  { province: 'West-Vlaanderen', code: 8573, city: 'Tiegem' },
  { province: 'West-Vlaanderen', code: 8580, city: 'Avelgem' },
  { province: 'West-Vlaanderen', code: 8581, city: 'Kerkhove' },
  { province: 'West-Vlaanderen', code: 8581, city: 'Waarmaarde' },
  { province: 'West-Vlaanderen', code: 8582, city: 'Outrijve' },
  { province: 'West-Vlaanderen', code: 8583, city: 'Bossuit' },
  { province: 'West-Vlaanderen', code: 8587, city: 'Helkijn' },
  { province: 'West-Vlaanderen', code: 8587, city: 'Spiere' },
  { province: 'West-Vlaanderen', code: 8587, city: 'Spiere-Helkijn' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Pervijze' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Lampernisse' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Kaaskerke' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Oostkerke' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Diksmuide' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Esen' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Vladslo' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Stuivekenskerke' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Sint-jacobs-kapelle' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Driekapellen' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Keiem' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Oudekapelle' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Beerst' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Leke' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Woumen' },
  { province: 'West-Vlaanderen', code: 8600, city: 'Nieuwkapelle' },
  { province: 'West-Vlaanderen', code: 8610, city: 'Zarren' },
  { province: 'West-Vlaanderen', code: 8610, city: 'Werken' },
  { province: 'West-Vlaanderen', code: 8610, city: 'Kortemark' },
  { province: 'West-Vlaanderen', code: 8610, city: 'Handzame' },
  { province: 'West-Vlaanderen', code: 8620, city: 'Nieuwpoort' },
  { province: 'West-Vlaanderen', code: 8620, city: 'Sint-joris' },
  { province: 'West-Vlaanderen', code: 8620, city: 'Ramskapelle' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Steenkerke' },
  { province: 'West-Vlaanderen', code: 8630, city: 'De Moeren' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Bulskamp' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Avekapelle' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Wulveringem' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Vinkem' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Houtem' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Eggewaartskapelle' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Booitshoeke' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Zoutenaaie' },
  { province: 'West-Vlaanderen', code: 8630, city: 'Veurne' },
  { province: 'West-Vlaanderen', code: 8640, city: 'Westvleteren' },
  { province: 'West-Vlaanderen', code: 8640, city: 'Woesten' },
  { province: 'West-Vlaanderen', code: 8640, city: 'Oostvleteren' },
  { province: 'West-Vlaanderen', code: 8640, city: 'Vleteren' },
  { province: 'West-Vlaanderen', code: 8647, city: 'Lo' },
  { province: 'West-Vlaanderen', code: 8647, city: 'Noordschote' },
  { province: 'West-Vlaanderen', code: 8647, city: 'Lo-Reninge' },
  { province: 'West-Vlaanderen', code: 8647, city: 'Pollinkhove' },
  { province: 'West-Vlaanderen', code: 8647, city: 'Reninge' },
  { province: 'West-Vlaanderen', code: 8650, city: 'Merkem' },
  { province: 'West-Vlaanderen', code: 8650, city: 'Houthulst' },
  { province: 'West-Vlaanderen', code: 8650, city: 'Klerken' },
  { province: 'West-Vlaanderen', code: 8660, city: 'Adinkerke' },
  { province: 'West-Vlaanderen', code: 8660, city: 'De panne' },
  { province: 'West-Vlaanderen', code: 8670, city: 'Koksijde' },
  { province: 'West-Vlaanderen', code: 8670, city: 'Oostduinkerke' },
  { province: 'West-Vlaanderen', code: 8670, city: 'Wulpen' },
  { province: 'West-Vlaanderen', code: 8680, city: 'Koekelare' },
  { province: 'West-Vlaanderen', code: 8680, city: 'Bovekerke' },
  { province: 'West-Vlaanderen', code: 8680, city: 'Zande' },
  { province: 'West-Vlaanderen', code: 8690, city: 'Hoogstade' },
  { province: 'West-Vlaanderen', code: 8690, city: 'Sint-Rijkers' },
  { province: 'West-Vlaanderen', code: 8690, city: 'Oeren' },
  { province: 'West-Vlaanderen', code: 8690, city: 'Alveringem' },
  { province: 'West-Vlaanderen', code: 8691, city: 'Stavele' },
  { province: 'West-Vlaanderen', code: 8691, city: 'Beveren-aan-de-ijzer' },
  { province: 'West-Vlaanderen', code: 8691, city: 'Izenberge' },
  { province: 'West-Vlaanderen', code: 8691, city: 'Gijverinkhove' },
  { province: 'West-Vlaanderen', code: 8691, city: 'Leisele' },
  { province: 'West-Vlaanderen', code: 8700, city: 'Kanegem' },
  { province: 'West-Vlaanderen', code: 8700, city: 'Tielt' },
  { province: 'West-Vlaanderen', code: 8700, city: 'Aarsele' },
  { province: 'West-Vlaanderen', code: 8700, city: 'Schuiferskapelle' },
  { province: 'West-Vlaanderen', code: 8710, city: 'Wielsbeke' },
  { province: 'West-Vlaanderen', code: 8710, city: 'Ooigem' },
  { province: 'West-Vlaanderen', code: 8710, city: 'Sint-Baafs-Vijve' },
  { province: 'West-Vlaanderen', code: 8720, city: 'Oeselgem' },
  { province: 'West-Vlaanderen', code: 8720, city: 'Markegem' },
  { province: 'West-Vlaanderen', code: 8720, city: 'Wakken' },
  { province: 'West-Vlaanderen', code: 8720, city: 'Dentergem' },
  { province: 'West-Vlaanderen', code: 8730, city: 'Oedelem' },
  { province: 'West-Vlaanderen', code: 8730, city: 'Sint-Joris' },
  { province: 'West-Vlaanderen', code: 8730, city: 'Beernem' },
  { province: 'West-Vlaanderen', code: 8740, city: 'Pittem' },
  { province: 'West-Vlaanderen', code: 8740, city: 'Egem' },
  { province: 'West-Vlaanderen', code: 8750, city: 'Zwevezele' },
  { province: 'West-Vlaanderen', code: 8750, city: 'Wingene' },
  { province: 'West-Vlaanderen', code: 8755, city: 'Ruiselede' },
  { province: 'West-Vlaanderen', code: 8760, city: 'Meulebeke' },
  { province: 'West-Vlaanderen', code: 8770, city: 'Ingelmunster' },
  { province: 'West-Vlaanderen', code: 8780, city: 'Oostrozebeke' },
  { province: 'West-Vlaanderen', code: 8790, city: 'Waregem' },
  { province: 'West-Vlaanderen', code: 8791, city: 'Beveren' },
  { province: 'West-Vlaanderen', code: 8792, city: 'Desselgem' },
  { province: 'West-Vlaanderen', code: 8793, city: 'Sint-Eloois-Vijve' },
  { province: 'West-Vlaanderen', code: 8800, city: 'Beveren' },
  { province: 'West-Vlaanderen', code: 8800, city: 'Roeselare' },
  { province: 'West-Vlaanderen', code: 8800, city: 'Oekene' },
  { province: 'West-Vlaanderen', code: 8800, city: 'Rumbeke' },
  { province: 'West-Vlaanderen', code: 8810, city: 'Lichtervelde' },
  { province: 'West-Vlaanderen', code: 8820, city: 'Torhout' },
  { province: 'West-Vlaanderen', code: 8830, city: 'Gits' },
  { province: 'West-Vlaanderen', code: 8830, city: 'Hooglede' },
  { province: 'West-Vlaanderen', code: 8840, city: 'Oostnieuwkerke' },
  { province: 'West-Vlaanderen', code: 8840, city: 'Staden' },
  { province: 'West-Vlaanderen', code: 8840, city: 'Westrozebeke' },
  { province: 'West-Vlaanderen', code: 8850, city: 'Ardooie' },
  { province: 'West-Vlaanderen', code: 8851, city: 'Koolskamp' },
  { province: 'West-Vlaanderen', code: 8860, city: 'Lendelede' },
  { province: 'West-Vlaanderen', code: 8870, city: 'Emelgem' },
  { province: 'West-Vlaanderen', code: 8870, city: 'Kachtem' },
  { province: 'West-Vlaanderen', code: 8870, city: 'Izegem' },
  { province: 'West-Vlaanderen', code: 8880, city: 'Sint-eloois-winkel' },
  { province: 'West-Vlaanderen', code: 8880, city: 'Ledegem' },
  { province: 'West-Vlaanderen', code: 8880, city: 'Rollegem-kapelle' },
  { province: 'West-Vlaanderen', code: 8890, city: 'Dadizele' },
  { province: 'West-Vlaanderen', code: 8890, city: 'Moorslede' },
  { province: 'West-Vlaanderen', code: 8900, city: 'Dikkebus' },
  { province: 'West-Vlaanderen', code: 8900, city: 'Brielen' },
  { province: 'West-Vlaanderen', code: 8900, city: 'Ieper' },
  { province: 'West-Vlaanderen', code: 8900, city: 'Sint-jan' },
  { province: 'West-Vlaanderen', code: 8902, city: 'Voormezele' },
  { province: 'West-Vlaanderen', code: 8902, city: 'Zillebeke' },
  { province: 'West-Vlaanderen', code: 8902, city: 'Hollebeke' },
  { province: 'West-Vlaanderen', code: 8904, city: 'Boezinge' },
  { province: 'West-Vlaanderen', code: 8904, city: 'Zuidschote' },
  { province: 'West-Vlaanderen', code: 8906, city: 'Elverdinge' },
  { province: 'West-Vlaanderen', code: 8908, city: 'Vlamertinge' },
  { province: 'West-Vlaanderen', code: 8920, city: 'Langemark' },
  { province: 'West-Vlaanderen', code: 8920, city: 'Langemark-Poelkapelle' },
  { province: 'West-Vlaanderen', code: 8920, city: 'Poelkapelle' },
  { province: 'West-Vlaanderen', code: 8920, city: 'Bikschote' },
  { province: 'West-Vlaanderen', code: 8930, city: 'Menen' },
  { province: 'West-Vlaanderen', code: 8930, city: 'Lauwe' },
  { province: 'West-Vlaanderen', code: 8930, city: 'Rekkem' },
  { province: 'West-Vlaanderen', code: 8940, city: 'Geluwe' },
  { province: 'West-Vlaanderen', code: 8940, city: 'Wervik' },
  { province: 'West-Vlaanderen', code: 8950, city: 'Nieuwkerke' },
  { province: 'West-Vlaanderen', code: 8950, city: 'Heuvelland' },
  { province: 'West-Vlaanderen', code: 8951, city: 'Dranouter' },
  { province: 'West-Vlaanderen', code: 8952, city: 'Wulvergem' },
  { province: 'West-Vlaanderen', code: 8953, city: 'Wijtschate' },
  { province: 'West-Vlaanderen', code: 8954, city: 'Westouter' },
  { province: 'West-Vlaanderen', code: 8956, city: 'Kemmel' },
  { province: 'West-Vlaanderen', code: 8957, city: 'Mesen' },
  { province: 'West-Vlaanderen', code: 8958, city: 'Loker' },
  { province: 'West-Vlaanderen', code: 8970, city: 'Reningelst' },
  { province: 'West-Vlaanderen', code: 8970, city: 'Poperinge' },
  { province: 'West-Vlaanderen', code: 8972, city: 'Roesbrugge-haringe' },
  { province: 'West-Vlaanderen', code: 8972, city: 'Proven' },
  { province: 'West-Vlaanderen', code: 8972, city: 'Krombeke' },
  { province: 'West-Vlaanderen', code: 8978, city: 'Watou' },
  { province: 'West-Vlaanderen', code: 8980, city: 'Passendale' },
  { province: 'West-Vlaanderen', code: 8980, city: 'Zonnebeke' },
  { province: 'West-Vlaanderen', code: 8980, city: 'Beselare' },
  { province: 'West-Vlaanderen', code: 8980, city: 'Geluveld' },
  { province: 'West-Vlaanderen', code: 8980, city: 'Zandvoorde' },
















  // Oost-Vlaanderen'},
  { province: 'Oost-Vlaanderen', code: 9000, city: 'Gent' },
  { province: 'Oost-Vlaanderen', code: 9030, city: 'Mariakerke' },
  { province: 'Oost-Vlaanderen', code: 9031, city: 'Drongen' },
  { province: 'Oost-Vlaanderen', code: 9032, city: 'Wondelgem	' },
  { province: 'Oost-Vlaanderen', code: 9040, city: 'Sint-amandsberg' },
  { province: 'Oost-Vlaanderen', code: 9041, city: 'Oostakker' },
  { province: 'Oost-Vlaanderen', code: 9042, city: 'Desteldonk' },
  { province: 'Oost-Vlaanderen', code: 9042, city: 'Mendonk' },
  { province: 'Oost-Vlaanderen', code: 9042, city: 'Sint-kruis-winkel' },
  { province: 'Oost-Vlaanderen', code: 9050, city: 'Ledeberg' },
  { province: 'Oost-Vlaanderen', code: 9050, city: 'Gentbrugge' },
  { province: 'Oost-Vlaanderen', code: 9051, city: 'Afsnee' },
  { province: 'Oost-Vlaanderen', code: 9051, city: 'Sint-denijs-westrem' },
  { province: 'Oost-Vlaanderen', code: 9052, city: 'Zwijnaarde' },
  { province: 'Oost-Vlaanderen', code: 9060, city: 'Zelzate' },
  { province: 'Oost-Vlaanderen', code: 9070, city: 'Destelbergen' },
  { province: 'Oost-Vlaanderen', code: 9070, city: 'Heusden' },
  { province: 'Oost-Vlaanderen', code: 9080, city: 'Zeveneken' },
  { province: 'Oost-Vlaanderen', code: 9080, city: 'Beervelde' },
  { province: 'Oost-Vlaanderen', code: 9080, city: 'Lochristi' },
  { province: 'Oost-Vlaanderen', code: 9080, city: 'Zaffelare' },
  { province: 'Oost-Vlaanderen', code: 9090, city: 'Melle' },
  { province: 'Oost-Vlaanderen', code: 9090, city: 'Gontrode' },
  { province: 'Oost-Vlaanderen', code: 9100, city: 'Sint-Niklaas' },
  { province: 'Oost-Vlaanderen', code: 9100, city: 'Nieuwkerken-Waas' },
  { province: 'Oost-Vlaanderen', code: 9111, city: 'Belsele' },
  { province: 'Oost-Vlaanderen', code: 9112, city: 'Sinaai-Waas' },
  { province: 'Oost-Vlaanderen', code: 9120, city: 'Melsele' },
  { province: 'Oost-Vlaanderen', code: 9120, city: 'Beveren-Waas' },
  { province: 'Oost-Vlaanderen', code: 9120, city: 'Kallo (Beveren-Waas)' },
  { province: 'Oost-Vlaanderen', code: 9120, city: 'Vrasene' },
  { province: 'Oost-Vlaanderen', code: 9120, city: 'Haasdonk' },
  { province: 'Oost-Vlaanderen', code: 9130, city: 'Kieldrecht' },
  { province: 'Oost-Vlaanderen', code: 9130, city: 'Verrebroek' },
  { province: 'Oost-Vlaanderen', code: 9130, city: 'Kallo (Kieldrecht)' },
  { province: 'Oost-Vlaanderen', code: 9130, city: 'Doel' },
  { province: 'Oost-Vlaanderen', code: 9140, city: 'Steendorp' },
  { province: 'Oost-Vlaanderen', code: 9140, city: 'Elversele' },
  { province: 'Oost-Vlaanderen', code: 9140, city: 'Temse' },
  { province: 'Oost-Vlaanderen', code: 9140, city: 'Tielrode' },
  { province: 'Oost-Vlaanderen', code: 9150, city: 'Kruibeke' },
  { province: 'Oost-Vlaanderen', code: 9150, city: 'Bazel' },
  { province: 'Oost-Vlaanderen', code: 9150, city: 'Rupelmonde' },
  { province: 'Oost-Vlaanderen', code: 9160, city: 'Eksaarde' },
  { province: 'Oost-Vlaanderen', code: 9160, city: 'Daknam' },
  { province: 'Oost-Vlaanderen', code: 9160, city: 'Lokeren' },
  { province: 'Oost-Vlaanderen', code: 9170, city: 'Sint-Gillis-Waas' },
  { province: 'Oost-Vlaanderen', code: 9170, city: 'De Klinge' },
  { province: 'Oost-Vlaanderen', code: 9170, city: 'Sint-pauwels' },
  { province: 'Oost-Vlaanderen', code: 9170, city: 'Meerdonk' },
  { province: 'Oost-Vlaanderen', code: 9180, city: 'Moerbeke-waas' },
  { province: 'Oost-Vlaanderen', code: 9185, city: 'Wachtebeke' },
  { province: 'Oost-Vlaanderen', code: 9190, city: 'Stekene' },
  { province: 'Oost-Vlaanderen', code: 9190, city: 'Kemzeke' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Mespelare' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Oudegem' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Baasrode' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Schoonaarde' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Grembergen' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Appels' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Sint-gillis-dendermonde' },
  { province: 'Oost-Vlaanderen', code: 9200, city: 'Dendermonde' },
  { province: 'Oost-Vlaanderen', code: 9220, city: 'Moerzeke' },
  { province: 'Oost-Vlaanderen', code: 9220, city: 'Hamme' },
  { province: 'Oost-Vlaanderen', code: 9230, city: 'Massemen' },
  { province: 'Oost-Vlaanderen', code: 9230, city: 'Wetteren' },
  { province: 'Oost-Vlaanderen', code: 9230, city: 'Westrem' },
  { province: 'Oost-Vlaanderen', code: 9240, city: 'Zele' },
  { province: 'Oost-Vlaanderen', code: 9250, city: 'Waasmunster' },
  { province: 'Oost-Vlaanderen', code: 9255, city: 'Buggenhout' },
  { province: 'Oost-Vlaanderen', code: 9255, city: 'Opdorp' },
  { province: 'Oost-Vlaanderen', code: 9260, city: 'Serskamp' },
  { province: 'Oost-Vlaanderen', code: 9260, city: 'Schellebelle' },
  { province: 'Oost-Vlaanderen', code: 9260, city: 'Wichelen' },
  { province: 'Oost-Vlaanderen', code: 9270, city: 'Kalken' },
  { province: 'Oost-Vlaanderen', code: 9270, city: 'Laarne' },
  { province: 'Oost-Vlaanderen', code: 9280, city: 'Wieze' },
  { province: 'Oost-Vlaanderen', code: 9280, city: 'Denderbelle' },
  { province: 'Oost-Vlaanderen', code: 9280, city: 'Lebbeke' },
  { province: 'Oost-Vlaanderen', code: 9290, city: 'Overmere' },
  { province: 'Oost-Vlaanderen', code: 9290, city: 'Berlare' },
  { province: 'Oost-Vlaanderen', code: 9290, city: 'Uitbergen' },
  { province: 'Oost-Vlaanderen', code: 9300, city: 'Aalst' },
  { province: 'Oost-Vlaanderen', code: 9308, city: 'Gijzegem' },
  { province: 'Oost-Vlaanderen', code: 9308, city: 'Hofstade' },
  { province: 'Oost-Vlaanderen', code: 9310, city: 'Moorsel' },
  { province: 'Oost-Vlaanderen', code: 9310, city: 'Meldert' },
  { province: 'Oost-Vlaanderen', code: 9310, city: 'Baardegem' },
  { province: 'Oost-Vlaanderen', code: 9310, city: 'Herdersem' },
  { province: 'Oost-Vlaanderen', code: 9320, city: 'Nieuwerkerken' },
  { province: 'Oost-Vlaanderen', code: 9320, city: 'Erembodegem' },
  { province: 'Oost-Vlaanderen', code: 9340, city: 'Lede' },
  { province: 'Oost-Vlaanderen', code: 9340, city: 'Oordegem' },
  { province: 'Oost-Vlaanderen', code: 9340, city: 'Impe' },
  { province: 'Oost-Vlaanderen', code: 9340, city: 'Smetlede' },
  { province: 'Oost-Vlaanderen', code: 9340, city: 'Wanzele' },
  { province: 'Oost-Vlaanderen', code: 9400, city: 'Denderwindeke' },
  { province: 'Oost-Vlaanderen', code: 9400, city: 'Ninove' },
  { province: 'Oost-Vlaanderen', code: 9400, city: 'Appelterre-eichem' },
  { province: 'Oost-Vlaanderen', code: 9400, city: 'Nederhasselt' },
  { province: 'Oost-Vlaanderen', code: 9400, city: 'Lieferinge' },
  { province: 'Oost-Vlaanderen', code: 9400, city: 'Okegem' },
  { province: 'Oost-Vlaanderen', code: 9400, city: 'Voorde' },
  { province: 'Oost-Vlaanderen', code: 9401, city: 'Pollare' },
  { province: 'Oost-Vlaanderen', code: 9402, city: 'Meerbeke' },
  { province: 'Oost-Vlaanderen', code: 9403, city: 'Neigem' },
  { province: 'Oost-Vlaanderen', code: 9404, city: 'Aspelare' },
  { province: 'Oost-Vlaanderen', code: 9406, city: 'Outer' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Aaigem' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Burst' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Erondegem' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Bambrugge' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Erpe' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Erpe-mere' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Vlekkem' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Mere' },
  { province: 'Oost-Vlaanderen', code: 9420, city: 'Ottergem' },
  { province: 'Oost-Vlaanderen', code: 9450, city: 'Heldergem' },
  { province: 'Oost-Vlaanderen', code: 9450, city: 'Haaltert' },
  { province: 'Oost-Vlaanderen', code: 9450, city: 'Denderhoutem' },
  { province: 'Oost-Vlaanderen', code: 9451, city: 'Kerksken' },
  { province: 'Oost-Vlaanderen', code: 9470, city: 'Denderleeuw' },
  { province: 'Oost-Vlaanderen', code: 9472, city: 'Iddergem' },
  { province: 'Oost-Vlaanderen', code: 9473, city: 'Welle' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Viane' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Zarlardinge' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Overboelare' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Moerbeke' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Ophasselt' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Nederboelare' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Geraardsbergen' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Goeferdinge' },
  { province: 'Oost-Vlaanderen', code: 9500, city: 'Onkerzele' },
  { province: 'Oost-Vlaanderen', code: 9506, city: 'Idegem' },
  { province: 'Oost-Vlaanderen', code: 9506, city: 'Zandbergen' },
  { province: 'Oost-Vlaanderen', code: 9506, city: 'Schendelbeke' },
  { province: 'Oost-Vlaanderen', code: 9506, city: 'Waarbeke' },
  { province: 'Oost-Vlaanderen', code: 9506, city: 'Smeerebbe-vloerzegem' },
  { province: 'Oost-Vlaanderen', code: 9506, city: 'Grimminge' },
  { province: 'Oost-Vlaanderen', code: 9506, city: 'Nieuwenhove' },
  { province: 'Oost-Vlaanderen', code: 9520, city: 'Sint-Lievens-Houtem' },
  { province: 'Oost-Vlaanderen', code: 9520, city: 'Bavegem' },
  { province: 'Oost-Vlaanderen', code: 9520, city: 'Oombergen		' },
  { province: 'Oost-Vlaanderen', code: 9520, city: 'Vlierzele' },
  { province: 'Oost-Vlaanderen', code: 9520, city: 'Zonnegem' },
  { province: 'Oost-Vlaanderen', code: 9521, city: 'Letterhoutem' },
  { province: 'Oost-Vlaanderen', code: 9550, city: 'Herzele' },
  { province: 'Oost-Vlaanderen', code: 9550, city: 'Steenhuize-wijnhuize' },
  { province: 'Oost-Vlaanderen', code: 9550, city: 'Hillegem' },
  { province: 'Oost-Vlaanderen', code: 9550, city: 'Sint-antelinks' },
  { province: 'Oost-Vlaanderen', code: 9550, city: 'Woubrechtegem' },
  { province: 'Oost-Vlaanderen', code: 9550, city: 'Sint-lievens-esse' },
  { province: 'Oost-Vlaanderen', code: 9551, city: 'Ressegem' },
  { province: 'Oost-Vlaanderen', code: 9552, city: 'Borsbeke' },
  { province: 'Oost-Vlaanderen', code: 9570, city: 'Sint-maria-lierde' },
  { province: 'Oost-Vlaanderen', code: 9570, city: 'Deftinge' },
  { province: 'Oost-Vlaanderen', code: 9570, city: 'Lierde' },
  { province: 'Oost-Vlaanderen', code: 9571, city: 'Hemelveerdegem' },
  { province: 'Oost-Vlaanderen', code: 9572, city: 'Sint-martens-lierde' },
  { province: 'Oost-Vlaanderen', code: 9600, city: 'Ronse' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Sint-Maria-Oudenhove' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Godveerdegem' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Sint-goriks-oudenhove' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Velzeke-Ruddershove' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Zottegem' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Leeuwergem' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Erwetegem' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Grotenberge' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Oombergen' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Strijpen' },
  { province: 'Oost-Vlaanderen', code: 9620, city: 'Elene' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Beerlegem' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Sint-Maria-Latem' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Munkzwalm' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Meilegem' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Hundelgem' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Sint-Blasius-Boekel' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Sint-Denijs-Boekel' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Dikkele' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Roborst' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Zwalm' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Rozebeke' },
  { province: 'Oost-Vlaanderen', code: 9630, city: 'Paulatem' },
  { province: 'Oost-Vlaanderen', code: 9636, city: 'Nederzwalm-Hermelgem' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Everbeek' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Nederbrakel' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Michelbeke' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Zegelsem' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Sint-maria-oudenhove' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Opbrakel' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Elst' },
  { province: 'Oost-Vlaanderen', code: 9660, city: 'Brakel' },
  { province: 'Oost-Vlaanderen', code: 9661, city: 'Parike' },
  { province: 'Oost-Vlaanderen', code: 9667, city: 'Sint-Maria-Horebeke' },
  { province: 'Oost-Vlaanderen', code: 9667, city: 'Sint-Kornelis-Horebeke' },
  { province: 'Oost-Vlaanderen', code: 9667, city: 'Horebeke' },
  { province: 'Oost-Vlaanderen', code: 9680, city: 'Etikhove' },
  { province: 'Oost-Vlaanderen', code: 9680, city: 'Maarke-kerkem' },
  { province: 'Oost-Vlaanderen', code: 9680, city: 'Maarkedal	' },
  { province: 'Oost-Vlaanderen', code: 9681, city: 'Nukerke' },
  { province: 'Oost-Vlaanderen', code: 9688, city: 'Schorisse' },
  { province: 'Oost-Vlaanderen', code: 9690, city: 'Berchem' },
  { province: 'Oost-Vlaanderen', code: 9690, city: 'Kwaremont' },
  { province: 'Oost-Vlaanderen', code: 9690, city: 'Ruien' },
  { province: 'Oost-Vlaanderen', code: 9690, city: 'Zulzeke' },
  { province: 'Oost-Vlaanderen', code: 9690, city: 'Kluisbergen' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Eine' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Welden' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Oudenaarde' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Bevere' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Ename' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Melden' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Heurne' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Nederename' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Ooike' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Leupegem' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Edelare' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Mater' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Mullem' },
  { province: 'Oost-Vlaanderen', code: 9700, city: 'Volkegem' },
  { province: 'Oost-Vlaanderen', code: 9750, city: 'Zingem' },
  { province: 'Oost-Vlaanderen', code: 9750, city: 'Huise' },
  { province: 'Oost-Vlaanderen', code: 9750, city: 'Ouwegem' },
  { province: 'Oost-Vlaanderen', code: 9770, city: 'Kruishoutem' },
  { province: 'Oost-Vlaanderen', code: 9771, city: 'Nokere' },
  { province: 'Oost-Vlaanderen', code: 9772, city: 'Wannegem-lede' },
  { province: 'Oost-Vlaanderen', code: 9790, city: 'Ooike' },
  { province: 'Oost-Vlaanderen', code: 9790, city: 'Wortegem-Petegem' },
  { province: 'Oost-Vlaanderen', code: 9790, city: 'Wortegem' },
  { province: 'Oost-Vlaanderen', code: 9790, city: 'Elsegem' },
  { province: 'Oost-Vlaanderen', code: 9790, city: 'Petegem-aan-de-schelde' },
  { province: 'Oost-Vlaanderen', code: 9790, city: 'Moregem' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Bachte-maria-leerne' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Gottem' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Zeveren' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Grammene' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Vinkt' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Sint-martens-leerne' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Deinze' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Petegem-aan-de-leie' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Wontergem' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Astene' },
  { province: 'Oost-Vlaanderen', code: 9800, city: 'Meigem' },
  { province: 'Oost-Vlaanderen', code: 9810, city: 'Eke' },
  { province: 'Oost-Vlaanderen', code: 9810, city: 'Nazareth' },
  { province: 'Oost-Vlaanderen', code: 9820, city: 'Lemberge' },
  { province: 'Oost-Vlaanderen', code: 9820, city: 'Melsen' },
  { province: 'Oost-Vlaanderen', code: 9820, city: 'Merelbeke' },
  { province: 'Oost-Vlaanderen', code: 9820, city: 'Bottelare' },
  { province: 'Oost-Vlaanderen', code: 9820, city: 'Munte' },
  { province: 'Oost-Vlaanderen', code: 9820, city: 'Schelderode' },
  { province: 'Oost-Vlaanderen', code: 9830, city: 'Sint-martens-latem' },
  { province: 'Oost-Vlaanderen', code: 9831, city: 'Deurle' },
  { province: 'Oost-Vlaanderen', code: 9840, city: 'De pinte' },
  { province: 'Oost-Vlaanderen', code: 9840, city: 'Zevergem' },
  { province: 'Oost-Vlaanderen', code: 9850, city: 'Hansbeke' },
  { province: 'Oost-Vlaanderen', code: 9850, city: 'Landegem' },
  { province: 'Oost-Vlaanderen', code: 9850, city: 'Nevele' },
  { province: 'Oost-Vlaanderen', code: 9850, city: 'Merendree' },
  { province: 'Oost-Vlaanderen', code: 9850, city: 'Poesele' },
  { province: 'Oost-Vlaanderen', code: 9850, city: 'Vosselare' },
  { province: 'Oost-Vlaanderen', code: 9860, city: 'Scheldewindeke' },
  { province: 'Oost-Vlaanderen', code: 9860, city: 'Oosterzele' },
  { province: 'Oost-Vlaanderen', code: 9860, city: 'Balegem' },
  { province: 'Oost-Vlaanderen', code: 9860, city: 'Moortsele' },
  { province: 'Oost-Vlaanderen', code: 9860, city: 'Gijzenzele' },
  { province: 'Oost-Vlaanderen', code: 9860, city: 'Landskouter' },
  { province: 'Oost-Vlaanderen', code: 9870, city: 'Zulte' },
  { province: 'Oost-Vlaanderen', code: 9870, city: 'Machelen' },
  { province: 'Oost-Vlaanderen', code: 9870, city: 'Olsene' },
  { province: 'Oost-Vlaanderen', code: 9880, city: 'Lotenhulle' },
  { province: 'Oost-Vlaanderen', code: 9880, city: 'Poeke' },
  { province: 'Oost-Vlaanderen', code: 9880, city: 'Aalter' },
  { province: 'Oost-Vlaanderen', code: 9881, city: 'Bellem' },
  { province: 'Oost-Vlaanderen', code: 9890, city: 'Baaigem' },
  { province: 'Oost-Vlaanderen', code: 9890, city: 'Vurste' },
  { province: 'Oost-Vlaanderen', code: 9890, city: 'Dikkelvenne' },
  { province: 'Oost-Vlaanderen', code: 9890, city: 'Semmerzake' },
  { province: 'Oost-Vlaanderen', code: 9890, city: 'Gavere' },
  { province: 'Oost-Vlaanderen', code: 9890, city: 'Asper' },
  { province: 'Oost-Vlaanderen', code: 9900, city: 'Eeklo' },
  { province: 'Oost-Vlaanderen', code: 9910, city: 'Ursel' },
  { province: 'Oost-Vlaanderen', code: 9910, city: 'Knesselare' },
  { province: 'Oost-Vlaanderen', code: 9920, city: 'Lovendegem' },
  { province: 'Oost-Vlaanderen', code: 9921, city: 'Vinderhoute' },
  { province: 'Oost-Vlaanderen', code: 9930, city: 'Zomergem' },
  { province: 'Oost-Vlaanderen', code: 9931, city: 'Oostwinkel' },
  { province: 'Oost-Vlaanderen', code: 9932, city: 'Ronsele' },
  { province: 'Oost-Vlaanderen', code: 9940, city: 'Kluizen' },
  { province: 'Oost-Vlaanderen', code: 9940, city: 'Ertvelde' },
  { province: 'Oost-Vlaanderen', code: 9940, city: 'Evergem' },
  { province: 'Oost-Vlaanderen', code: 9940, city: 'Sleidinge' },
  { province: 'Oost-Vlaanderen', code: 9950, city: 'Waarschoot' },
  { province: 'Oost-Vlaanderen', code: 9960, city: 'Assenede' },
  { province: 'Oost-Vlaanderen', code: 9961, city: 'Boekhoute' },
  { province: 'Oost-Vlaanderen', code: 9968, city: 'Oosteeklo' },
  { province: 'Oost-Vlaanderen', code: 9968, city: 'Bassevelde' },
  { province: 'Oost-Vlaanderen', code: 9970, city: 'Kaprijke' },
  { province: 'Oost-Vlaanderen', code: 9971, city: 'Lembeke' },
  { province: 'Oost-Vlaanderen', code: 9980, city: 'Sint-Laureins' },
  { province: 'Oost-Vlaanderen', code: 9981, city: 'Sint-Margriete' },
  { province: 'Oost-Vlaanderen', code: 9982, city: 'Sint-Jan-In-Eremo' },
  { province: 'Oost-Vlaanderen', code: 9988, city: 'Waterland-Oudeman' },
  { province: 'Oost-Vlaanderen', code: 9988, city: 'Watervliet' },
  { province: 'Oost-Vlaanderen', code: 9990, city: 'Maldegem' },
  { province: 'Oost-Vlaanderen', code: 9991, city: 'Adegem' },
  { province: 'Oost-Vlaanderen', code: 9992, city: 'Middelburg' },

];


export default postalCodesBe;