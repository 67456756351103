import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Dashboard from '../../components/dashboard/Dashboard';
import Form from './Form';
import Api from '../../utils/Api';
import { authContext } from '../../context/AuthContext';
import { useToasts } from 'react-toast-notifications';


function Update() {
  const { id } = useParams();

  const [ error, setError ] = useState('');
  const [ site, setSite ] = useState({});
  
  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const { addToast } = useToasts();
  const history = useHistory();

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  useEffect(() => {
    fetchSites();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchSites = () => {
    setError('');

    Api.get(`/sites/${id}`, { headers })
      .then(res => {
        setSite(res.data);
        console.log(res.data);
      })
      .catch(err => setError(err.response))
  }

  const onSubmit = (data) => {
    Api.put(`/sites/${id}`, data, { headers })
      .then(res => {
        console.log(res.data);
        addToast('Werf aangepast.', { appearance: 'success' });
        history.push('/sites');
      })
      .catch(err => {
        console.log(err.response);
        addToast('Werf updaten mislukt.', { appearance: 'error' });
      })
  }

  const onDelete = (id) => {
    Api.delete(`/sites/${id}`, { headers })
      .then(res => {
        console.log(res.data);
        addToast('Werf verwijderd.', { appearance: 'success' });
        history.push('/sites');
      })
      .catch(err => {
        console.log(err.response);
        addToast('Werf verwijderen mislukt.', { appearance: 'error' });
      })
  }

  return (
    <Dashboard>
      <h1>Site {id}</h1>

      <Form 
        onSubmit={onSubmit}
        onDelete={onDelete}
        site={site}
        error={error}
      />

    </Dashboard>
  )
}

export default Update
