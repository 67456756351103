import React, { useContext } from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import { authContext } from '../../context/AuthContext';
import ExternalProvider from './ExternalProvider';

function Index() {
  const { auth } = useContext(authContext);

  return (
    <Dashboard>
      <h1>Profile</h1>
      <b>my profile</b>
      <div className="card mb-3">
        <div className="card-body">
        <dl className="row">
          <dt className="col-sm-3">name</dt>
          <dd className="col-sm-9">{auth.data.user.name}</dd>

          <dt className="col-sm-3">email</dt>
          <dd className="col-sm-9">
            <p>{auth.data.user.email}</p>
          </dd>
        </dl>
        </div>
      </div>

      <b>External providers</b>
      <div className="card mb-3">
        <div className="card-body">
          <div>
            <dl className="row">
              <ExternalProvider 
                name="Teamleader"
                endpoint="teamleader/me"
                reconnectLink="oauth/teamleader"
              />

              <ExternalProvider 
                name="Microsoft"
                endpoint="azure/me"
                reconnectLink="oauth/microsoft"
              />
            </dl>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Index
