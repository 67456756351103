import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill';

const ModalSendReminder = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [recipient, setRecipient] = useState(props.sugestedRecipient ?? '');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const [sendByLetter, setByLetter] = useState(false);

  const [activeInvoice, setActiveInvoice] = useState({});

  const defaultBody = `<p>Geachte,</p>
  <p>Uit onze administratie blijkt dat de bijgevoegde factuur nog niet door u is voldaan. We willen u daarom vriendelijk verzoeken om binnen de 5 dagen het openstaande bedrag over te maken.</p>
  <p>Indien uw betaling deze email heeft gekruist, kunt u deze betalingsherinnering als niet verzonden beschouwen.</p>
  <p>Met vriendelijke groet,<br>
  Zaniboo BV</p>`

  useImperativeHandle(ref, () => ({
    showModal(inv) {
      setActiveInvoice(inv);
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //initialize modal
  useEffect(() => {
    initializeState();
  }, [activeInvoice]); //eslint-disable-line react-hooks/exhaustive-deps

  const initializeState = () => {
    setBody(defaultBody);
    setSubject(`Betaalherinnering - Factuur ${activeInvoice?.tl_data?.invoice_number}`)

    if(activeInvoice?.workorder?.invoice_email){
      setRecipient(activeInvoice?.workorder?.invoice_email ?? '');
    }else if(activeInvoice?.company?.emails && activeInvoice?.company?.emails[0]?.email){
      setRecipient(activeInvoice?.company?.emails[0]?.email ?? '');
    }else{
      setRecipient('');
    }

    setByLetter(false);
  }

  const onSubmit = () => {
    const type = sendByLetter ? 'manual' : 'mail';
    const tl_invoice_id = activeInvoice.tl_invoice_id ?? null;

    const data = {
      tl_invoice_id: tl_invoice_id,
      type: type,
      recipient: recipient,
      subject: subject,
      body: body,
    };

    props.onSendMail(data);
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Verstuur betalingsherinnering</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5>Factuur</h5>
          <dl className="row">
            <dt className="col-sm-4">nummer</dt>
            <dd className="col-sm-8">{ activeInvoice?.tl_data?.invoice_number }</dd>

            <dt className="col-sm-4">verzonden op</dt>
            <dd className="col-sm-8">{ activeInvoice?.sent_invoice_at }</dd>

            <dt className="col-sm-4">vervaldag</dt>
            <dd className="col-sm-8">{ activeInvoice?.tl_data?.due_on }</dd>

            <dt className="col-sm-4">totaalbedrag</dt>
            <dd className="col-sm-8">
              <span>{ activeInvoice?.tl_data?.total?.payable?.amount }</span>
              { activeInvoice?.tl_data?.total?.payable?.currency ? 
                <span> { activeInvoice?.tl_data?.total?.payable?.currency }</span>
              : null }
            </dd>

            <dt className="col-sm-4">openstaand</dt>
            <dd className="col-sm-8">
              <span>{ activeInvoice?.tl_data?.total?.due?.amount }</span>
              { activeInvoice?.tl_data?.total?.due?.currency ? 
                <span> { activeInvoice?.tl_data?.total?.due?.currency }</span>
              : null }
            </dd>

            <dt className="col-sm-4">betaalref</dt>
            <dd className="col-sm-8">{ activeInvoice?.tl_data?.payment_reference }</dd>
          </dl>

          <h5>Werkbon</h5>
          { activeInvoice?.workorder ? 
            <dl className="row">
              <dt className="col-sm-4">ref</dt>
              <dd className="col-sm-8">{ activeInvoice?.workorder?.internal_ref ?? 'onbekend' }</dd>

              <dt className="col-sm-4">telefoon</dt>
              <dd className="col-sm-8">{ activeInvoice?.workorder?.site_contact_phone ?? 'onbekend' }</dd>

              <dt className="col-sm-4">email</dt>
              <dd className="col-sm-8">{ activeInvoice?.workorder?.site_contact_email ?? 'onbekend' }</dd>
  
              <dt className="col-sm-4">werf</dt>
              { activeInvoice?.workorder?.site_line_1 || activeInvoice?.workorder?.site_city ?
                <dd className="col-sm-8">
                  <span>{ activeInvoice?.workorder?.site_line_1 ?? '' }</span>
                  <span> </span>
                  <span>{ activeInvoice?.workorder?.site_city ?? '' }</span>
                  <span><br/></span>
                  <span>{ activeInvoice?.workorder?.site_postal_code ?? '' }</span>
                  <span> </span>
                  <span>{ activeInvoice?.workorder?.site_country ?? '' }</span>
                </dd>
              :
                <dd className="col-sm-8">onbekend</dd>
              }
            </dl>
          :
            <div className="alert alert-danger" role="alert">
              <span>Er werd voor deze factuur geen werkbon gevonden in de wbapp database. Mogelijks werdt de werkbon verwijderd. Deze Betaalherinnering kan nog steeds verstuurd worden mits het manueel invullen van de begunstigde.</span>
            </div>
          }

          <h5>Klant</h5>
          <dl className="row">
            <dt className="col-sm-4">naam</dt>
            <dd className="col-sm-8">{ activeInvoice?.tl_data?.invoicee?.name }</dd>

            { activeInvoice?.company?.telephones ? 
              <>
                <dt className="col-sm-4">telefoon</dt>
                <dd className="col-sm-8">
                  { activeInvoice?.company?.telephones && activeInvoice?.company?.telephones.map(tel => 
                    <p className="mb-0">{tel.number}</p>
                  )}
                  { activeInvoice?.company?.telephones.length <= 0 ? 
                    <p className="mb-0">onbekend</p>
                  : null }
                </dd>
              </>
            : null }

            { activeInvoice?.company?.emails ? 
              <>
                <dt className="col-sm-4">email</dt>
                <dd className="col-sm-8">
                  { activeInvoice?.company?.emails && activeInvoice?.company?.emails.map(email => 
                    <p className="mb-0">{email.email}</p>
                  )}
                  { activeInvoice?.company?.emails.length <= 0 ? 
                    <p className="mb-0">onbekend</p>
                  : null }
                </dd>
              </>
            : null }
          </dl>
        </div>


        <h5>Betaalherinnering</h5>

        <div className="form-group">
          <label htmlFor="FrmSendByLetter1">versturen</label>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="sendByLetter" 
              id="FrmSendByLetter1" 
              checked={!sendByLetter}
              onClick={() => setByLetter(false)}
            />
            <label className="form-check-label" htmlFor="FrmSendByLetter1">
              via email
            </label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="sendByLetter" 
              id="FrmSendByLetter2" 
              // value="option2"
              checked={sendByLetter}
              onClick={() => setByLetter(true)}
            />
            <label className="form-check-label" htmlFor="FrmSendByLetter2">
              via post
            </label>
          </div>
        </div>


        {sendByLetter ?
          <div>
            <p>Download de factuur en verstuur deze manueel.</p>
          </div>
        :
          <>
          <div className="form-group">
            <label htmlFor="frmRecipient">email begunstigde</label>
            <input 
              type="email"
              id="frmRecipient" 
              className="form-control"
              name="recipient" 
              value={recipient}
              onChange={(e) => setRecipient(e.target.value)}
            />
            { (props.sugestedRecipient && recipient !== props.sugestedRecipient) &&
              <small id="recipientHelp" className="form-text text-muted">voorgesteld: {props.sugestedRecipient}</small>
            }
          </div>
          <div className="form-group">
            <label htmlFor="frmSubject">onderwerp</label>
            <input 
              type="text"
              id="frmSubject" 
              className="form-control"
              name="subject" 
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <label>Bijlagen</label>
            </div>

            <div className="col-6">
              <div className="alert alert-primary" role="alert">
                <span>{`factuur-${activeInvoice?.tl_data?.invoice_number}`}</span> 
              </div>
            </div>
            
          </div>
          <div className="form-group">
            <label htmlFor="frmBody">Bericht</label>
            <ReactQuill 
              theme="snow"
              onChange={html => setBody(html)}
              value={body}
              modules={ModalSendReminder.modules}
              formats={ModalSendReminder.formats}
              bounds={'.app'}
              placeholder="placeholder"
            />
          </div>
          </>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Versturen
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

ModalSendReminder.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}

ModalSendReminder.formats = [
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'background', 'color'
]

export default ModalSendReminder