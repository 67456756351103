import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';
import DynamicSelect from '../../components/form/DynamicSelect';

const ModalAddToWorkorder = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [workorders, setWorkorders] = useState([]);
  const [loadedWorkorders, setLoadedWorkorders] = useState(false);
  const [workorder, setWorkorder] = useState({});

  const { auth } = useContext(authContext);
  
  useEffect(() => {
    fetchWorkorders();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchWorkorders = () => {
    setWorkorder({});
    setLoadedWorkorders(false);

    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    const params = {
      'filter': {
        // 'status' : 'draft_or_less'
        'status' : 'all'
      }
    };


    Api.get('/workorders', { headers, params })
      .then(res => setWorkorders(res.data))
      .catch(err => console.log(err))
      .finally(() => setLoadedWorkorders(true));
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onUpdateMail = () => {
    props.updateMailWorkorder(workorder.id ?? null);
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Naar bestaande opdrachtbon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Kies de opdrachtbon in onderstaande lijst:</span>
        <div className="form-group">
          <label htmlFor="frmCliendId">OpdrachtbonId</label>
          <DynamicSelect 
            id="frmWorkorderId"
            data={workorders}
            dataLoaded={loadedWorkorders}
            value={workorder}
            valueField="id"
            displayField="internal_ref"
            additionalField="site_city"
            additionalFieldBrackets={true}
            updateValue={(value) => setWorkorder(value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="primary" onClick={onUpdateMail}>
          Opslaan
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

export default ModalAddToWorkorder
