import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authContext } from '../context/AuthContext';

function ProtectedRoute ({ component: Component, ...rest }){
  const { auth } = useContext(authContext);
  const { loading } = auth;

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p>Loading...</p>;
        }}
      />
    );
  }

  return (
    <Route {...rest} render={(props) => (
      auth.data !== null
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )
}

export default ProtectedRoute;
