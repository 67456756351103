import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { authContext } from '../../context/AuthContext';

function AppHeader() {
  const { auth, setAuthData } = useContext(authContext);
  const [ profileDropdown, setProfileDropdown ] = useState(false); 

  const toggleProfileDropdown = () => {
    setProfileDropdown(!profileDropdown);
  }

  const onLogout = () => {
    setAuthData(null)
  }

  return (
    <nav className="navbar navbar-light bg-light w-100">
      <div></div>
      <div className="dropdown">
        <button className="btn btn-link dropdown-toggle" type="button" onClick={toggleProfileDropdown}>
          <span>{ auth.data.user.name }</span>
        </button>
        <div className={`dropdown-menu dropdown-menu-right ${profileDropdown && " show"}`}>
          <Link to="/profile" className="dropdown-item" type="button">My profile</Link>
          <button className="dropdown-item" type="button" onClick={onLogout}>Logout</button>
        </div>
      </div>
      {/* <div className="d-flex align-items-center">
        <span className="mr-3">Welkom, { auth.data.user.name } </span>
        <Logout />
        <div>
          <img src="/user.png" alt="profile" className="img-fluid" style={{ height: '38px' }} />
        </div>
      </div> */}
    </nav>
  )
}

export default AppHeader
