import React, { useState, useEffect, useContext } from 'react';
import { Tab, Nav, Dropdown } from 'react-bootstrap';
import EmptyAlert from '../../components/alert/EmptyAlert';
import { useToasts } from 'react-toast-notifications';
import { authContext } from '../../context/AuthContext';
import { WorkorderContext } from '../../context/WorkorderContext';
import Api from '../../utils/Api';

function Siteorders(props) {
  const [ selectedSiteorderId, setSelectedSiteorderId ] = useState(null);
  const { auth } = useContext(authContext);
  const { refetchWorkorder } = useContext(WorkorderContext);
  const { addToast } = useToasts();

  //set selected siteorder to first siteorder id
  useEffect(() => {
    if(props.siteorders && props.siteorders?.length > 1){
      setSelectedSiteorderId(props.siteorders[0].id);  
    }
  }, [props.siteorders]);

  //delete siteorder
  const onDeleteSiteorder = (id) => {
    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.delete(`siteorders/${id}`, { headers })
      .then(res => {
        addToast('Werfbon verwijderd.', { appearance: 'success' })
        refetchWorkorder();
      })
      .catch(err => addToast('Werfbon verwijderen mislukt.', { appearance: 'error' }));
  }

  //no siteorder
  if(!props.siteorders || props.siteorders?.length === 0){
    return(
      <EmptyAlert
        text="Nog geen werfbon"
      />
    )
  }

  //single siteorder
  if(props.siteorders?.length === 1){
    return (
      <div className="d-flex flex-column h-100">
        <embed 
          src={`${process.env.REACT_APP_API_SERVER}storage/${props.siteorders[0].path}`} 
          className="flex-grow-1"
          style={{width: '100%', minHeight: '300px'}}
        ></embed>
      </div>
    )
  }
  
  //multiple siteorders
  return (
    <div className="d-flex flex-column h-100">
      <Tab.Container 
        id="siteorder-tabs" 
        defaultActiveKey={props.siteorders[0].id ?? null} 
        activeKey={selectedSiteorderId}
        onSelect={(id) => setSelectedSiteorderId(id)}
        transition={false}
      >
        <div className="d-flex justify-content-between">
          <Nav variant="tabs" className="border-0">
            { props.siteorders.map((siteorder, i) => 
              <Nav.Item 
                key={siteorder.id}
              >
                <Nav.Link 
                  eventKey={siteorder.id}
                >Werfbon {i+1}</Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              Opties
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item 
                as="button"
                onClick={() => onDeleteSiteorder(selectedSiteorderId)}
              >Werfbon verwijderen</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
           
        <Tab.Content className="h-100">
          { props.siteorders.map((siteorder, i) => 
            <Tab.Pane 
              key={siteorder.id}
              eventKey={siteorder.id} 
              className="h-100"
            >
              <embed 
                src={`${process.env.REACT_APP_API_SERVER}storage/${siteorder.path}`} 
                className="flex-grow-1 h-100"
                style={{width: '100%', minHeight: '300px'}}
              ></embed>
            </Tab.Pane>
          )}
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

export default Siteorders
