import React from 'react';
import { Letter } from 'react-letter';

function MailBody(props) {
  if (!props.body){
    return (
      <p className="text-muted py-4">Dit bericht bevat geen tekst.</p>
    )
  }

  return (
    <div className="d-flex py-2 flex-column h-100">
      { props.body.substring(0,5).toLowerCase() === '<html' ?
        <Letter html={props.body} className="email-body" />
      :
        <Letter text={props.body} className="email-body" />
      }
    </div>
  )
}

export default MailBody
