import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';
import Table from '../../components/table';
import Pagination from '../../components/table/Pagination';

//table formatters
const LogoFormatter = ({value, row}) => {
  return (
    <div>
      { row.logo 
      ? <img 
          className="img-fluid" 
          style={{width: 'auto', height: '50px'}} 
          src={`${process.env.REACT_APP_API_SERVER}storage/${row.logo}`} 
          alt="company-logo"
        />
      : <span>-</span> }
    </div>
  )
}

const AddressFormatter = ({value, row}) => {
  return (
    <div>
      <div>{row?.address?.line_1}</div>
      <div>{row?.address?.postal_code} {row?.address?.city}</div>
    </div>
  )
}

const EmailsFormatter = ({value, row}) => {
  if(row.emails && row.emails.length > 0){
    return (
      <div>
        { row.emails.map(email => 
          <div className="text-nowrap">{email.email}</div>
        )}
      </div>
    )
  }

  return (<div>-</div>); 
}

const PhonesFormatter = ({value, row}) => {
  if(row.telephones && row.telephones.length > 0){
    return (
      <div>
        { row.telephones.map(telephone => 
          <div className="text-nowrap">{telephone.number}</div>
        )}
      </div>
    )
  }

  return (<div>-</div>); 
}


function List() {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState('');
  const { auth } = useContext(authContext);

  const PAGECOUNT = 20;

  const columns = [
    { 
      key: 'logo', 
      name: 'logo', 
      sortable: false, 
      formatter: LogoFormatter 
    },
    { 
      key: 'name', 
      name: 'bedrijfsnaam', 
      sortable: false, 
    },
    { 
      name: 'adres', 
      sortable: false, 
      formatter: AddressFormatter 
    },
    { 
      name: 'email', 
      sortable: false, 
      formatter: EmailsFormatter 
    },
    { 
      name: 'telefoon', 
      sortable: false, 
      formatter: PhonesFormatter 
    },
  ]
 
  useEffect(() => {
    fetchCompanies();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(page < 1){
      setPage(1);
    }

    fetchCompanies(page)
  }, [page])  //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //debounce
    const timer = setTimeout(() => {
      fetchCompanies();
    }, 350);

    return () => clearTimeout(timer);
  }, [filterTerm]) //eslint-disable-line react-hooks/exhaustive-deps

  const fetchCompanies = (page) => {
    setError('');
    setLoading(true);

    const token = auth.data.access_token;

    const params = {
      pageCount: PAGECOUNT,
      page: page
    }

    if(filterTerm && filterTerm !== ''){
      params['term'] = filterTerm;
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get("/companies", { headers, params })
      .then(res => {
        setCompanies(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response);
        setLoading(false);
      });  
  }

  return (
    <div className="h-100 d-flex flex-column">
      {/* toolbar */}
      <div className="row mb-3 mt-1">
        <div className="col">
          <div className="d-flex justify-items-start align-items-center">
            <span>Filter:</span>
            <div className="ml-2">
              <form className="form-inline">
                <div className="input-group">
                  <input 
                    type="text" 
                    value={filterTerm}
                    className="form-control form-control-sm" 
                    placeholder="Zoeken.." 
                    onChange={(e) => setFilterTerm(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      <Table 
        columns={columns} 
        data={companies}
        //sortBy={sortBy}
        //sortAsc={sortAsc}
        //setSortBy={setSortBy}
        loading={loading}
        invertSortDir={() => {}}
        onRowClick={(row) => {}}
      />
      
      {/* pagination */}
      <div className="d-flex justify-content-end">
        <Pagination 
          data={companies}
          loading={loading}
          page={page}
          pageCount={PAGECOUNT}
          setPage={setPage}
        />
      </div>

      {/* error */}
      { error.status && 
        <div className="p-3 mb-2 bg-danger text-white">
          <p>There was an error loading the resource</p>
          { error.status === 502 && <p>Please check the provider config <Link to="/profile">here</Link></p> }
        </div>
      }
    </div>
  )
}

export default List
