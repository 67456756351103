import React from 'react';
import MailCard from '../../components/mail/MailCard';
import EmptyAlert from '../../components/alert/EmptyAlert';

function Mails(props) {
  if(!props.mails || props.mails?.length === 0){
    return (
      <EmptyAlert
        text="Nog geen emails gelinkt"
      />
    )
  }

  return (
    <div>
      { props.mails.map(mail => 
        <MailCard 
          key={mail.id} 
          mailId={mail.id} 
          refetchWorkorder={props.refetchWorkorder}
        /> 
      )}
    </div>
  )
}

export default Mails
