import React, { useContext } from 'react';
import Api from '../../utils/Api';
import { authContext } from '../../context/AuthContext';
import { useToasts } from 'react-toast-notifications';

function Approvement(props) {
  const { addToast } = useToasts();
  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  const onDeleteApproval = () => {
    Api.delete(`approvals/${props.approval.id}`, { headers })
      .then(res => {
        addToast('Goedkeuring verwijderd van werkbon.', { appearance: 'success' });
        props.refetchWorkorder();
      })
      .catch(err => addToast('Goedkeuring verwijderen van werkbon mislukt.', { appearance: 'error' }));
  }

  if (!props.approval){
    if(props.needsApproval !== null && props.needsApproval === 0){
      return(
        <div className="alert alert-success" role="alert">
          <span>Geen goedkeuring vereist</span> 
        </div>
      )
    }

    return (
      <div className="alert alert-danger" role="alert">
          <span>Nog geen goedkeuring</span> 
      </div>
    )
  }

  return (
    <div className="d-flex flex-column h-100">
      <embed
        src={`${process.env.REACT_APP_API_SERVER}/storage/${props.approval.path}`}  
        className="flex-grow-1"
        style={{width: '100%', minHeight: '300px'}}
      ></embed >
      <div className="d-flex justify-content-between pt-2">
        <button 
          type="button"
          className="btn btn-danger"
          onClick={() => onDeleteApproval()}
        >Verwijder goedkeuring</button>
      </div>
    </div>
  )
}

export default Approvement
