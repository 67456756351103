import React, { useState, useEffect, useContext, useRef} from 'react';
import { useParams, useHistory } from 'react-router-dom'; 
import { Tab, Nav } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import Api from '../../utils/Api';
import moment from 'moment';

import Dashboard from '../../components/dashboard/Dashboard';
import Form from './Form';
import Document from './Document';
import Mails from './Mails';
import Photos from './photos';
import Siteorders from './Siteorders';
import Approval from './Approval';
import Invoice from './Invoice';
import ModalArchiveWorkorder from './ModalArchiveWorkorder';

import { authContext } from '../../context/AuthContext';
import { WorkorderContext }  from '../../context/WorkorderContext';
import ModalWarnBeforeProceed from './ModalWarnBeforeProceed';

function BadgeSiteorder(props){
  if (!props.siteorders || props.siteorders?.length === 0){
    return null
  }

  return (<span className="badge badge-success">{props.siteorders?.length}</span>)
}

function BadgeInvoice(props){
  if (props.statusInvoice === 'paid' || props.statusInvoice === 'sent'){
    return (<span className="badge badge-success">F</span>)
  }

  if (props.statusInvoice === 'booked'){
    return (<span className="badge badge-success">F</span>)
  }

  if (props.statusInvoice === 'missing' && props.statusProforma === 'ok'){
    return (<span className="badge badge-primary">P</span>)
  }

  return (null);
}

function BadgeApproval(props){
  if(props.approval){
    return (<span className="badge badge-success">Y</span>)
  }

  if(props.needsApproval !== null && props.needsApproval === 1 && !props.approval){
    return (<span className="badge badge-danger">X</span>)
  }

  if(props.needsApproval === 0 ){
    return (<span className="badge badge-success">X</span>)
  }

  return (null);
}

function Edit(props) {
  const { id } = useParams();
  const { addToast } = useToasts();
  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const history = useHistory();
  const modalArchiveWorkorderRef = useRef();
  const modalWarnBeforeProceedRef = useRef();

  //laravel resources
  const [workorder, setWorkorder] = useState({});
  const [workorderPdf, setWorkorderPdf] = useState(null);

  //fetch workorder
  useEffect(() => {
    fetchWorkorder();
  }, [id]);  //eslint-disable-line react-hooks/exhaustive-deps

  const refetchWorkorder = () => {
    //setWorkorder({});
    fetchWorkorder();
  }

  const fetchWorkorder = () => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get(`/workorders/${id}`, { headers })
      .then(res => {
        setWorkorder(res.data)
      })
      .catch(err => {
        console.log(err)
        err.response.status === 404 && props.history.push("/404");
      });

    Api.get(`workorders/getWorkorder/${id}`, { headers })
    .then(res => {
      setWorkorderPdf(null);
      setWorkorderPdf(res.data?.workorder)
    })
    .catch(err => {
      console.log(err)
    });
  }

  //submit form
  const onSubmit = (data) => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.patch(`/workorders/${id}`, data, { headers })
      .then(res => {
        addToast('Werkbon saved.', { appearance: 'success' });
        fetchWorkorder();
      })
      .catch(err => {
        addToast('Werkbon of factuur updaten mislukt.', { appearance: 'error' });
        fetchWorkorder();
      });
  }

  //archive
  const onArchive = () => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.delete(`/workorders/${id}`, { headers })
      .then(res => {
        addToast('Werkbon gearchiveerd.', { appearance: 'success' });
        history.push(`/workorders`)
      })
      .catch(err => addToast('Werkbon archiveren mislukt.', { appearance: 'error' }));
  }

  //archive
  const onDuplicate = () => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    console.log(workorder);

    const data = {
      'status': 0,
      // 'date': null,
      // 'timeslot': null,
      'is_category_drilling': workorder.is_category_drilling,
      'is_category_sawing': workorder.is_category_sawing,
      'is_category_various': workorder.is_category_various,

      'checkin_at_work': workorder.checkin_at_work,
      // 'site_id': siteId ?? null,
      'site_line_1': workorder.site_line_1,
      'site_postal_code': String(workorder.site_postal_code),
      'site_city': workorder.site_city,
      'site_country': workorder.site_country,
      'site_contact_name': workorder.site_contact_name,
      'site_contact_phone': workorder.site_contact_phone,
      'site_contact_email': workorder.site_contact_email,

      'tl_client_id': workorder.tl_client_id,
      'tl_contact': workorder.tl_contact,
      'is_billed_to_other_company' : workorder.is_billed_to_other_company ? true : false,
      'tl_client_billing': workorder.tl_client_billing,
      'tl_contact_billing': workorder.tl_contact_billing,
      'invoice_email': workorder.invoice_email,
      'payment_term': Number(workorder.payment_term),
      
      // 'siteorder_nr': siteorderNr,
      'employee': workorder.employee,
      'work_todo': workorder.work_todo,

      'needs_approval': workorder.needs_approval,
      'invoice_lines': workorder.invoice_lines,
      'invoice_total': workorder.invoice_total,
    };

    Api.post(`/workorders/`, data, { headers })
      .then(res => {
        addToast('Werkbon gedupliceerd.', { appearance: 'success' });
        history.push(`/workorders/${res.data?.id}`);
      })
      .catch(err => {
        console.log(err.response);
        addToast('Werkbon dupliceren mislukt.', { appearance: 'error' })
      });
  }

  return (
    <Dashboard padding="0">
      <div className="h-100 d-flex flex-column"> 

        <div className="row flex-fill" style={{ minHeight: 0, overflowY: 'auto' }}>
          <div className="col-8 mh-100" style={{ overflowY: 'auto' }}>

            <div className="h-100 d-flex flex-column">
              <WorkorderContext.Provider value={{ refetchWorkorder }}>
                <Tab.Container id="workorder-tabs" defaultActiveKey="workorder" transition={false}>

                  <div className="row flex-shrink-0 border-bottom">
                    <div className="col-12 pt-3">
                      
                      <h1>Werkbon {workorder.internal_ref}</h1>
                      <p className="text-muted mb-2">Laatste aanpassing: {moment(workorder.updated_at).calendar()}</p>
                      <Nav variant="tabs" className="border-0">
                        <Nav.Item>
                          <Nav.Link eventKey="workorder">Werkbon</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="mails">
                            <span>Mails <span className="badge badge-primary">{workorder.mails?.length}</span></span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="photos">
                            <span>Fotos <span className="badge badge-primary">{workorder.photos?.length}</span></span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="siteorder">
                            <span>Werfbon <BadgeSiteorder siteorders={workorder.siteorders} /></span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="invoice">
                            <span>Factuur <BadgeInvoice statusProforma={workorder.status_proforma} statusInvoice={workorder.status_invoice} /></span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="approval">
                            <span>Goedkeuring <BadgeApproval approval={workorder.approval} needsApproval={workorder.needs_approval} /></span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                    </div>
                  </div>

                  <div className="row flex-fill" style={{ minHeight: 0, overflowY: 'auto' }}>
                    <div className="col-12 mh-100 py-3" style={{ overflowY: 'auto' }}>

                      <Tab.Content className="h-100">
                        <Tab.Pane eventKey="workorder" className="h-100">
                          <Document 
                            workorder={workorder}
                            workorderPdf={workorderPdf}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="mails" className="h-100">
                          <Mails 
                            mails={workorder.mails ?? []}
                            refetchWorkorder={refetchWorkorder}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="photos" className="h-100">
                          <Photos 
                            internalRef={workorder.internal_ref}
                            photos={workorder.photos}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="siteorder" className="h-100">
                          <Siteorders 
                            siteorders={workorder.siteorders}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="approval" className="h-100">
                          <Approval 
                            needsApproval={workorder.needs_approval}
                            approval={workorder.approval}
                            refetchWorkorder={refetchWorkorder}
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="invoice" className="h-100">
                          <Invoice 
                            tl_invoice_id={workorder.tl_invoice_id}
                            workorder={workorder}
                            refetchWorkorder={refetchWorkorder}
                          />
                        </Tab.Pane>
                      </Tab.Content>

                    </div>
                  </div>

                </Tab.Container>
              </WorkorderContext.Provider>
            </div>

          </div>

          <div className="col-4 bg-light border-left border-top h-100 d-flex flex-column" >

              <Form 
                onSubmit={onSubmit}
                workorder={workorder}
                onArchive={() => modalArchiveWorkorderRef.current.showModal()}
                onDuplicate={() => modalWarnBeforeProceedRef.current.showModal()}
              />

              <ModalWarnBeforeProceed
                ref={modalWarnBeforeProceedRef}
                title="Dupliceren werkbon"
                body="U staat op het punt deze werkbon te dupliceren, bent u zeker? Vergeet de nodige velden niet aan te passen na duplicatie."
                onSubmit={onDuplicate}
                css={"bg-white"}
              />

              <ModalArchiveWorkorder 
                ref={modalArchiveWorkorderRef}
                onArchive={onArchive}
              />
            
          </div>

        </div>
      </div>
      
    </Dashboard>
  )
}

export default Edit
