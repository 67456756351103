import React from 'react'

function NotFound() {
  return (
    <div>
      <h1>404, Not found!</h1>
    </div>
  )
}

export default NotFound
