import React, { useState, useContext } from 'react';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';

function Login(props) {
  const [errorMsg, setErrorMsg] = useState('');

  const { setAuthData } = useContext(authContext);

  //submit login
  const onSubmit = (e) => {
    e.preventDefault();
    setErrorMsg('');

    const data = {
      email: e.target.email.value,
      password: e.target.password.value
    }

    Api.post('/login', data)
      .then(res => {
        setAuthData(res.data);
        //localStorage.setItem('user', res.data);
        props.history.push('/');
      })
      .catch(err => {
        console.log(err);
        setErrorMsg(err.response?.data?.message ?? 'there was an error');
      });

  }

  return (
    <div className="container-fluid h-100 d-flex flex-column bg-light">
      <div className="row justify-content-center my-auto">
        <form onSubmit={onSubmit} className="col-md-4 p-4 rounded bg-white shadow-sm">
          <div className="form-group">
            <label htmlFor="frmEmail">Email adress</label>
            <input 
              type="email" 
              name="email" 
              className="form-control" 
              id="frmEmail" 
              placeholder="Enter email" 
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="frmPassword">Password</label>
            <input 
              type="password" 
              name="password" 
              className="form-control" 
              id="frmPassword" 
              placeholder="Password" 
              required
            />
          </div>

          { errorMsg && <p className="text-danger">{errorMsg}</p> }

          <button className="btn btn-primary" type="submit">Login</button>
        </form>
      </div>
    </div>
  )
}

export default Login
