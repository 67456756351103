import React, { useState, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalArchiveWorkorder = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = () => {
    props.onArchive();
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Werkbon archiveren</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Opgelet, deze actie zal de werkbon verwijderen. Tevens worden alle gerelateerde mails, fotos, bevestingen, etc gearchiveerd.</p>
        <p>Wenst u verder te gaan?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="danger" onClick={onSubmit}>
          Archiveren
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

export default ModalArchiveWorkorder