import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import Api from '../../utils/Api';
import { authContext } from '../../context/AuthContext';
import { useToasts } from 'react-toast-notifications';
import Dashboard from '../../components/dashboard/Dashboard';
import Form from './Form';

function Create(props) {
  const history = useHistory();
  const createWithMailId = (props.location && props.location.createWithMailId) || null;

  const { addToast } = useToasts();
  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  //submit form
  const onSubmit = (formData) => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    const data = createWithMailId ? {
      ...formData, createWithMailId
    } : formData;

    Api.post(`/workorders/`, data, { headers })
      .then(res => {
        addToast('Werkbon saved.', { appearance: 'success' });
        history.push(`/workorders/${res.data?.id}`);
      })
      .catch(err => addToast('Werkbon opslaan mislukt.', { appearance: 'error' }));
  }

  return(
    <Dashboard padding="0">
      <div className="h-100 d-flex flex-column"> 

        <div className="row flex-fill" style={{ minHeight: 0, overflowY: 'auto' }}>
          <div className="col-8 mh-100" style={{ overflowY: 'auto' }}>

            <div className="h-100 d-flex flex-column">
                <Tab.Container id="workorder-tabs" defaultActiveKey="workorder" transition={false}>

                  <div className="row flex-shrink-0 border-bottom">
                    <div className="col-12 pt-3">
                      
                      <h1>Nieuwe werkbon</h1>

                      <Nav variant="tabs" className="border-0">
                        <Nav.Item>
                          <Nav.Link eventKey="workorder">Werkbon</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          {/* <Nav.Link eventKey="mails">
                            <span>Mails</span>
                          </Nav.Link> */}
                        </Nav.Item>
                      </Nav>

                    </div>
                  </div>

                  <div className="row flex-fill" style={{ minHeight: 0, overflowY: 'auto' }}>
                    <div className="col-12 mh-100 py-3" style={{ overflowY: 'auto' }}>

                    <Tab.Content className="h-100">
                      <Tab.Pane eventKey="workorder" className="h-100">
                        <div className="col bg-light py-4">
                          <p className="text-muted mb-0">Werkbon wordt beschikbaar na opslaan.</p>
                        </div>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="mails" className="h-100">
                        <p>mails</p>
                      </Tab.Pane> */}
                    </Tab.Content>

                  </div>
                </div>

              </Tab.Container>
            </div>

          </div>

          <div className="col-4 bg-light border-left border-top h-100 d-flex flex-column" >

              <Form 
                onSubmit={onSubmit}
              />
            
          </div>

        </div>
      </div>
      
    </Dashboard>
  )
}

export default Create
