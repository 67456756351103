import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

function WorkList(props) {
  const [newQuantity, setNewQuantity] = useState(1); 
  const [newDescription, setNewDescription] = useState(''); 
  const [addEnabled, setAddEnabled] = useState(false);

  const list = props.list;

  //enable add btn
  useEffect(() => {
    setAddEnabled(!!newDescription && newQuantity > 0)
  }, [newDescription, newQuantity]);

  //row update
  const onUpdate = (updateId, quantity, description) => {
    const updatedList = list.map((item, id) => {
      if (id === updateId){
        return {quantity, description}
      }
      else {return item}
    });

    props.onChange(updatedList);
  }

  //row add
  const onAdd = () => {
    const updatedList = [
      ...list,
      {quantity: newQuantity, description: newDescription}
    ];

    props.onChange(updatedList);
    setNewQuantity(1);
    setNewDescription('');
  }

  //row delete
  const onDelete = (deleteId) => {
    const updatedList = list.filter((item, id) => id !== deleteId);
    props.onChange(updatedList);
  }

  return (
    <div>
      <Table bordered>
        <thead>
          <tr>
            <th style={{width: '50px'}}>qty</th>
            <th colSpan="2">omschrijving</th>
          </tr>
        </thead>
        <tbody>
          { list.map((item, id) =>
            <tr key={id}>
              <td className="p-0">
                <input 
                  type="number"
                  min="1"
                  max="999"
                  className="border-0"
                  style={{width: '50px', height: '76px'}}
                  value={item.quantity}
                  onChange={(e) => onUpdate(id, e.target.value, item.description)}
                  />
              </td>
              <td className="p-0">     
                <textarea 
                  className="border-0"
                  style={{minWidth: '100%', height: '76px', display: 'block', resize: 'none'}}
                  value={item.description}
                  onChange={(e) => onUpdate(id, item.quantity, e.target.value)}
                />
              </td>
              { !props.disabled ?
                <td className="p-0 d-flex justify-content-center align-items-center" style={{height: '76px'}}>
                  <button type="button" className="btn btn-sm btn-danger" onClick={() => onDelete(id)}>x</button>     
                </td>
              : null }
            </tr>
          )}
          { list.length === 0 &&
            <tr>
              <td colSpan="3">
                <span className="text-muted">Nog geen items</span>
              </td>
            </tr>
          }
        </tbody>
      </Table>
      { !props.disabled ?
      <>
      <label htmlFor="frmWorkPerformed">taak toevoegen</label>
      <Table bordered>
        <tbody>
        <tr>
          <td className="p-0" style={{width: '50px'}}>
            <input 
              type="number"
              value={newQuantity}
              onChange={(e) => setNewQuantity(e.target.value)}
              min="1"
              max="999"
              className="border-0"
              style={{width: '50px', height: '76px'}}
              />
          </td>
          <td className="p-0">     
            <textarea 
              className="border-0"
              value={newDescription}
              onChange={e => setNewDescription(e.target.value)}
              style={{minWidth: '100%', height: '76px', display: 'block'}}
            />
          </td>
          <td className="p-0 d-flex justify-content-center align-items-center" style={{height: '76px'}}>
            <button
              type="button"
              disabled={!addEnabled} 
              className="btn btn-sm btn-success" 
              onClick={() => onAdd()}
            >add</button>     
          </td>
        </tr>
        </tbody>
      </Table>
      </>
      : null }
    </div>
  )
}

export default WorkList
