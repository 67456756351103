import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';
import Table from '../../components/table';

const LineOneFormatter = ({value, row}) => {
  return (<span>{row.address?.line_1 ?? '-'}</span>)
}

const PostalcodeFormatter = ({value, row}) => {
  return (<span>{row.address?.postal_code ?? '-'}</span>)
}

const CityFormatter = ({value, row}) => {
  return (<span>{row.address?.city ?? '-'}</span>)
}

const CountryFormatter = ({value, row}) => {
  return (<span>{row.address?.country ?? '-'}</span>)
}

function List() {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { auth } = useContext(authContext);

  const ButtonFormatter = ({value, row}) => {
    return (
      <Link 
        to={`/sites/${row.id}`} 
        className="btn btn-primary"
      >Edit</Link>
    );
  }

  const columns = [
    { 
      key: 'name', 
      name: 'naam', 
      sortable: false, 
      //formatter: LogoFormatter 
    },
    { 
      name: 'adres', 
      sortable: false,
      formatter: LineOneFormatter 
    },
    { 
      name: 'postcode', 
      sortable: false, 
      formatter: PostalcodeFormatter 
    },
    { 
      name: 'stad', 
      sortable: false, 
      formatter: CityFormatter 
    },
    { 
      name: 'land', 
      sortable: false, 
      formatter: CountryFormatter 
    },
    { 
      name: '', 
      sortable: false, 
      formatter: ButtonFormatter 
    },
  ]
   
  useEffect(() => {
    fetchSites();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchSites = () => {
    setLoading(true);
    setError('');
    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get('/sites', { headers })
      .then(res => {
        setSites(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response);
        setLoading(false);
      })
  }

  return (
    <div className="h-100 d-flex flex-column">

      <Table 
        columns={columns} 
        data={sites}
        //sortBy={sortBy}
        //sortAsc={sortAsc}
        //setSortBy={setSortBy}
        loading={loading}
        invertSortDir={() => {}}
        onRowClick={(row) => {}}
      />

      { error.status && 
        <div className="p-3 mb-2 bg-danger text-white">
          <p>There was an error loading the resource</p>
          { error.status === 502 && <p>Please check the provider config <Link to="/profile">here</Link></p> }
        </div>
      }
    </div>
  )
}

export default List
