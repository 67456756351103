import React from 'react';
import Oauth from './Oauth';

function Teamleader() {
  return (
    <Oauth 
      name="Teamleader"
      authUrl="/teamleader/authenticate"
      redirUrl="/teamleader/redirecturl"
    />
  )
}

export default Teamleader
