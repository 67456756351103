import React from 'react'

function Loader() {
  return (
    <div className="text-muted">
      <p>Loading...</p>
    </div>
  )
}

export default Loader
