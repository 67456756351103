import React, { useState, useEffect, useContext, useRef } from 'react';
import { Prompt } from 'react-router-dom';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';
import ReactQuill from 'react-quill';
import DynamicSelect from '../../components/form/DynamicSelect';
import DynamicSuggestion from '../../components/form/DynamicSuggestion';
import WorkList from './WorkList';
import moment from 'moment';
import postalCodesBe from '../../utils/PostalCodes';
import ModalImportSite from './ModalImportSite';

const todaysDate = () => {
  return moment().format('YYYY-MM-DD'); //2020-01-01
} 

function WorkorderForm(props) {
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [invoiceEmailWasAutoUpd, setInvoiceEmailWasAutoUpd] = useState(false);
  const [stateLoaded, setStateLoaded] = useState(false);
  const [stateOnLoad, setStateOnLoad] = useState(null);

  //api resources
  const [companies, setCompanies] = useState([]);
  const [contacts, setContacts] = useState([]); 
  const [billingCompanies, setBillingCompanies] = useState([]);
  const [billingContacts, setBillingContacts] = useState([]); 
  const [loadedCompanies, setLoadedCompanies] = useState(false);
  const [loadedContacts, setLoadedContacts] = useState(false);
  const [loadedBillingCompanies, setLoadedBillingCompanies] = useState(false);
  const [loadedBillingContacts, setLoadedBillingContacts] = useState(false);

  //form workorder
  const [status, setStatus] = useState(0);
  const [date, setDate] = useState(todaysDate());
  const [timeslot, setTimeslot] = useState(1);
  const [isCategoryDrilling, setCategoryDrilling] = useState(false);
  const [isCategorySawing, setCategorySawing] = useState(false);
  const [isCategoryVarious, setCategoryVarious] = useState(false);

  //form site
  const [checkinAtWork, setCheckinAtWork] = useState('');
  const [siteId, setSiteId] = useState(null);
  const [siteLine1, setSiteLine1] = useState('');
  const [sitePostalCode, setSitePostalCode] = useState('');
  const [siteCity, setSiteCity] = useState('');
  const [siteCountry, setSiteCountry] = useState('');
  const [siteContactName, setSiteContactName] = useState('');
  const [siteContactPhone, setSiteContactPhone] = useState('');
  const [siteContactEmail, setSiteContactEmail] = useState('');

  //form client
  const [company, setCompany] = useState({});
  const [contact, setContact] = useState({});
  const [isBilledToOtherCompany, setBilledToOtherCompany] = useState(false);
  const [alternativeBillingCompany, setAlternativeBillingCompany] = useState({});
  const [alternativeBillingContact, setAlternativeBillingContact] = useState({});
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [sugInvoiceEmail, setSugInvoiceEmail] = useState('');

  //form work
  const [siteorderNr, setSiteorderNr] = useState('');
  const [employee, setEmployee] = useState('');
  const [workTodo, setWorkTodo] = useState('');

  //form invoice
  const [needsApproval, setNeedsApproval] = useState(null);
  const [SugpaymentTerm, setSugPaymentTerm] = useState(null);
  const [SugpaymentTermCompany, setSugPaymentTermCompany] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState(0);
  const [invoiceLines, setInvoiceLines] = useState([]);
  const [invoiceTotal, setInvoiceTotal] = useState(0);

  //extra
  const [allowChangesInvoice, setAllowChangesInvoice] = useState(false);

  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const refModalImportSite = useRef();

  //set defaults
  useEffect(() => {
    setStateOnLoad({
      status:     props.workorder?.status   ?? 0,
      date:       props.workorder?.date     ?? todaysDate(),
      timeslot:   props.workorder?.timeslot ?? 1,

      isCategoryDrilling:   Boolean(props.workorder?.is_category_drilling)   ?? false,
      isCategorySawing:     Boolean(props.workorder?.is_category_sawing)     ?? false,
      isCategoryVarious:    Boolean(props.workorder?.is_category_various)    ?? false,

      checkinAtWork:      props.workorder?.checkin_at_work    ?? '',
      siteLine1:          props.workorder?.site_line_1        ?? '',
      sitePostalCode:     props.workorder?.site_postal_code   ?? '',
      siteCity:           props.workorder?.site_city          ?? '',
      siteCountry:        props.workorder?.site_country       ?? '',
      siteContactName:    props.workorder?.site_contact_name  ?? '',
      siteContactPhone:   props.workorder?.site_contact_phone ?? '',
      siteContactEmail:   props.workorder?.site_contact_email ?? '',

      company:                    props.workorder?.company                  ?? {},
      contact:                    props.workorder?.tl_contact_id            ?? {},
      isBilledToOtherCompany:     Boolean(props.workorder?.has_alternative_billing)  ?? false,
      alternativeBillingCompany:  props.workorder?.company_billing          ?? {},
      alternativeBillingContact:  props.workorder?.tl_contact_billing       ?? {},
      invoiceEmail:               props.workorder?.invoice_email            ?? '',
      paymentTerm:                props.workorder?.payment_term             ?? null,

      siteorderNr:  props.workorder?.siteorder_nr ?? '',
      employee: props.workorder?.employee ?? '',
      workTodo: props.workorder?.work_todo ?? '',

      needsApproval: props.workorder?.needs_approval ?? null,
      invoiceLines: props.workorder?.invoice_lines ?? [],
      invoiceTotal: props.workorder?.invoice_total ?? 0,
    });

    if(props.workorder){
      if(props.workorder?.invoice && props.workorder?.invoice?.tl_status === 'draft'){
        setAllowChangesInvoice(true);
      } else if (!props.workorder?.invoice){
        setAllowChangesInvoice(true);
      } else {
        setAllowChangesInvoice(false);
      }
    }
  }, [props.workorder])

  useEffect(() => {
    if(stateOnLoad){
      setStatus(stateOnLoad.status);
      setDate(stateOnLoad.date);
      setTimeslot(stateOnLoad.timeslot);
      setCategoryDrilling(stateOnLoad.isCategoryDrilling);
      setCategorySawing(stateOnLoad.isCategorySawing);
      setCategoryVarious(stateOnLoad.isCategoryVarious);

      setCheckinAtWork(stateOnLoad.checkinAtWork);
      setSiteLine1(stateOnLoad.siteLine1);
      setSitePostalCode(stateOnLoad.sitePostalCode);
      setSiteCity(stateOnLoad.siteCity);
      setSiteCountry(stateOnLoad.siteCountry);
      setSiteContactName(stateOnLoad.siteContactName);
      setSiteContactPhone(stateOnLoad.siteContactPhone);
      setSiteContactEmail(stateOnLoad.siteContactEmail);
      
      setCompany(stateOnLoad.company);
      setContact(stateOnLoad.contact);
      setBilledToOtherCompany(stateOnLoad.isBilledToOtherCompany);
      setAlternativeBillingCompany(stateOnLoad.alternativeBillingCompany);
      setAlternativeBillingContact(stateOnLoad.alternativeBillingContact);
      setInvoiceEmail(stateOnLoad.invoiceEmail);
      setPaymentTerm(stateOnLoad.paymentTerm);

      setSiteorderNr(stateOnLoad.siteorderNr);
      setEmployee(stateOnLoad.employee);
      setWorkTodo(stateOnLoad.workTodo);

      setNeedsApproval(stateOnLoad.needsApproval);
      setInvoiceLines(stateOnLoad.invoiceLines);
      setInvoiceTotal(stateOnLoad.invoiceTotal);

      setStateLoaded(true);
      setUnsavedChanges(false);
    }
  }, [stateOnLoad])

  //fetch companies
  useEffect(() => {
    fetchCompanies();
    fetchBillingCompanies();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  //fetch contacts for selected company
  useEffect(() => {
    //if company is selected
    if (Object.keys(company).length !== 0){
      fetchContactsForCompany(company.tl_id)
    }
  }, [company]); //eslint-disable-line react-hooks/exhaustive-deps

  //fetch contacts for selected billing company
  useEffect(() => {
    //if company is selected
    if (Object.keys(alternativeBillingCompany).length !== 0){
      fetchContactsForAlternativeCompany(alternativeBillingCompany.tl_id)
    }
  }, [alternativeBillingCompany]); //eslint-disable-line react-hooks/exhaustive-deps

  //remove contact when removing company
  useEffect(() => {
    if(Object.keys(company) < 1){
      setContact({});
    }
  }, [company]);

  //update payment term and company email
  useEffect(() => {
    const getCompanyEmail = (company) => {
      if(company?.emails && company?.emails?.length > 0){
        const email = company?.emails[0];
        return email.email;
      }
      return null;
    }

    if(Object.keys(alternativeBillingCompany).length !== 0){
      setSugPaymentTerm(alternativeBillingCompany.payment_term ?? null);
      setSugPaymentTermCompany(alternativeBillingCompany.name);

      //update invoice email
      const companyEmail = getCompanyEmail(alternativeBillingCompany);
      setSugInvoiceEmail(companyEmail);
      if(!invoiceEmail){
        setInvoiceEmail(companyEmail);
        setInvoiceEmailWasAutoUpd(true);
      }
    }else if(Object.keys(company).length !== 0){
      setSugPaymentTerm(company.payment_term ?? null);
      setSugPaymentTermCompany(company.name);
      
      //update invoice email
      const companyEmail = getCompanyEmail(company);
      setSugInvoiceEmail(companyEmail);
      if(!invoiceEmail){
        setInvoiceEmail(companyEmail);
        setInvoiceEmailWasAutoUpd(true);
      }     
    }else{
      setSugPaymentTerm(null);
      setSugPaymentTermCompany(null);
    }
  }, [company, alternativeBillingCompany, invoiceEmail])

  //remove contact when removing company
  useEffect(() => {
    Object.keys(alternativeBillingCompany) < 1 && setAlternativeBillingContact({});
  }, [alternativeBillingCompany]);

  //remove alternative billing
  useEffect(() => {
    if(isBilledToOtherCompany === false){
      setAlternativeBillingCompany({});
      setAlternativeBillingContact({});
    }
  }, [isBilledToOtherCompany]);


  const fetchCompanies = (str = null) => {
    if(!str){
      setLoadedCompanies(false);
    }

    let params = {
      pageCount: 10,
    };

    if(str){
      params = {
        pageCount: 10,
        term: str
      }
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get(`/companies`, { headers, params })
      .then(res => {
        setCompanies(res.data)
        setLoadedCompanies(true);
      })
      .catch(err => console.log(err));
  }

  const fetchBillingCompanies = (str = null) => {    
    if(!str){
      setLoadedBillingCompanies(false);
    }

    let params = {
      pageCount: 10,
    };

    if(str){
      params = {
        pageCount: 10,
        term: str
      }
    }

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get(`/companies`, { headers, params })
      .then(res => {
        setBillingCompanies(res.data)
        setLoadedBillingCompanies(true);
      })
      .catch(err => console.log(err));
  }

  const fetchContactsForCompany = (companyId = null) => {
    if(companyId){
      setLoadedContacts(false);
      
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
      
      Api.get(`/contactsFromCompany/${companyId}`, { headers })
      .then(res => {
        setContacts(res.data)
        setLoadedContacts(true);
      })
      .catch(err => console.log(err));
    }
  }

  const fetchContactsForAlternativeCompany = (companyId = null) => {
    if(companyId){
      setLoadedBillingContacts(false);
        
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
      
      Api.get(`/contactsFromCompany/${companyId}`, { headers })
      .then(res => {
        setBillingContacts(res.data)
        setLoadedBillingContacts(true);
      })
      .catch(err => console.log(err)); 
    }
  }

  //update city and code after using suggestion
  const updateCityAndCode = (address) => {
    setSiteCity(address.city ?? '');
    setSitePostalCode(address.code ?? '');
  }

  const onImportSite = (site) => {
    if(site){
      setSiteId(site.id);
      setSiteLine1(site.address?.line_1 ?? '');
      setSitePostalCode(site.address?.postal_code ?? '' );
      setSiteCity(site.address?.city ?? '' );
      setSiteCountry(site.address?.country ?? '' );
    }
  }

  //submit form
  const onSubmit = () => {
    //set default country if missing
    const siteCountryHelper = (siteLine1 && !siteCountry) ? 'België' : null;

    const data = {
      'status': status,
      'date': date,
      'timeslot': timeslot,
      'is_category_drilling': isCategoryDrilling,
      'is_category_sawing': isCategorySawing,
      'is_category_various': isCategoryVarious,

      'checkin_at_work': checkinAtWork,
      'site_id': siteId ?? null,
      'site_line_1': siteLine1 ? siteLine1 : null,
      'site_postal_code': String(sitePostalCode) ? String(sitePostalCode) : null,
      'site_city': siteCity ? siteCity : null,
      'site_country': siteCountry ? siteCountry : siteCountryHelper ? siteCountryHelper : null,
      'site_contact_name': siteContactName,
      'site_contact_phone': siteContactPhone,
      'site_contact_email': siteContactEmail,

      'tl_client_id': (company && company.tl_id) ? company.tl_id : null,
      'tl_contact': contact,
      'is_billed_to_other_company' : isBilledToOtherCompany,
      'tl_client_billing': (alternativeBillingCompany && alternativeBillingCompany.tl_id) ? alternativeBillingCompany.tl_id : null,
      'tl_contact_billing': alternativeBillingContact,
      'invoice_email': invoiceEmail ?? null,
      'payment_term': Number(paymentTerm),
      
      'siteorder_nr': siteorderNr,
      'employee': employee,
      'work_todo': workTodo,

      'needs_approval': needsApproval,
      'invoice_lines': invoiceLines,
      'invoice_total': invoiceTotal,
    };

    setUnsavedChanges(false);
    props.onSubmit(data);
  }

  //archive workorder
  const onArchive = () => {
    setUnsavedChanges(false);
    props.onArchive();
  }

  //archive workorder
  const onDuplicate = () => {
    setUnsavedChanges(false);
    props.onDuplicate();
  }

  //check for unsaved changes
  useEffect(() => {
    if(stateLoaded){
      if(
        date !== stateOnLoad.date
        || timeslot !== stateOnLoad.timeslot
        || isCategoryDrilling !== stateOnLoad.isCategoryDrilling
        || isCategorySawing !== stateOnLoad.isCategorySawing
        || isCategoryVarious !== stateOnLoad.isCategoryVarious
        || checkinAtWork !== stateOnLoad.checkinAtWork
        || siteLine1 !== stateOnLoad.siteLine1
        || sitePostalCode !== stateOnLoad.sitePostalCode
        || siteCity !== stateOnLoad.siteCity
        || siteCountry !== stateOnLoad.siteCountry
        || siteContactName !== stateOnLoad.siteContactName
        || siteContactPhone !== stateOnLoad.siteContactPhone
        || siteContactEmail !== stateOnLoad.siteContactEmail
        || JSON.stringify(company) !== JSON.stringify(stateOnLoad.company)
        // || JSON.stringify(contact) !== JSON.stringify(stateOnLoad.contact)
        || isBilledToOtherCompany !== stateOnLoad.isBilledToOtherCompany
        || JSON.stringify(alternativeBillingCompany) !== JSON.stringify(stateOnLoad.alternativeBillingCompany)
        || (invoiceEmail !== stateOnLoad.invoiceEmail && !invoiceEmailWasAutoUpd)
        || String(paymentTerm) !== String(stateOnLoad.paymentTerm)
        || siteorderNr !== stateOnLoad.siteorderNr
        || employee !== stateOnLoad.employee
        || workTodo.replace(/<\/?[^>]+(>|$)/g, "") !== stateOnLoad.workTodo.replace(/<\/?[^>]+(>|$)/g, "")
        || needsApproval !== stateOnLoad.needsApproval
        || JSON.stringify(invoiceLines) !== JSON.stringify(stateOnLoad.invoiceLines)
        || invoiceTotal !== stateOnLoad.invoiceTotal
      ){
        setUnsavedChanges(true);
      }else{
        setUnsavedChanges(false);
      }
    }
  }, [stateLoaded, date, timeslot, isCategoryDrilling, isCategorySawing, isCategoryVarious, 
    checkinAtWork, siteLine1, sitePostalCode, siteCity, siteCountry, siteContactName, siteContactPhone, siteContactEmail, company, contact, isBilledToOtherCompany, alternativeBillingCompany, invoiceEmail, paymentTerm, siteorderNr, employee, workTodo, needsApproval, invoiceLines, invoiceTotal, stateOnLoad, invoiceEmailWasAutoUpd]);

  return (
    <>
      <Prompt
        when={unsavedChanges}
        message={(location, action) => {
          return `Er zijn niet-opgeslagen aanpassingen, bent u zeker dat u dit formulier wenst te verlaten zonder op te slaan?`
        }}
      />

      { unsavedChanges ? 
        <div className="alert alert-warning small my-2"><span role="img" aria-label="warning">⚠️</span> Unsaved changes</div>
      : null }

      <div className="row flex-fill" style={{minHeight: '0'}}>
        <div className="col mh-100" style={{overflowY: 'scroll'}}>
          <form onSubmit={e => e.preventDefault()}>

            <div className="my-2 pb-5">
              <div className="border-bottom my-2">
                <b>Werkbon</b>
              </div>

              <div className="form-group">
                <label htmlFor="frmInternalRef">Nummer</label>
                <input 
                  className="form-control" 
                  name="internalRef"
                  id="frmInternalRef" 
                  value={props.workorder?.internal_ref ?? ""} 
                  disabled
                />
              </div>

              {/* <div className="form-group">
                <label htmlFor="frmStatus">Status</label>
                <select 
                  className="form-control" 
                  name="status" 
                  id="frmStatus" 
                  onChange={(e) => setStatus(e.target.value)} 
                  value={status}
                  disabled
                >
                  <option value="1">Draft</option>
                  <option value="2">Goedgekeurd</option>
                  <option value="3">Uitgevoerd</option>
                  <option value="4">Geweigerd</option>
                </select>
              </div> */}

              <div className="form-group">
                <label htmlFor="frmDate">Date</label>
                <input 
                  type="date"
                  className="form-control" 
                  name="frmDate" 
                  pattern="\d{4}-\d{2}-\d{2}"
                  // placeholder={todaysDate} 
                  id="frmDate" onChange={(e) => setDate(e.target.value)} 
                  value={date} 
                />
              </div>

              <div className="form-group">
                <label htmlFor="frmTimeslot">Timeslot</label>
                <select 
                  className="form-control" 
                  name="status" 
                  id="frmTimeslot" 
                  onChange={(e) => setTimeslot(Number(e.target.value))} 
                  value={timeslot}
                >
                  <option value="1">eerste uur</option>
                  <option value="2">voormiddag</option>
                  <option value="3">namiddag</option>
                  <option value="4">avond</option>
                  <option value="5">nacht</option>
                  <option value="6">loopdag</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="frmCategoryDrilling">Categorieën</label>
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="frmCategoryDrilling"
                    name="isCategoryDrilling"
                    checked={isCategoryDrilling} 
                    onChange={(e) => setCategoryDrilling(e.target.checked)} 
                  />
                  <label className="form-check-label" htmlFor="frmCategoryDrilling">boren</label>
                </div>

                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="frmCategorySawing" 
                    name="isCategorySawing"
                    checked={isCategorySawing} 
                    onChange={(e) => setCategorySawing(e.target.checked)} 
                  />
                  <label className="form-check-label" htmlFor="frmCategorySawing">zagen</label>
                </div>

                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="frmCategoryVarious" 
                    name="isCategoryVarious"
                    checked={isCategoryVarious} 
                    onChange={(e) => setCategoryVarious(e.target.checked)} 
                  />
                  <label className="form-check-label" htmlFor="frmCategoryVarious">diverse</label>
                </div>
              </div>
            </div>

            <div className="my-2 pb-5">
              <div className="border-bottom my-2">
                <b>Werfgegevens</b>
              </div>


              <div className="form-group mb-5">
                <label htmlFor="frmCheckinAtWork">Checkin at work</label>
                <input 
                  className="form-control" 
                  name="checkinAtWork" 
                  id="frmCheckinAtWork" 
                  onChange={(e) => setCheckinAtWork(e.target.value)} 
                  value={checkinAtWork} 
                />
              </div>

              <div className="mb-2">
                <div className="btn-group btn-group-sm" role="group">
                  <button 
                    type="button" 
                    className={`btn btn btn-light ${!siteId && 'active'}`}
                    onClick={() => setSiteId(null)}
                    >eenmalig werf</button>
                  <button 
                    type="button" 
                    className={`btn btn btn-light ${siteId && 'active'}`}
                    //onClick={() => setUseExistingSite(true)}
                    onClick={() => refModalImportSite.current.showModal()}
                  >bestaand werf</button>
                </div>
              </div>

              <div className="mb-5">
              { siteId ? 
                <>
                  <div className="alert alert-info" role="alert">
                    {/* <div><span>Werf gent</span></div> */}
                    <div className="small">
                      <div>
                        <span>{ siteLine1 }</span>
                      </div>
                      <div>
                        <span>{sitePostalCode} { siteCity }</span>
                      </div>
                      <div>
                        <span>{siteCountry}</span>
                      </div>
                    </div>
                  </div>
                </>
              :
                <>
                <div className="form-group">
                  <label htmlFor="frmSiteLine1">Straat + nr</label>
                  <input 
                    className="form-control" 
                    id="frmSiteLine1" 
                    name="siteLine1" 
                    onChange={(e) => setSiteLine1(e.target.value)} 
                    value={siteLine1} 
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="frmSiteCity">Stad</label>
                  <DynamicSuggestion 
                    id="frmSiteCity"
                    data={postalCodesBe}
                    dataLoaded={loadedCompanies}
                    value={siteCity}
                    valueField="city"
                    displayField="city"
                    additionalField="code"
                    additionalFieldBrackets={true}
                    searchUpdated={(str) => setSiteCity(str)}
                    updateValue={(value) => updateCityAndCode(value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="frmSitePostalCode">Postcode</label>
                  <input 
                    className="form-control" 
                    id="frmSitePostalCode" 
                    name="sitePostalCode" 
                    onChange={(e) => setSitePostalCode(e.target.value)} 
                    value={sitePostalCode} 
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="frmSiteCountry">Land</label>
                  <select 
                    className="form-control" 
                    id="frmSiteCountry"
                    name="siteCountry" 
                    onChange={(e) => setSiteCountry(e.target.value)} 
                    value={siteCountry} 
                  >
                    <option value="België">België</option>
                    <option value="Nederland">Nederland</option>
                    <option value="Frankrijk">Frankrijk</option>
                    <option value="Duitsland">Duitsland</option>
                    <option value="Luxemburg">Luxemburg</option>
                    <option value="Spanje">Spanje</option>
                    <option value="Verenigd Koninkrijk">UK</option>
                  </select>
                </div>
                </>
              }
              </div>

              <div className="form-group">
                <label htmlFor="frmSiteContactName">Contactpersoon</label>
                <input 
                  className="form-control" 
                  id="frmSiteContactName" 
                  name="siteContactName" 
                  onChange={(e) => setSiteContactName(e.target.value)} 
                  value={siteContactName} 
                />
              </div>

              <div className="form-group">
                <label htmlFor="frmSiteContactPhone">Contactpersoon GSM</label>
                <input 
                  className="form-control" 
                  id="frmSiteContactPhone" 
                  name="siteContactPhone" 
                  onChange={(e) => setSiteContactPhone(e.target.value)} 
                  value={siteContactPhone} 
                />
              </div>

              <div className="form-group">
                <label htmlFor="frmSiteContactEmail">Contactpersoon Email</label>
                <input 
                  className="form-control" 
                  type="email"
                  id="frmSiteContactEmail" 
                  name="siteContactEmail" 
                  onChange={(e) => setSiteContactEmail(e.target.value)} 
                  value={siteContactEmail} 
                />
              </div>
            </div>

            <div className="my-2 pb-5">
              <div className="border-bottom my-2">
                <b>Opdrachtgever</b>
              </div>

              <div className="form-group">
                <label htmlFor="frmCliendId">Bedrijf</label>
                <DynamicSelect 
                  id="frmClientId"
                  data={companies}
                  dataLoaded={loadedCompanies}
                  value={company}
                  valueField="id"
                  displayField="name"
                  additionalField="address.city"
                  additionalFieldBrackets={true}
                  searchUpdated={(str) => fetchCompanies(str)}
                  updateValue={(value) => setCompany(value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="frmContactId">Contact</label>
                <DynamicSelect 
                  data={contacts}
                  dataLoaded={loadedContacts}
                  value={contact}
                  valueField="id"
                  displayField="first_name"
                  additionalField="last_name"
                  updateValue={(value) => setContact(value)}
                  disabled={Object.keys(company).length === 0}
                  disabledText="Please select company first"
                />
              </div>

              <div className="form-group">
                <div className="form-check">
                  <input 
                    className="form-check-input" 
                    type="checkbox" 
                    id="defaultCheck1" 
                    checked={isBilledToOtherCompany} 
                    onChange={(e) => setBilledToOtherCompany(e.target.checked)} 
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">Facturatie op ander bedrijf</label>
                </div>
              </div>

              {isBilledToOtherCompany ?
                <div id="alternativeBillingAddress" className="py-2">
                  <div className="form-group">
                    <label htmlFor="frmCliendId">Bedrijf facturatie</label>
                    <DynamicSelect 
                      id="frmClientId"
                      data={billingCompanies}
                      dataLoaded={loadedBillingCompanies}
                      value={alternativeBillingCompany}
                      valueField="id"
                      displayField="name"
                      additionalField="address.city"
                      additionalFieldBrackets={true}
                      searchUpdated={(str) => fetchBillingCompanies(str)}
                      updateValue={(value) => setAlternativeBillingCompany(value)}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="frmContactId">Contact facturatie</label>
                    <DynamicSelect 
                      data={billingContacts}
                      dataLoaded={loadedBillingContacts}
                      value={alternativeBillingContact}
                      valueField="id"
                      displayField="first_name"
                      additionalField="last_name"
                      updateValue={(value) => setAlternativeBillingContact(value)}
                      disabled={Object.keys(alternativeBillingCompany).length === 0}
                      disabledText="Please select company first"
                    />
                  </div>
                </div>
              : null }

              <div className="form-group">
                <label htmlFor="frmInvoiceEmail">Email facturatie</label>
                <input 
                  className="form-control" 
                  id="frmInvoiceEmail" 
                  name="invoiceEmail" 
                  placeholder="" 
                  value={invoiceEmail}
                  onChange={(e) => setInvoiceEmail(e.target.value)}
                />
              </div>

              { (sugInvoiceEmail && sugInvoiceEmail !== invoiceEmail ) ?
                <div className="alert alert-warning" role="alert">
                  <span><small>Volgens Teamleader heeft <i>{`${SugpaymentTermCompany ? SugpaymentTermCompany : 'dit bedrijf'}`}</i> als standaard email <b>{sugInvoiceEmail}</b>.</small></span>
                </div>
              : null }

              <div className="form-group">
                <label htmlFor="frmPaymentTerm">Betaaltermijn (dagen)</label>
                <input 
                  className="form-control" 
                  id="frmPaymentTerm" 
                  name="paymentTermp" 
                  placeholder="onbekend" 
                  type="number"
                  value={paymentTerm}
                  onChange={(e) => setPaymentTerm(e.target.value)}
                />
              </div>

              { (SugpaymentTerm && String(SugpaymentTerm) !== String(paymentTerm) ) ?
                <div className="alert alert-warning" role="alert">
                  <span><small>Volgens Teamleader heeft <i>{`${SugpaymentTermCompany ? SugpaymentTermCompany : 'dit bedrijf'}`}</i> een standaard betaaltermijn van <b>{SugpaymentTerm}</b> dagen.</small></span>
                </div>
              : null }

            </div>

            <div className="my-2 pb-5">
              <div className="border-bottom my-2">
                <b>Werk</b>
              </div>

              <div className="form-group">
                <label htmlFor="frmSiteOrderNr">Werfbonnummer</label>
                <input 
                  className="form-control" 
                  id="frmSiteOrderNr" 
                  name="siteprderNr" 
                  placeholder="" 
                  onChange={(e) => setSiteorderNr(e.target.value)} value={siteorderNr} 
                />
              </div>

              <div className="form-group">
                <label htmlFor="frmEmployee">Werknemer(s)</label>
                <input 
                  className="form-control" 
                  id="frmEmployee" 
                  name="employee" 
                  placeholder="name" 
                  onChange={(e) => setEmployee(e.target.value)} value={employee} 
                />
              </div>

              <div className="form-group">
                <label htmlFor="frmWorkTodo">uit te voeren werk</label>
                <ReactQuill 
                  theme="snow"
                  className="bg-white"
                  onChange={html => setWorkTodo(html)}
                  value={workTodo}
                  modules={WorkorderForm.modules}
                  formats={WorkorderForm.formats}
                  bounds={'.app'}
                  placeholder="placeholder"
                />
              </div>
            </div>

            <div className="my-2">
              <div className="border-bottom my-2">
                <b>Factuur</b>
              </div>

              <div className="form-group">
                <label htmlFor="frmNeedsApproval">Goedkeuring nodig</label>
                <select 
                  className="form-control" 
                  name="needsApproval" 
                  id="frmNeedsApproval" 
                  onChange={(e) => setNeedsApproval(Number(e.target.value))} 
                  value={needsApproval}
                >
                  {needsApproval === null ? <option value={null}>--</option> : null}
                  <option value="1">Ja</option>
                  <option value="0">Nee</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="frmWorkPerformed">uitgevoerde taken</label>
                <WorkList
                  list={invoiceLines}
                  onChange={list => {
                    setInvoiceLines(list)
                  }}
                  disabled={!allowChangesInvoice}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="frmInvoiceTotal">Totaalprijs</label>
              <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">€</span>
              </div>
                <input 
                  className="form-control" 
                  type="number"
                  step="0.01"
                  id="frmInvoiceTotal" 
                  name="invoiceTotal" 
                  onChange={(e) => setInvoiceTotal(Number(e.target.value))} 
                  value={invoiceTotal} 
                  disabled={!allowChangesInvoice}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <ModalImportSite 
        ref={refModalImportSite}
        onImportSite={onImportSite}
      />

      <div className="row flex-shrink-0 bg-light border-top">
        <div className="col-12 d-flex justify-content-between py-3">
          <button 
            type="button" 
            className="btn btn-success"
            onClick={() => onSubmit()}
          >Save</button>
          <div>
          { props.onDuplicate ?
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onDuplicate()}
              >Dupliceren</button>
            : null }
            { props.onArchive ?
              <button
                type="button"
                className="btn btn-danger ml-2"
                onClick={() => onArchive()}
              >Archiveren</button>
            : null }
          </div>
        </div>
      </div>
    </>
  )
}

WorkorderForm.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}

WorkorderForm.formats = [
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'background', 'color'
]


export default WorkorderForm
