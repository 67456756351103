import React, { useState, useEffect, useContext, useRef } from 'react';
import Api from '../../utils/Api';
import { authContext } from '../../context/AuthContext';
import { WorkorderContext } from '../../context/WorkorderContext';
import { useToasts } from 'react-toast-notifications';
import ModalSendProforma from './ModalSendProforma';
import ModalSendInvoice from './ModalSendInvoice';
import ModalBookInvoice from './ModalBookInvoice';
import ModalWarnBeforeProceed from './ModalWarnBeforeProceed';
import moment from 'moment';


function InvoiceHeader(props){
  const [type, setType] = useState(null);
  const [sentAt, setSentAt] = useState(null);

  useEffect(() => {
    initialize();
  }, [props.invoice]); //eslint-disable-line react-hooks/exhaustive-deps

  const initialize = () => {
    if(props.invoice?.tl_status === 'draft'){
      setType('Proforma');
      setSentAt(props.invoice?.sent_approval_at ?? null);
    } else {
      setType('Factuur');
      setSentAt(props.invoice?.sent_invoice_at ?? null);
    }
  }

  if(!props.invoice){
    return null;
  }

  return(
    <div className="row no-gutters bg-dark text-white">
      <div className="col my-2">
        <span className="p-3">
          <span className={`badge badge-${type === 'Factuur' ? 'success' : 'primary'}`}>{type}</span>
        </span>
      </div>
      <div className="col-auto my-2">
        <span className="p-3 small">{sentAt ? `${type} laatst verzonden ${moment(sentAt).calendar()}` : 'nog niet verzonden'}</span>
      </div>
    </div>
  )
}

function Invoice(props) {
  const [invoice, setInvoice] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isNotFound, setNotFound] = useState(false);
  const [base64Invoice, setBase64Invoice] = useState(null);

  const { addToast } = useToasts();

  const { refetchWorkorder } = useContext(WorkorderContext)
  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const ModalSendProformaRef = useRef();
  const ModalBookInvoiceRef = useRef();
  const ModalSendInvoiceRef = useRef();
  const ModalWarnBeforeProceedRef = useRef();
  const ModalWarnBeforeProceed2Ref = useRef();
  const ModalWarnBeforeProceed3Ref = useRef();
  const ModalWarnBeforeProceed4Ref = useRef();
  const ModalWarnBeforeProceed5Ref = useRef();

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  useEffect(() => {
    if(props.tl_invoice_id){
      fetchInvoice();
    } else {
      setLoading(false);
    }
  }, [props.tl_invoice_id, props.workorder.updated_at, props.workorder?.invoice]);  //eslint-disable-line react-hooks/exhaustive-deps

  //temp fix
  useEffect(() => {
    base64Invoice && setLoading(false);
  }, [base64Invoice]);

  //fetch invoice
  const fetchInvoice = () => {
    setNotFound(false);
    setLoading(true);
    setBase64Invoice('');

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    
    Api.get(`workorders/getInvoice/${props.tl_invoice_id}`, { headers })
      .then(res => {
        setBase64Invoice(res.data?.pdf);
        setInvoice(res.data?.invoice);
        !res.data?.pdf && setLoading(false);
      })
      .catch(err => {
        console.log(err);
        if(err.response?.status === 404){
          setNotFound(true);
        }
        setLoading(false);
      });
  }

  //on send mail
  const onSendMail = (data) => {
    Api.post(`/workorders/${props.workorder.id}/sendMail`, data, { headers })
      .then(res => {
        addToast('Mail verstuurd.', { appearance: 'success' })
        refetchWorkorder();
      })
      .catch(err => addToast('Mail versturen mislukt.', { appearance: 'error' }));
  }

  //on send letter
  const onSendLetter = (data) => {
    Api.post(`/workorders/${props.workorder.id}/sendLetter`, data, { headers })
      .then(res => {
        addToast('Gemarkeerd als verstuurd via post.', { appearance: 'success' })
        refetchWorkorder();
      })
      .catch(err => addToast('Markeren als verstuurd via post mislukt.', { appearance: 'error' }));
  }

  //on draft invoice
  const onDraftInvoice = () => {
    setLoading(true);
    Api.post(`/workorders/${props.workorder.id}/draftInvoice`, {}, { headers })
      .then(res => {
        addToast('Proforma aangemaakt.', { appearance: 'success' });
        refetchWorkorder();
        //fetchInvoice();
      })
      .catch(err => {
        addToast('Proforma aanmaken mislukt.', { appearance: 'error' });
        setLoading(false);
      });
  }

  //on book invoice
  const onBookInvoice = () => {
    setLoading(true);
    Api.post(`/workorders/${props.workorder.id}/bookInvoice`, {}, { headers })
      .then(res => {
        addToast('factuur ingeboekt.', { appearance: 'success' });
        refetchWorkorder();
        //fetchInvoice();
      })
      .catch(err => {
          addToast('factuur inboeken mislukt.', { appearance: 'error' });
          setLoading(false);
        });

    setLoading(false);
  }

  if(isLoading){
    return (
      <div className="d-flex justify-content-center">
          <span>Loading...</span>
      </div>
    )
  }

  if(isNotFound){
    return(
      <div>
        <div className="alert alert-danger" role="alert">
          <span>De gekoppelde factuur/proforma wordt niet langer teruggevonden in Teamleader. Mogelijk werdt deze verwijderd in Teamleader?</span> 
        </div>
        {/* <button 
          type="button" 
          className="btn btn-primary"
          onClick={onDraftInvoice}
        >Genereer nieuwe proforma</button> */}
      </div>
    )
  }

  if(!base64Invoice){
    return(
      <div>
        <p>Nog geen factuur aangemaakt.</p>
        { props.workorder.needs_approval === null ? (
          <>
            <div className="alert alert-danger" role="alert">
              <span>'Goedkeuring nodig (ja/nee)' is vereist</span> 
            </div>
            <button 
              disabled 
              type="button" 
              className="btn btn-primary"
              onClick={null}
            >Genereer proforma</button>
          </>
        ):(props.workorder?.company) ? (
            <button 
              type="button" 
              className="btn btn-primary"
              onClick={onDraftInvoice}
            >Genereer proforma</button>
          ):(
            <>
            <div className="alert alert-danger" role="alert">
              <span>Klant is vereist</span> 
            </div>
            <button 
              disabled 
              type="button" 
              className="btn btn-primary"
              onClick={null}
            >Genereer proforma</button>
            </>
          )
        }
      </div>
    )
  }

  return (
    <div className="d-flex flex-column h-100">
      <InvoiceHeader invoice={props.workorder?.invoice} />
      <embed
        src={`data:application/pdf;base64,${base64Invoice}`}  
        className="flex-grow-1"
        style={{width: '100%', minHeight: '300px'}}
      ></embed >
      <div className="d-flex justify-content-between pt-2">
        { invoice.status === 'draft' &&
          <>
            { props.workorder?.invoice?.sent_approval_at ?
              <>
                <button 
                  type="button" 
                  onClick={() => ModalWarnBeforeProceedRef.current.showModal()}
                  className="btn btn-secondary"
                >Verstuur Proforma</button>
                <ModalWarnBeforeProceed 
                  ref={ModalWarnBeforeProceedRef}
                  title="Proforma reeds verstuurd"
                  body={`Opgelet, deze Proforma werdt reeds verstuurd op ${props.workorder?.invoice?.sent_approval_at}, wenst u deze nogmaals te versturen?`}
                  onSubmit={() => ModalSendProformaRef.current.showModal()}
                />
              </>
            : !props.workorder?.siteorders || props.workorder?.siteorders?.length < 1 ?
              <>
                <button 
                  type="button" 
                  onClick={() => ModalWarnBeforeProceed4Ref.current.showModal()}
                  className="btn btn-secondary"
                >Verstuur Proforma</button>
                <ModalWarnBeforeProceed 
                  ref={ModalWarnBeforeProceed4Ref}
                  title="Werfbon ontbreekt"
                  body={`Deze Proforma kan niet verzonden worden zonder werfbon. Voeg eerst een werfbon toe.`}
                />
              </>
            :
              <button 
                type="button" 
                onClick={() => ModalSendProformaRef.current.showModal()}
                className="btn btn-primary"
              >Verstuur Proforma</button>
            }
            <ModalSendProforma 
              ref={ModalSendProformaRef} 
              siteorders={props.workorder?.siteorders}
              internalRef={props.workorder.internal_ref}
              siteLine1={props.workorder.site_line_1}
              sugestedRecipient={props.workorder.site_contact_email}
              onSendMail={onSendMail}
            />
            { props.workorder?.needs_approval === 1 && !props.workorder.approval ?
              <>
                <button 
                  type="button" 
                  onClick={() => ModalWarnBeforeProceed2Ref.current.showModal()}
                  className="btn btn-secondary"
                >Factuur inboeken</button>
                <ModalWarnBeforeProceed 
                  ref={ModalWarnBeforeProceed2Ref}
                  title="Nog geen goedkeuring"
                  body={`Het is niet mogelijk de factuur in te boeken zonder goedkeuring. Voeg een goedkeuring toe of verander 'goedkeuring nodig' naar nee.`}
                />
              </>
            : !props.workorder?.siteorders || props.workorder?.siteorders?.length < 1 ?
              <>
                <button 
                  type="button" 
                  onClick={() => ModalWarnBeforeProceed5Ref.current.showModal()}
                  className="btn btn-secondary"
                >Factuur inboeken</button>
                <ModalWarnBeforeProceed 
                  ref={ModalWarnBeforeProceed5Ref}
                  title="Werfbon ontbreekt"
                  body={`Het is niet mogelijk de factuur in te boeken zonder werfbon. Voeg eerst een werfbon toe.`}
                />
              </>
            :
              <>
                <button 
                  type="button" 
                  onClick={() => ModalBookInvoiceRef.current.showModal()}
                  className="btn btn-primary"
                >Factuur inboeken</button>
                <ModalBookInvoice
                  ref={ModalBookInvoiceRef} 
                  onBookInvoice={onBookInvoice}
                />
              </>
            }
            
          </>
        }

        { invoice.status === 'outstanding' && 
          <>
            {/* <a 
              className="btn btn-primary"
              href={`https://app.teamleader.eu/invoice_detail.php?id=${props.workorder.tl_invoice_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >Openen in Teamleader</a> */}
            { props.workorder?.invoice?.sent_invoice_at ?
              <>
                <button 
                  type="button" 
                  onClick={() => ModalWarnBeforeProceed3Ref.current.showModal()}
                  className="btn btn-secondary"
                >Verstuur Factuur</button>
                <ModalWarnBeforeProceed 
                  ref={ModalWarnBeforeProceed3Ref}
                  title="Factuur reeds verstuurd"
                  body={`Opgelet, deze Factuur werdt reeds verstuurd op ${props.workorder?.invoice?.sent_invoice_at}, wenst u deze nogmaals te versturen?`}
                  onSubmit={() => ModalSendInvoiceRef.current.showModal()}
                />
              </>
            : 
              <>
                <button 
                  type="button" 
                  onClick={() => ModalSendInvoiceRef.current.showModal()}
                  className="btn btn-primary"
                >Verstuur Factuur</button>
              </>
            }
            <ModalSendInvoice 
              ref={ModalSendInvoiceRef} 
              siteorders={props.workorder?.siteorders}
              internalRef={props.workorder.internal_ref}
              invoiceNr={props.workorder?.invoice?.tl_invoice_nr}
              siteLine1={props.workorder.site_line_1}
              sugestedRecipient={props.workorder.invoice_email}
              approval={props.workorder.approval}
              onSendMail={onSendMail}
              onSendLetter={onSendLetter}
            /> 
          </>
        }
      </div>
    </div>
  )
}

export default Invoice
