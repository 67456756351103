import React, { useState, useEffect, useRef } from 'react';
import ModalDeleteSite from './modals/ModalDeleteSite';

function Form(props) {
  const [ name, setName ] = useState('');
  const [ line1, setLine1 ] = useState('');
  const [ postalCode, setPostalCode ] = useState('');
  const [ city, setCity ] = useState('');
  const [ country, setCountry ] = useState('BE');

  const ModalDeleteSiteRef = useRef();

  useEffect(() => {
    console.log(props.site)
    if(props.site){
      setName(props.site?.name ?? '');
      setLine1(props.site?.address?.line_1 ?? '');
      setPostalCode(props.site?.address?.postal_code ?? '');
      setCity(props.site?.address?.city ?? '');
      setCountry(props.site?.address?.country ?? 'BE');
    }
  }, [props.site])

  // submit
  const onSubmit = (e) => {
    e.preventDefault();

    const data = {
      'name': name,
      'line_1': line1,
      'postal_code': postalCode,
      'city': city,
      'country': country,
    };

    props.onSubmit(data);
  }

  const onDelete = () => {
    props.onDelete(props.site?.id);
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="frmName">name</label>
          <input 
            className="form-control" 
            name="name" 
            id="frmName" 
            onChange={(e) => setName(e.target.value)} 
            value={name}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="frmName">line1</label>
          <input 
            className="form-control" 
            name="line1" 
            id="frmLine1" 
            onChange={(e) => setLine1(e.target.value)} 
            value={line1}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="frmPostalCode">postal code</label>
          <input 
            className="form-control" 
            name="postalCode" 
            id="frmPostalCode" 
            onChange={(e) => setPostalCode(e.target.value)} 
            value={postalCode}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="frmCity">city</label>
          <input 
            className="form-control" 
            name="city" 
            id="frmCity" 
            onChange={(e) => setCity(e.target.value)} 
            value={city}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="frmCountry">country</label>
          <input 
            className="form-control" 
            name="country" 
            id="frmCity" 
            onChange={(e) => setCountry(e.target.value)} 
            value={country} 
            disabled
          />
        </div>

        <div className="d-flex justify-content-between">
          <button 
            className="btn btn-success"
            type="submit"
          >Save</button>

          { props.site ? 
            <button 
              className="btn btn-danger"
              type="button"
              onClick={() => ModalDeleteSiteRef.current.showModal()}
            >Delete</button>
          : null }
        </div>
        { props.error && <p className="text-danger">{props.error}</p> }
      </form>

      <ModalDeleteSite
        ref={ModalDeleteSiteRef}
        title="Verwijderen site"
        body={`Bent u zeker dat u het werf met naam "${name}" wenst te verwijderen?`}
        onSubmit={onDelete}
      />

    </div>
  )
}

export default Form
