import React from 'react';
import { Link } from 'react-router-dom';

function Badge(props) {
  return (
    <span className={`badge badge-${props.variant ?? 'light'} w-100 text-center`}>{props.text}</span>
  )
}

function Item(props) {

  //badge proforma
  const badgeProforma = (workorder) => {
    if(workorder.status_proforma === 'ok'){
      return <Badge variant="success" text="PF"/>;
    }

    return <Badge text="PF"/>;
  }

  //badge approval
  const badgeApproval = (workorder) => {
    if(workorder.status_approval === 'approved'){
      return <Badge variant="success" text="GK"/>;
    }

    if(workorder.status_approval === 'requested'){
      return <Badge variant="info" text="GK"/>;
    }

    if(workorder.status_approval === 'required'){
      return <Badge variant="warning" text="GK"/>;
    }

    if(workorder.status_approval === 'not required'){
      return <Badge variant="success" text="GK"/>;
    }

    return <Badge text="GK"/>;
  }

  //badge invoice
  const badgeInvoice = (workorder) => {
    if(workorder.status_invoice === 'paid'){
      return <Badge variant="success" text="F"/>;
    }

    if(workorder.status_invoice === 'sent'){
      return <Badge variant="info" text="F"/>;
    }

    if(workorder.status_invoice === 'booked'){
      return <Badge variant="warning" text="F"/>;
    }

    return <Badge text="F"/>;
  }

  //badge siteorder
  const badgeSiteorder = (workorder) => {
    if(workorder.siteorders_count){
      return <Badge variant="success" text="W"/>;
    }

    return <Badge text="W"/>;
  }

  return (
    <Link 
      key={props.workitem?.id} 
      className="card bg-light mb-2" 
      style={{textDecoration: 'none', color: 'inherit'}}
      to={`/workorders/${props.workitem?.id}`} 
    >
      <div className="card-body p-3" style={{fontSize: '85%', lineHeight: '1.2'}}>
        
        <div className="d-flex justify-content-start">
          <b className="mr-1">{props.workitem?.internal_ref}</b>

          { props.workitem?.is_category_drilling ? 
            <div>
              <div className="badge badge-success mr-1">B</div>
            </div>
          : null }

          { props.workitem?.is_category_sawing ? 
            <div>
              <div className="badge badge-warning mr-1">Z</div>
            </div>
          : null }

          { props.workitem?.is_category_various ? 
            <div>
              <div className="badge badge-info mr-1">D</div>
            </div>
          : null }
          
        </div>
        <hr className="my-2"/>
        <div className="d-flex justify-content-start mb-1">
          <div className="mr-1">{badgeProforma(props.workitem)}</div>
          <div className="mr-1">{badgeApproval(props.workitem)}</div>
          <div className="mr-1">{badgeInvoice(props.workitem)}</div>
          <div className="mr-1">{badgeSiteorder(props.workitem)}</div>
        </div>
        <p className="mb-1">
          { props.workitem?.company?.name ?
            <b>{ props.workitem?.company?.name }</b>
          : 
            <span className="text-muted" style={{ fontSize: '90%' }}>klant onbekend</span> 
          }
        </p>
        <div style={{ fontSize: '90%' }}>
          { (!props.workitem?.site_line_1 && !props.workitem?.site_city && !props.workitem?.site_postal_code) ?
            <span className="text-muted">werf onbekend</span> 
          :
            <>
              {/* <p className="mb-0"><i>Werf naam</i></p> */}
              { props.workitem?.site_line_1 ?
                <p className="mb-0">{ props.workitem?.site_line_1 }</p>
              : null }
              { (props.workitem?.site_city || props.workitem?.site_postal_code) ?
                <p className="mb-0">{ `${props.workitem?.site_city ?? ""} ${props.workitem?.site_postal_code ?? ""}`}</p>
              : null }
              { props.workitem?.site_country ?
                <p className="mb-0">{props.workitem?.site_country}</p>
              : null }
            </>
          }
        </div>

        {
          props.workitem?.employee ?
          <>
            <hr className="my-2"/>
            <div style={{ fontSize: '90%' }}>
              <p className="mb-0">- {props.workitem?.employee}</p>
            </div>
          </>
        : null }
        
      </div>
    </Link>
  )
}

export default Item
