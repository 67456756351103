import React from 'react';
import MailAttachment from './MailAttachment';

function MailAttachments(props) {

  if (!props.attachments || props.attachments.length <= 0){
    return (
      <p className="text-muted">geen bijlagen</p>
    )
  }

  return (
    <div>
      {props.attachments.map(attachment => 
        <MailAttachment 
          key={attachment.id}
          attachment={attachment}
          importedPhotos={props.importedPhotos}
          onImportSiteorder={props.onImportSiteorder}
          onImportApproval={props.onImportApproval}
          onImportPhoto={props.onImportPhoto}
          onImportCompanyLogo={props.onImportCompanyLogo}
          onRemovePhoto={props.onRemovePhoto}
        />
      )}
    </div>
  )
}

export default MailAttachments