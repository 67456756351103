import React from 'react';
import moment from 'moment';

const createTimeString = (date) => {
  const now = Date.now();
  const dateArr = moment(date).format('YYYY|MM|DD|ddd|hh|mm').split('|');
  const todayArr = moment(now).format('YYYY|MM|DD|').split('|');

  const sameYear = (dateArr[0] === todayArr[0]) ? true : false;
  const sameMonth = (dateArr[1] === todayArr[1]) ? true : false;

  if (sameYear && sameMonth && todayArr[2] === dateArr[2]){
    return `vandaag ${dateArr[4]}:${dateArr[5]}`;
  } else if(sameYear && sameMonth && todayArr[2] - dateArr[2] < 2){
    return `gisteren ${dateArr[4]}:${dateArr[5]}`;
  } else {
    return `${dateArr[2]}/${dateArr[1]}`;
  }
}

function List(props) {
  if(!props.mails || props.mails.length <= 0){
    return(
      <span className="text-muted">Geen emails</span>
    )
  }

  return (
    <div className="list-group">
    { props.mails.map((mail, i) => 
      <div 
        key={i}
        className={`list-group-item list-group-item-action ${props.selectedMail === mail.id && "bg-light"}`} 
        onClick={() => {
          props.selectMail(mail.id);
          props.selectedMailDetails({
            id: mail.id ?? null,
            from: mail.from ?? null,
            received_at: mail.received_at ?? null,
            subject: mail.subject ?? null,
            body: mail.body ?? null,
          });
        }}
      >
        <div className="d-flex w-100 justify-content-between">
          <small className="mb-1 overflow-hidden w-100 mr-2">
            {/* <span className={`badge ${mail.from.includes('@zaniboo.be') ? 'badge-light' : 'badge-warning'}`}>{mail.from}</span> */}
            <span className="">{mail.from}</span>
          </small>
          <small className="text-muted text-nowrap">{createTimeString(mail.received_at)}</small>
        </div>
        <p className="mb-1">{mail.subject}</p>
        {mail.categories && mail.categories[0] && 
          <div className="d-flex justify-content-start">
            <small className="mb-1 mr-1">
              <span className={`badge badge-${mail.categories[0].includes("Goedkeuring") ? 'success' : mail.categories[0].includes("Offerte") ? 'danger' : mail.categories[0].includes("Werkbon") ? 'warning' : 'light'} `}>{mail.categories[0]}</span>
            </small>
            <small className="mb-1 mr-1">
              <span className={`badge badge-light`}>{mail.mailbox}</span>
            </small>
          </div>
        }
        {/* <small className="text-muted">Werkbon</small> */}
      </div>
    )}
    </div>
  )
}

export default List
