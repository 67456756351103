import React from 'react';
import { NavLink } from 'react-router-dom';

function SidebarLink(props) {
  return (
    <NavLink 
      to={props.to} 
      exact={props.exact ?? false}
      activeClassName="active"
      className="nav-item"
    >
      <div className="nav-link collapsed">
        <div className="nav-link-icon">
          { props.children }
        </div>
        <span className="nav-link-text">{props.text}</span>
      </div>
    </NavLink>
  )
}

export default SidebarLink
