import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Tab, Card, Nav, Dropdown, DropdownButton } from 'react-bootstrap';
import { InboxContext } from '../../context/InboxContext';
import { authContext } from '../../context/AuthContext';
import { useToasts } from 'react-toast-notifications';
import Api from '../../utils/Api';
import MailBody from './MailBody';
import MailAttachments from './MailAttachments';
import ModalAddToWorkorder from './ModalAddToWorkorder';
import ModalArchiveMail from '../../components/mail/ModalArchiveMail';

function MailDetail(props) {
  const [mail, setMail] = useState({});
  const [mailErr, setMailErr] = useState(null);
  const [mailLoading, setMailLoading] = useState(false);

  const { addToast } = useToasts();
  const { auth } = useContext(authContext);

  const { fetchInbox } = useContext(InboxContext)

  const modalAddToWorkorderRef = useRef();
  const modalArchiveMailRef = useRef();

  const token = auth.data.access_token;

  const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json'
  }

  const history = useHistory();

  useEffect(() => {
    if (props.selectedMail){  
      fetchMail();
    }
  }, [props.selectedMail]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchMail = () => {
    setMailLoading(true);
    setMailErr(null);
    setMail(null);

    Api.get(`/mails/${props.selectedMail}`, { headers })
    .then(res => {
      setMail(res.data);
      setMailLoading(false);
    })
    .catch(err => {
      console.log(err);
      setMailErr(err.response?.status);
      setMailLoading(false);
    });

  }

  //update mail workorder
  const onUpdateWorkorder = (workorderId = null) => {
    Api.patch(`mails/${mail.id}`, { workorder_id: workorderId }, { headers })
      .then(res => { 
        addToast('Mail toegevoegd aan werkbon.', { appearance: 'success' });
        //window.location.href = `/workorders/${res.data?.workorder?.id}`;
        history.push(`/workorders/${res.data?.workorder?.id}`)
      })
      .catch(err => addToast('Mail toegevoegd aan werkbon mislukt.', { appearance: 'error' }));
  }

  //archive mail
  const onArchiveMail = () => {
    const mailId = mail?.id ?? props.selectedMailDetails?.id;
    
    if(mailId){
      Api.delete(`mails/${mailId}`, { headers })
        .then(res => { 
          addToast('Mail gearchiveerd.', { appearance: 'success' });
          setMail(null);
          setMailErr(null);
          fetchInbox();
        })
        .catch(err => addToast('Mail archiveren mislukt.', { appearance: 'error' }));
    }else{
      addToast('Mail archiveren mislukt.', { appearance: 'error' });
    }
  }


  if(mailErr === 404){
    return (
      <div className="h-100 d-flex flex-column"> 
        <div className="card" style={{ minHeight: 0, overflowY: 'auto' }}>
          <div className="card-header bg-light border-bottom-0">

            <div className="row">
              <div className="col">
                <span>{props.selectedMailDetails?.from ?? '---'}</span>
              </div>
              <div className="col-auto">
                <span>{props.selectedMailDetails?.received_at ?? '---'}</span>
              </div>
            </div>

            <div className="row">
              <div className="col">
                  <h2>{props.selectedMailDetails?.subject ?? '---'}</h2>
              </div>
            </div>
          </div>

          <Tab.Container id="mail-tabs" activeKey="body" transition={false}>

            <Card.Header className="bg-light border-bottom pb-0">
              <div className="d-flex justify-content-between">

                <Nav variant="tabs" className="border-0 mb-0">
                  <Nav.Item>
                    <Nav.Link eventKey="body">
                      <span>Bericht</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <button
                  className="btn btn-danger"
                  onClick={() => modalArchiveMailRef.current.showModal()}
                >Archiveren</button>

              </div>

              <ModalArchiveMail 
                ref={modalArchiveMailRef} 
                onArchiveMail={onArchiveMail}
              />

            </Card.Header>

            <Card.Body style={{overflowY: 'auto'}}>
              <Tab.Content className="h-100">
                <Tab.Pane eventKey="body" className="h-100">
                  <div className="alert alert-danger" role="alert">
                    <span>Deze mail kan niet meer worden gevonden. Mogelijk werdt deze verwijderd in Outlook?</span>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>

          </Tab.Container>
        </div>
      </div>
    )
  }

  if((!props.selectedMail || !mail) && !mailLoading){
    return (
      <div className="card">
        <div className="card-body">
          <span className="text-muted">Klik op een mail om deze te bekijken.</span>
        </div>
      </div>
    )
  }

  return (
    <div className="h-100 d-flex flex-column"> 
      <div className="card" style={{ minHeight: 0, overflowY: 'auto' }}>
        <div className="card-header bg-light border-bottom-0">

          <div className="row">
            <div className="col">
              <span>{mailLoading ? (props.selectedMailDetails?.from ?? '-') : mail.from}</span>
            </div>
            <div className="col-auto">
              <span>{mailLoading ? (props.selectedMailDetails?.received_at ?? '-') : mail.received_at}</span>
            </div>
          </div>

          <div className="row">
            <div className="col">
                <h2>{mailLoading ? (props.selectedMailDetails?.subject ?? '-') : mail.subject}</h2>
            </div>
          </div>
        </div>

        <Tab.Container id="mail-tabs" defaultActiveKey="body" transition={false}>

          <Card.Header className="bg-light border-bottom pb-0">
            <div className="d-flex justify-content-between">

              <Nav variant="tabs" className="border-0 mb-0">
                <Nav.Item>
                  <Nav.Link eventKey="body">
                    <span>Bericht</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="attachments">
                    <span> 
                      <span className="mr-1">Bijlagen</span>
                      { mailLoading ? 
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                      :
                        <span className="badge badge-primary">{mail.attachments && mail.attachments.length}</span>
                      }
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <DropdownButton id="inbox-mail-dropdown" title="opties">
                { !mailLoading && mail ?
                  <>
                    <Dropdown.Item 
                      eventKey="body"
                      as="button"
                      onClick={() => modalAddToWorkorderRef.current.showModal()}
                    >Naar bestaande opdrachtbon</Dropdown.Item>
                    <Dropdown.Item 
                      eventKey="body"
                      as={Link}
                      to={{
                        pathname: "/workorders/create",
                        createWithMailId: mail.graph_id
                      }}
                    >Naar nieuwe opdrachtbon</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item 
                      eventKey="body"
                      onClick={() => modalArchiveMailRef.current.showModal()}
                    >Archiveren</Dropdown.Item>
                  </>
                :
                  <Dropdown.Header>Dropdown header</Dropdown.Header>
                }
              </DropdownButton>

            </div>

            { !mailLoading && mail ?
              <ModalAddToWorkorder 
                ref={modalAddToWorkorderRef} 
                mailId={mail.id}
                updateMailWorkorder={onUpdateWorkorder}
              />
            : null }

            <ModalArchiveMail 
              ref={modalArchiveMailRef} 
              onArchiveMail={onArchiveMail}
            />

          </Card.Header>

          <Card.Body style={{overflowY: 'auto'}}>
            <Tab.Content className="h-100">
              <Tab.Pane eventKey="body" className="h-100">
                {mailLoading ? 
                  props.selectedMailDetails?.body ?
                    <MailBody 
                      body={props.selectedMailDetails?.body} 
                    /> 
                  :
                  <p className="text-muted">Loading</p>
                :
                  <MailBody 
                    body={mail.body} 
                  />
                }
                
              </Tab.Pane>
              <Tab.Pane eventKey="attachments" className="h-100">
                { mailErr ? 
                  <div className="alert alert-danger" role="alert">
                    <span>Er was een probleem bij het laden van de mail (code: {mailErr}).</span>
                  </div>
                : mailLoading ?
                  <p className="text-muted">Loading..</p>
                : 
                  <MailAttachments 
                    attachments={mail.attachments} 
                    mailGraphId={mail.graph_id}
                  />
                }
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>

        </Tab.Container>
      </div>
    </div>
  )
}

export default MailDetail
