import React from 'react';
import Oauth from './Oauth';

function Azure() {
  return (
    <Oauth 
      name="Microsoft"
      authUrl="/azure/authenticate"
      redirUrl="/azure/redirecturl"
    />
  )
}

export default Azure
