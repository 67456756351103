import React from 'react';
import MailAttachment from '../../components/mail/MailAttachment';

function MailAttachments(props) {
  if (!props.attachments || props.attachments.length <= 0){
    return (
      <p className="text-muted">geen bijlagen</p>
    )
  }

  return (
    <div className="list-group">
      {props.attachments.map(attachment => 
          <MailAttachment 
            key={attachment.id}
            attachment={attachment}
          />
      )}
    </div>
  )
}

export default MailAttachments
