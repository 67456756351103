import React, { useState, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalArchiveMail = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);

  //modal handlers
  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //on submit
  const onArchive = () => {
    props.onArchiveMail();
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Mail archiveren</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Bent u zeker dat u deze mail wenst te archiveren? Deze mail zal niet meer worden weergegeven in de Postvak IN.</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="danger" onClick={onArchive}>
          Archiveren
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

export default ModalArchiveMail