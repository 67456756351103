import React, { useState, useEffect } from 'react'

function DynamicSuggestion(props) {
  const [isFocused, setFocused] = useState(false);
  const [filteredData, setFilteredData] = useState(props.data);
  const [value, setValue] = useState(props.value);

  const hasValue = false;

  const sanitizeString = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g,'').toLowerCase();
  }

  useEffect(() => {
    if(props.data){
      let i = 0;

      const newData = props.data.filter((item) => {
        if(i < 10 && sanitizeString(JSON.stringify(item)).includes(sanitizeString(value))){
          i++;
          return item;
        }
        return null;
      });

      setFilteredData(newData);
    }
  }, [value, props.data]);

  useEffect(() => {
    const handleUpdate = () => {
      if(props.searchUpdated){
        props.searchUpdated(value);
      };
    }

    //debounce
    const timer = setTimeout(() => {
      handleUpdate();
    }, 300);

    return () => clearTimeout(timer);
  }, [value]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue(props.value);
  }, [props.value]) //eslint-disable-line react-hooks/exhaustive-deps

  const styleOpened = {
    'position': 'absolute', 
    'overflowY': 'scroll',
    'maxHeight' : '150px',
    'transform': 'translate3d(0px, 38px, 0px)', 
    'top': '0px', 
    'left': '0px',
    'width': '100%', 
    'willChange': 'transform'
  }

  const asignValue = (newValue) => {
    props.updateValue(newValue);
  }

  if(props.disabled){
    return (
      <div
        className="form-control text-muted bg-muted"
        style={{'backgroundColor': '#e9ecef'}}
        disabled
      >
        <span className="mr-2">{ props.disabledText }</span>
      </div>
    )
  }

  // if(!props.dataLoaded && !hasValue){
  //   return (
  //     <div
  //       className="form-control text-muted bg-muted"
  //       style={{'backgroundColor': '#e9ecef'}}
  //       disabled
  //     >
  //       <span className="mr-2">fetching</span>
  //       <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  //     </div>
  //   )
  // }


  //get deep object value
  const getObjectValue = (object, keyStr) => {
    const keys = keyStr.split('.');
    let result = object;
    
    keys.forEach(key => {
      if(!result){
        return null;
      }
      result = result[key]
    });

    return result;
  }

  //create string from displayField and additionalField
  const selectedValue = (object) => {
    //additional field needs brackets
    const additionalFieldSeparator = () => {
      if (props.additionalFieldBrackets){
        return [' (', ')'];
      } else {
        return [' ', ''];
      }
    }

    if (props.additionalField && getObjectValue(object, props.additionalField)){
      return object[props.displayField] + additionalFieldSeparator()[0] + getObjectValue(object, props.additionalField) + additionalFieldSeparator()[1];
    }else{
      return object[props.displayField];
    }
  }

  return (
    <div className="dropdown show">
      <input 
        placeholder="type to search.."
        autoComplete="off"
        type="text" 
        name="test"
        className={`form-control dropdown-toggle`} 
        id={props.id ?? null} 
        onChange={(e) => setValue(e.target.value)} 
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={value}
      />


      <label  
        type='button' 
        className="h-100 btn btn-link text-muted"
        style={{'position' : 'absolute', 'top': '0', 'right': '0'}}
        onClick={() => setFocused(true)}
      >
        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>
      </label >
      

      { (!hasValue && isFocused) && 
        <div className="dropdown-menu show shadow" aria-labelledby="dropdownMenuButton" x-placement="bottom-start" style={isFocused && styleOpened}>
          { filteredData.map(item => 
            <button 
              type="button" 
              key={item.id} 
              className="dropdown-item" 
              href="#" 
              onMouseDown={() => asignValue(item)}
            >
              <span>{selectedValue(item)}</span>
            </button>
          )}
        </div>
      }
    </div>
  )
}

export default DynamicSuggestion
