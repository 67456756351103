import React, { useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import ModalImportCompanyLogo from './ModalImportCompanyLogo';

function MailAttachment(props) {
  const modalImportCompanyLogoRef = useRef();
  
  const getFileExt = (str) => {
    if (str && str.includes('.')){
      return str.split('.').slice(-1)[0];
    } else {
      return null;
    }
  }

  const getAttachmentExt = getFileExt(props.attachment.name);

  //pdf
  if(props.attachment.contentType === "application/pdf" || (props.attachment.contentType === "application/octet-stream" && getAttachmentExt === 'pdf')){
    return (
      <div className="list-group-item">
        <div className="pb-1 d-flex justify-content-between">
          <span className="text-muted">{props.attachment.name}</span>
          { (props.onImportSiteorder && props.onImportApproval) ? 
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Importeer PDF als
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item 
                  as="button"
                  onClick={() => props.onImportSiteorder(props.attachment.id)}
                >werfbon</Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => props.onImportApproval(props.attachment.id)}
                >goedkeuring</Dropdown.Item>
                <Dropdown.Item 
                as="button"
                onClick={() => props.onImportPhoto(props.attachment.id)}
                >foto</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          : null}
        </div>
        <embed 
          title="attachment-pdf"
          src={`data:application/pdf;base64,${props.attachment.contentBytes}`} 
          style={{ width: '100%', height: '400px' }}
        />
      </div>
    )
  }

  return (
    <div className="list-group-item">
      <div className="pb-1 d-flex justify-content-between">
        <div>
          <span className="text-muted">{props.attachment.name}</span>
          { props.importedPhotos && props.importedPhotos.some(importedPhoto => importedPhoto.graph_attachment_id === props.attachment.id) 
          ? <span className="badge badge-success ml-1">Geimporteerd</span> 
          : null
          }
        </div>

        { (props.onImportPhoto && props.onRemovePhoto) ? 
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            Opties
          </Dropdown.Toggle>

          <Dropdown.Menu>
            { props.importedPhotos.some(importedPhoto => importedPhoto.graph_attachment_id === props.attachment.id) 
            ? 
              <Dropdown.Item 
              as="button"
              onClick={() => props.onRemovePhoto(props.attachment.id)}
              >Importeren ongedaan maken</Dropdown.Item>
            : 
              <>
                <Dropdown.Item 
                as="button"
                onClick={() => props.onImportPhoto(props.attachment.id)}
                >Importeer foto</Dropdown.Item>
                { props.onImportCompanyLogo ? 
                  <>
                    <Dropdown.Item 
                      as="button"
                      onClick={() => modalImportCompanyLogoRef.current.showModal()}
                    >Importeer als logo</Dropdown.Item>
                    <ModalImportCompanyLogo 
                      ref={modalImportCompanyLogoRef} 
                      attachmentId={props.attachment.id}
                      onSubmit={props.onImportCompanyLogo}
                    />
                  </>
                : null}
                
              </>
          }
          </Dropdown.Menu>
        </Dropdown> 
        : null }



      </div>
      <div className="p-1">
        <img 
          className="img-fluid" 
          src={`data:image/${getAttachmentExt ?? 'gif'};base64,${props.attachment.contentBytes}`}
          alt="attachment" 
        />
      </div>
    </div>
  )
}

export default MailAttachment
