import React, { useState, useEffect, useContext, useRef } from 'react';
import { authContext } from '../../context/AuthContext';
import { useToasts } from 'react-toast-notifications';
import WorkorderRef from '../../components/formatting/WorkorderRef';
import Api from '../../utils/Api';
import moment from 'moment';
import Table from '../../components/table';
import Badge from '../../components/badge';
import ModalSendReminder from './modals/sendReminder';

function ColumnError(val){
  return (
    <span className="text-danger">{val}</span>
  )
}

function BadgeInvoice(date){
  if(!date){
    return (
      ColumnError('onbekend')
    );
  }

  return (
    <Badge variant='info' text={date} />
  )
}

//table formatters
const RefFormatter = ({value, row}) => {
  if(row.workorder?.internal_ref){
    return <WorkorderRef internalRef={row.workorder?.internal_ref} />;
  }
  return ColumnError('onbekend');
}

const ClientFormatter = ({value, row}) => {
  if(row.tl_data?.invoicee?.name){
    return <span>{row.tl_data?.invoicee?.name}</span>;
  }
  return ColumnError('onbekend');
}

const InvoiceNrFormatter = ({value, row}) => {
  if(row.tl_data?.invoice_number){
    return <span>{row.tl_data?.invoice_number}</span>;
  }
  return ColumnError('onbekend');
}

const InvoiceTotalFormatter = ({value, row}) => {
  return (
    <div>
      <span>{row.tl_data?.total?.payable?.amount ?? '-'} </span>
      <span>{row.tl_data?.total?.payable?.currency ?? '-'}</span>
    </div>
  )
}

const InvoiceDueFormatter = ({value, row}) => {
  return (
    <div>
      <span>{row.tl_data?.total?.due?.amount ?? '-'} </span>
      <span>{row.tl_data?.total?.due?.currency ?? '-'}</span>
    </div>
  )
}

const InvoiceSentAtFormatter = ({value, row}) => {
  return BadgeInvoice(row.sent_invoice_at);
}

const InvoiceDueAtFormatter = ({value, row}) => {
  if(row.tl_data?.due_on){
    return (<span>{row.tl_data?.due_on}</span>);
  }
  return(ColumnError('onbekend'));
}

const InvoiceDaysDueFormatter = ({value, row}) => {
  return(<Badge variant="danger" text={row.days_due} />);
}

const InvoiceRemindersFormatter = ({value, row}) => {
  if(!row.payment_reminders_count || row.payment_reminders_count < 1){
    return(<span>geen</span>);
  }

  return(
    <div>
      {row.payment_reminders.map(reminder => 
        <Badge key={reminder.id} variant="danger" padding="py-1" text={`[${reminder.type}] ${moment(reminder.sent_at).format('YYYY-MM-DD')}`} />
      )}
    </div>
  );
}


function List() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterTerm, setFilterTerm] = useState('');
  const [sortBy, setSortBy] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);

  const { auth } = useContext(authContext);
  const token = auth.data.access_token;

  const { addToast } = useToasts();

  const modalSendReminderRef = useRef();

  const InvoiceSendFormatter = ({value, row}) => {
    return(
      <button 
        className="btn btn-primary"
        onClick={() => modalSendReminderRef.current.showModal(row)}
      >Stuur</button>
    );
  }

  const columns = [
    { 
      key: 'workorder.internal_ref',
      name: 'ref', 
      sortable: true, 
      formatter: RefFormatter 
    },
    { 
      key: 'company.name',
      name: 'klant', 
      sortable: true, 
      formatter: ClientFormatter
    },
    { 
      key: 'tl_data.invoice_number',
      name: 'factuur', 
      sortable: true, 
      formatter: InvoiceNrFormatter
    },
    { 
      key: 'tl_data.total.due.amount',
      name: 'totaalbedrag', 
      sortable: true, 
      formatter: InvoiceTotalFormatter
    },
    { 
      key: 'tl_data.total.payable.amount',
      name: 'openstaand', 
      sortable: true, 
      formatter: InvoiceDueFormatter
    },
    { 
      key: 'sent_invoice_at',
      name: 'verzenddatum', 
      sortable: true, 
      formatter: InvoiceSentAtFormatter
    },
    { 
      key: 'tl_data.due_on',
      name: 'vervaldatum', 
      sortable: true, 
      formatter: InvoiceDueAtFormatter
    },
    { 
      key: 'tl_data.due_on',
      name: 'dagen vervallen', 
      sortable: true, 
      formatter: InvoiceDaysDueFormatter
    },
    {
      key: 'payment_reminders_count',
      name: 'herinneringen',
      sortable: true,
      formatter: InvoiceRemindersFormatter
    },
    {
      name: '',
      sortable: false,
      formatter: InvoiceSendFormatter
    }
  ]

  useEffect(() => {
    fetchInvoices();
  }, [sortBy, sortAsc]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //debounce
    const timer = setTimeout(() => {
      fetchInvoices();
    }, 350);

    return () => clearTimeout(timer);
  }, [filterTerm]) //eslint-disable-line react-hooks/exhaustive-deps

  const fetchInvoices = () => {
    setLoading(true);

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    //prepare params
    let params = {};
    let term = null;

    if(filterTerm && filterTerm !== ''){
      term = filterTerm;
      console.log(filterTerm)
    }

    params['filter'] = {};

    if(term){
      params['filter']['term'] = term;
    }

    if(sortBy){
      const sort = {
        field: sortBy,
        order: sortAsc ? 'asc' : 'desc'
      }

      params['sort'] = sort;
    }

    //get due invoices
    Api.get("/invoices/getInvoicesDue", { headers, params })
      .then(res => {
        console.log(res.data);
        setInvoices(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }

  //send reminder mail
  const sendReminderMail = (data) => {
    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.post("invoices/sendPaymentReminder", data, { headers })
      .then(res => {
        addToast('Betaalherinnering verstuurd.', { appearance: 'success' });
        fetchInvoices();
      })
      .catch(err => {
        console.log(err);
        addToast('Betaalherinnering versturen mislukt.', { appearance: 'error' });
      });
  }

  return (
    <div className="h-100 d-flex flex-column">
      <ModalSendReminder 
        ref={modalSendReminderRef}
        onSendMail={sendReminderMail}
      />

        {/* toolbar */}
        <div className="row mb-3 mt-1">
        <div className="col">
          <div className="d-flex justify-items-start align-items-center">
          <div>
          <form className="form-inline">
            <div className="input-group">
              <input 
                type="text" 
                value={filterTerm}
                className="form-control form-control-sm" 
                placeholder="Zoeken.." 
                onChange={(e) => setFilterTerm(e.target.value)}
              />
            </div>
          </form>
          </div>
          </div>
        </div>
      </div>

      {/* <p>sortBy: {sortBy}</p>
      <p>sortAsc: {sortAsc ? 'yes' : 'no'}</p>
      <p>filterTerm: {filterTerm}</p> */}

      <Table 
        columns={columns} 
        data={invoices}
        sortBy={sortBy}
        sortAsc={sortAsc}
        setSortBy={setSortBy}
        loading={loading}
        invertSortDir={() => setSortAsc(!sortAsc)}
        onRowClick={(row) => {}}
      />
    </div>
  )
}

export default List
