import React from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import Agenda from '../../components/agenda'

function Home() {

  return (
    <div className="App">
      <Dashboard>
        <div className="row">
          <div className="col">
            <h1>Dashboard</h1>
          </div>
          <div className="col-auto">
            {/* <button className="btn btn-secondary">
              <span>Toevoegen</span>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
            </button> */}
          </div>
        </div>
        
        <div className="row">
          <div className="col-12 pb-3">
            <Agenda />
          </div>
          {/* <div className="col-12 pb-3">
            <div className="card dashboard-widget">
              <div className="card-body">
                Postvak IN
              </div>
            </div>
          </div> */}
        </div>
      </Dashboard>
    </div>
  );
}

export default Home;
