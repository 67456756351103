import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill';

const ModalSendProforma = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const defaultBody = `<p>Beste,</p>
  <br>
  <p>Hierbij sturen wij u de proforma van onze werfbon.<br>
  Mogen wij u vragen bijgevoegde proforma voor akkoord te willen tekenen en ons terug te sturen.<br>
  Of ons een bestelbon te bezorgen met vermelding van onze werfbonnummer.<p>
  <br>
  <p>Indien wij geen getekende versie/bestelbon terug ontvangen binnen de 7 werkdagen, gaan wij ervan uit dat er geen opmerkingen zijn en zullen de werken zoals vermeld op de proforma gefactureerd worden. Graag ook de facturatiegegevens na te kijken.</p>
  <br>
  <p><span style="color: rgb(240, 102, 102);">Gelieve uw bevestiging te sturen naar office@zaniboo.be .</span></p>
  <br>
  <p>Met vriendelijke groet,<br>
  Zaniboo BV</p>`

  //initialize modal
  useEffect(() => {
    initializeState();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const initializeState = () => {
    setBody(defaultBody);
    setRecipient(props.sugestedRecipient ? props.sugestedRecipient : '');
    setSubject(`Goedkeuring Proforma - Werkbon ${props.internalRef}`);
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = () => {
    const data = {
      recipient: recipient,
      subject: subject,
      body: body,
    };

    props.onSendMail(data);
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Verstuur Proforma</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="frmRecipient">email begunstigde</label>
          <input 
            type="email"
            id="frmRecipient" 
            className="form-control"
            name="recipient" 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
          { (props.sugestedRecipient && recipient !== props.sugestedRecipient) &&
            <small id="recipientHelp" className="form-text text-muted">voorgesteld: {props.sugestedRecipient}</small>
          }
        </div>
        <div className="form-group">
          <label htmlFor="frmSubject">onderwerp</label>
          <input 
            type="text"
            id="frmSubject" 
            className="form-control"
            name="subject" 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <label>Bijlagen</label>
          </div>

          <div className="col-6">
            <div className="alert alert-primary" role="alert">
              <span>{`factuur-${props.internalRef}`}</span> 
            </div>
          </div>

          { props.siteorders.length > 0 && props.siteorders.map((siteorder, i) => 
            <div className="col-6" key={siteorder.id}>
              <div className="alert alert-primary" role="alert">
                { props.siteorders.length > 1 ? 
                  <span>{`werfbon-${props.internalRef}_${i+1}`}</span> 
                :
                  <span>{`werfbon-${props.internalRef}`}</span> 
                }
              </div>
            </div>
          )}
          
        </div>
        <div className="form-group">
          <label htmlFor="frmBody">Bericht</label>
          <ReactQuill 
            theme="snow"
            onChange={html => setBody(html)}
            value={body}
            modules={ModalSendProforma.modules}
            formats={ModalSendProforma.formats}
            bounds={'.app'}
            placeholder="placeholder"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Versturen
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

ModalSendProforma.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}

ModalSendProforma.formats = [
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'background', 'color'
]

export default ModalSendProforma
