import React, { useState, useEffect, useContext, useRef } from 'react';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';
import Item from '../../components/agenda/Item';
import Loader from '../../components/loader';
import moment from 'moment';
import ItemSm from './ItemSm';
import ModalPrintDay from './modals/ModalPrintDay';

function Index() {
  const [calendar, setCalendar] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // const [week, setWeek] = useState(0);
  const [week, setWeek] = useState(Number( sessionStorage.getItem('agendaWeek') ?? 0 ));
  const [showWeekend, setShowWeekend] = useState(false);
  const { auth } = useContext(authContext);

  const [base64pdf, setBase64pdf] = useState(null);

  const days = ["ma", "di", "wo", "do", "vr", "za", "zo"];
  const slots = ["eerste uur", "voormiddag", "namiddag", "avond", "nacht", "loopdag"];

  const ModalPrintDayRef = useRef();

  useEffect(() => {
    fetchAgenda();
    sessionStorage.setItem('agendaWeek', week);
  }, [week]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchAgenda = () => {
    setLoading(true);
    setError('');
    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get(`/workorders/week/${week}`, { headers })
      .then(res => {
        console.log(res.data);
        setCalendar(res.data);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response);
        setLoading(false);
      })
  }

  const getOverview = (date) => {
    setBase64pdf(null);

    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    Api.get(`/workorders/getDayOverviewPdf/${date}`, { headers })
      .then(res => {
        setBase64pdf(res.data?.pdf);
        ModalPrintDayRef.current.showModal();
      })
      .catch(err => {
        setError(err.response?.message);
      })
  }

  // agenda header component
  const AgendaHeader = (props) => {
    if(props.thin){
      return (
        <th scope="col" width="78px" style={props.leftBorder ? {borderLeftWidth: '2px'} : {}}>
          <span>{moment(props.date).format('ddd')}</span>
        </th>
      )
    }

    return (
      <th scope="col" min-width="13%" style={props.leftBorder ? {borderLeftWidth: '2px'} : {}}>
        <div className="d-flex justify-content-between align-items-center"> 
          <div>{moment(props.date).format('ddd D MMM')}</div>
          <button
            className="btn btn-light" 
            onClick={() => getOverview(props.date)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
            </svg>
          </button>
        </div>
      </th>
    )
  }

  if(loading){
    return <Loader />
  }

  if(error){
    return <p className="text-danger">{error}</p>
  }

  if(calendar.length <= 0){
    return 'none';
  }

  return (
    <div className="card dashboard-widget">
      <div className="card-body">
        <b>Agenda</b>

        <ModalPrintDay
          ref={ModalPrintDayRef}
          base64pdf={base64pdf}
        />

        <div className="btn-toolbar my-2 d-flex justify-content-between" role="toolbar">
          <div>
            <div className="btn-group mr-2" role="group">
              <button 
                type="button" 
                className="btn btn-secondary"
                onClick={() => setWeek(week-1)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                  <path d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"/>
                </svg>
              </button>
              <button 
                type="button" 
                className="btn btn-secondary"
                onClick={() => setWeek(week+1)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                  <path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                </svg>
              </button>
            </div>
            <div className="btn-group mr-2" role="group">
              <button 
                type="button" 
                className="btn btn-secondary"
                onClick={() => setWeek(0)}
                disabled={week === 0 ? true : false}
              >Deze week</button>
            </div>
          </div>
          <div>
            <div className="btn-group" role="group">
              <button 
                type="button" 
                className="btn btn-secondary"
                onClick={() => setShowWeekend(!showWeekend)}
              >{`${showWeekend ? 'Verberg weekend' : 'Toon weekend'}`}</button>
            </div>
          </div>
        </div>

        <table className="table table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col" width="49px"></th>

              <AgendaHeader 
                date={calendar[0]?.date}
              />
              <AgendaHeader 
                date={calendar[1]?.date}
              />
              <AgendaHeader 
                date={calendar[2]?.date}
              />
              <AgendaHeader 
                date={calendar[3]?.date}
              />
              <AgendaHeader 
                date={calendar[4]?.date}
              />
              <AgendaHeader 
                date={calendar[5]?.date}
                leftBorder={true}
                thin={!showWeekend}
              />
              <AgendaHeader 
                date={calendar[6]?.date}
                thin={!showWeekend}
              />
            </tr>
          </thead>
          <tbody>
            { slots.map((slot, i) => 
              <tr key={i}>
                <td className="bg-light">
                  <span style={{writingMode: 'vertical-rl', textOrientation: 'mixed'}}>{slot}</span>
                </td>
                { days.map((day, j) => 
                  <td key={j} style={{borderLeftWidth: `${j === 5 ? '2px' : 'inherit'}`}}>
                    {Object.values(calendar[j].data[i]).map((workitem, k) => {
                      if(j > 4 && !showWeekend){
                        return <ItemSm key={k} workitem={workitem} />
                      }else{
                        return <Item key={k} workitem={workitem} />
                      }
                    }
                    )}
                  </td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Index
