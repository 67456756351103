import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'; 
import './index.scss';

import AuthProvider from './context/AuthContext';
import { ToastProvider } from 'react-toast-notifications'

import ProtectedRoute from './components/ProtectedRoute';
import Login from './pages/auth/Login';

import Profile from './pages/profile';

import AzureOauth from './pages/oauth/Azure';
import TeamleaderOauth from './pages/oauth/Teamleader';

import Home from './pages/home/';
import Inbox from './pages/inbox/';
import Workorders from './pages/workorders';
import WorkordersEdit from './pages/workorders/Edit';
import WorkordersCreate from './pages/workorders/Create';
import Reminders from './pages/reminders';
import Companies from './pages/companies';
import Contacts from './pages/contacts';
import Sites from './pages/sites';
import SitesCreate from './pages/sites/Create';
import SitesUpdate from './pages/sites/Update';

import NotFound from './pages/NotFound'

import * as serviceWorker from './serviceWorker';

import moment from 'moment';
import localization from 'moment/locale/nl-be';

//clear cache on new build
import withClearCache from "./ClearCache";
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  return (
    <React.StrictMode>
      <AuthProvider>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={6000}
          placement="top-center"
        >
          <Router>
            <Switch>
              <Route exact path="/login" component={Login} />

              <ProtectedRoute exact path="/profile" component={Profile} />
              <ProtectedRoute exact path="/" component={Home} />
              <ProtectedRoute exact path="/inbox" component={Inbox} />
              <ProtectedRoute exact path="/workorders" component={Workorders} />
              <ProtectedRoute exact path="/workorders/create" component={WorkordersCreate} />
              <ProtectedRoute exact path="/workorders/:id" component={WorkordersEdit} />
              <ProtectedRoute exact path="/reminders" component={Reminders} />
              <ProtectedRoute exact path="/companies" component={Companies} />
              <ProtectedRoute exact path="/contacts" component={Contacts} />
              <ProtectedRoute exact path="/sites" component={Sites} />
              <ProtectedRoute exact path="/sites/create" component={SitesCreate} />
              <ProtectedRoute exact path="/sites/:id" component={SitesUpdate} />
              <ProtectedRoute exact path="/oauth/teamleader" component={TeamleaderOauth} />
              <ProtectedRoute exact path="/oauth/microsoft" component={AzureOauth} />

              <Route component={NotFound} />
            </Switch>
          </Router>
        </ToastProvider>
      </AuthProvider>
    </React.StrictMode>
  );
}

moment.locale('nl-be', localization);

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
