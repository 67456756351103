import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactQuill from 'react-quill';

const ModalSendInvoice = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [recipient, setRecipient] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [sendByLetter, setByLetter] = useState(false);
  const [sendAsSinglePDF, setSendAsSinglePDF] = useState(false);

  const defaultBody = `<p>Beste,</p>
  <br>
  <p>Gelieve in bijlage onze factuur terug te vinden met factuurnummer ${props.invoiceNr} in onderwerp.<br>
  Er wordt geen papieren versie meer nagestuurd per post.<br>
  Wij hopen u hiermee van dienst te zijn en danken u voor het vertrouwen.<p>
  <br>
  <p>Met vriendelijke groet,<br>
  Zaniboo BV</p>`

  //initialize modal
  useEffect(() => {
    initializeState();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const initializeState = () => {
    setBody(defaultBody);
    setRecipient(props.sugestedRecipient ? props.sugestedRecipient : '');
    setSubject(`Zaniboo facturatie - ${props.invoiceNr ?? props.internalRef}`);
  }

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = () => {
    if(sendByLetter){
      sendLetter();
    }else{
      sendMail();
    }
  }

  const sendMail = () => {
    const data = {
      recipient: recipient,
      subject: subject,
      body: body,
      type: 'invoice',
      singlePdf: sendAsSinglePDF ? true : false,
    };

    props.onSendMail(data);
    handleClose();
  }

  const sendLetter = () => {
    const data = {
      type: 'invoice'
    };

    props.onSendLetter(data);
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Verstuur factuur</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="FrmSendByLetter1">versturen</label>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="sendByLetter" 
              id="FrmSendByLetter1" 
              // value="option1" 
              checked={!sendByLetter}
              onClick={() => setByLetter(false)}
            />
            <label className="form-check-label" for="FrmSendByLetter1">
              via email
            </label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="sendByLetter" 
              id="FrmSendByLetter2" 
              // value="option2"
              checked={sendByLetter}
              onClick={() => setByLetter(true)}
            />
            <label className="form-check-label" for="FrmSendByLetter2">
              via post
            </label>
          </div>
        </div>

        { !sendByLetter ? 
          <>
            <div className="form-group">
              <label htmlFor="frmRecipient">email begunstigde</label>
              <input 
                type="email"
                id="frmRecipient" 
                className="form-control"
                name="recipient" 
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
              />
              { (props.sugestedRecipient && recipient !== props.sugestedRecipient) &&
                <small id="recipientHelp" className="form-text text-muted">voorgesteld: {props.sugestedRecipient}</small>
              }
            </div>

            <div className="form-group">
              <label htmlFor="frmSubject">onderwerp</label>
              <input 
                type="text"
                id="frmSubject" 
                className="form-control"
                name="subject" 
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>

            <div className="row">
              <div className="col-12">
                <label>Bijlagen</label>
              </div>

              <div className="col-12 mb-3">
                <div className="form-check">
                    <input 
                      className="form-check-input" 
                      type="checkbox" 
                      id="frmSinglePDF" 
                      name="singlePDF"
                      checked={sendAsSinglePDF} 
                      onChange={(e) => setSendAsSinglePDF(e.target.checked)} 
                    />
                    <label className="form-check-label" htmlFor="frmCategorySawing">Alle bijlagen samenvoegen in 1 pdf</label>
                </div>
              </div>

              <div className="col-6">
                <div className="alert alert-primary" role="alert">
                  <span>{`factuur-${props.internalRef}`}</span> 
                </div>
              </div>  

              { props.approval && 
                <div className="col-6">
                  <div className="alert alert-primary" role="alert">
                    <span>{`goedkeuring-${props.internalRef}`}</span> 
                  </div>
                </div>  
              }

              { props.siteorders.length > 0 && props.siteorders.map((siteorder, i) => 
                <div className="col-6" key={siteorder.id}>
                  <div className="alert alert-primary" role="alert">
                    { props.siteorders.length > 1 ? 
                      <span>{`werfbon-${props.internalRef}_${i+1}`}</span> 
                    :
                      <span>{`werfbon-${props.internalRef}`}</span> 
                    }
                  </div>
                </div>
              )}

            </div>

            <div className="form-group">
              <label htmlFor="frmBody">Bericht</label>
              <ReactQuill 
                theme="snow"
                onChange={html => setBody(html)}
                value={body}
                modules={ModalSendInvoice.modules}
                formats={ModalSendInvoice.formats}
                bounds={'.app'}
                placeholder="placeholder"
              />
            </div>
          </>
        :
          <div className="alert alert-warning" role="alert">
            Vergeet de benodigde pdf's niet af te printen.
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Versturen
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

ModalSendInvoice.modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'color': [] }, { 'background': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}]
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}

ModalSendInvoice.formats = [
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet', 'background', 'color'
]

export default ModalSendInvoice
