import React from 'react';
import Dashboard from '../../components/dashboard/Dashboard';
import List from './List';

function Contacts() {
  return (
    <Dashboard padding="0">
      <div className="h-100 d-flex flex-column"> 
        <div className="row">
          <div className="col">
            <h1>Contacts</h1>
          </div>
        </div>

        <div 
          className="row flex-fill h-100"
          style={{minHeight: '0px', overflowY: 'auto'}}
        >
          <div className="col h-100">
            <List />
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Contacts
