import React, { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { authContext } from '../../context/AuthContext';
import Api from '../../utils/Api';
import DynamicSelect from '../../components/form/DynamicSelect';

const ModalImportCompanyLogo = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loadedCompanies, setLoadedCompanies] = useState(false);
  const [company, setCompany] = useState({});

  const { auth } = useContext(authContext);
  
  useEffect(() => {
    setCompany({});
    setLoadedCompanies(false);

    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    };

    Api.get('/companies', { headers })
      .then(res => setCompanies(res.data))
      .catch(err => console.log(err))
      .finally(() => setLoadedCompanies(true));
  }, []);  //eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    showModal() {
      handleShow();
    }
  }));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = () => {
    const attachmentId = props.attachmentId;
    const companyId = company.id ?? null;
    props.onSubmit(attachmentId, companyId);
    handleClose();
  }

  return (
    <Modal 
      show={show} 
      onHide={handleClose}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Importeer bedrijfslogo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Kies het bedrijf in onderstaande lijst:</span>
        <div className="form-group">
          <label htmlFor="frmCompanyId">Bedrijven</label>
          <DynamicSelect 
            id="frmCompanyId"
            data={companies}
            dataLoaded={loadedCompanies}
            value={company}
            valueField="id"
            displayField="name"
            updateValue={(value) => setCompany(value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Annuleren
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Opslaan
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

export default ModalImportCompanyLogo

