import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Api from '../../utils/Api';
import { authContext } from '../../context/AuthContext';

function ExternalProvider(props) {
  const [providerDataLoading, setProviderDataLoading] = useState(true);
  const [providerData, setProviderData] = useState({});
  const [providerErr, setProviderErr] = useState(false);
  const { auth } = useContext(authContext);

  useEffect(() => {
    getMe();
  }, []) //eslint-disable-line react-hooks/exhaustive-deps


  const getMe = () => {
    const token = auth.data.access_token;

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }

    setProviderDataLoading(true);

    Api.get(props.endpoint, { headers })
      .then(res => {
        setProviderErr(false);
        setProviderData(res.data);
      })
      .catch(err => setProviderErr(true))

    setProviderDataLoading(false);
  }

  return (
    <>
      <dt className="col-sm-3">{props.name}</dt>
      <dd className="col-sm-9">
        <div>
          <span className="mr-2">status:</span>
          { providerDataLoading ?
            <span className="text-muted">Loading..</span>
          : providerErr ? 
            <span className="text-danger">Error</span>
          : 
            <>
              <span className="text-success">OK</span>
              <div className="text-muted small py-2">{JSON.stringify(providerData)}</div>
            </>
          }
        </div>
        <Link 
          to={props.reconnectLink} 
          className="btn btn-info" 
          disabled={providerDataLoading}
        >Reconnect</Link>
      </dd>
    </>
  )
}

export default ExternalProvider
