import React from 'react';
import { Link } from 'react-router-dom';

function Badge(props) {
  return (
    <span className={`badge badge-${props.variant ?? 'light'} w-100 text-center`}>{props.text}</span>
  )
}

function ItemSm(props) {
  //badge proforma
  const badgeProforma = (workorder) => {
    if(workorder.status_proforma === 'ok'){
      return <Badge variant="success" text="PF"/>;
    }

    return <Badge text="PF"/>;
  }

  //badge approval
  const badgeApproval = (workorder) => {
    if(workorder.status_approval === 'approved'){
      return <Badge variant="success" text="GK"/>;
    }

    if(workorder.status_approval === 'requested'){
      return <Badge variant="info" text="GK"/>;
    }

    if(workorder.status_approval === 'required'){
      return <Badge variant="warning" text="GK"/>;
    }

    if(workorder.status_approval === 'not required'){
      return <Badge variant="success" text="GK"/>;
    }

    return <Badge text="GK"/>;
  }

  //badge invoice
  const badgeInvoice = (workorder) => {
    if(workorder.status_invoice === 'paid'){
      return <Badge variant="success" text="F"/>;
    }

    if(workorder.status_invoice === 'sent'){
      return <Badge variant="info" text="F"/>;
    }

    if(workorder.status_invoice === 'booked'){
      return <Badge variant="warning" text="F"/>;
    }

    return <Badge text="F"/>;
  }

  //badge siteorder
  const badgeSiteorder = (workorder) => {
    if(workorder.siteorders_count){
      return <Badge variant="success" text="W"/>;
    }

    return <Badge text="W"/>;
  }

  return (
    <Link 
      key={props.workitem?.id} 
      className="card bg-light mb-2" 
      style={{textDecoration: 'none', color: 'inherit'}}
      to={`/workorders/${props.workitem?.id}`} 
    >
      <div className="card-body p-3" style={{fontSize: '85%', lineHeight: '1.2'}}>
        <div 
          className="d-flex justify-content-start align-items-center" 
          style={{writingMode: 'vertical-rl', textOrientation: 'mixed'}}
        >
          <b className="mb-1">{props.workitem?.internal_ref}</b>

          <div className="d-flex justify-content-start mb-1">
            <div className="mb-1">{badgeProforma(props.workitem)}</div>
            <div className="mb-1">{badgeApproval(props.workitem)}</div>
            <div className="mb-1">{badgeInvoice(props.workitem)}</div>
            <div className="mb-1">{badgeSiteorder(props.workitem)}</div>
          </div>
            
        </div>
        
      </div>
    </Link>
  )
}

export default ItemSm
