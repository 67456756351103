import React from 'react'

function Document(props){
  if (!props.workorderPdf){
    return (
      <p>Loading</p>
    )
  }

  return (
    <div className="d-flex flex-column h-100">
      <embed
        src={`data:application/pdf;base64,${props.workorderPdf}`}  
        className="flex-grow-1"
        style={{width: '100%', minHeight: '300px'}}
      ></embed >
    </div>
  )
}

export default Document
